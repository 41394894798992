import { Colors } from '../../styles/colors'
import styled from 'styled-components'
import { SpoqaMedium } from './TextStyle/Spoqa'
import { motion } from 'framer-motion'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isShowDownBtn?: boolean
  setIsShowDownBtn?: React.Dispatch<React.SetStateAction<boolean>>
  isInstaEvent?: boolean
}

interface DownloadBtnProps {
  isInstaEvent?: boolean
}

interface ScrollUpBtnProps {
  isInstaEvent?: boolean
}

export const Styled = {
  Container: styled.div`
    position: fixed;
    display: flex;
    z-index: 1;
    bottom: 112px;
    right: 50px;
  `,

  ScrollUpBtnNew: styled.div`
    position: fixed;
    display: flex;
    z-index: 1;
    bottom: 40px;
    right: 112px;
  `,

  DownloadBtn: styled(motion.div)<DownloadBtnProps>`
    background-color: ${({ isInstaEvent }) =>
      isInstaEvent ? Colors.gf : Colors.primary_ios};
    border-radius: 100px;
    padding: ${({ isInstaEvent }) =>
      isInstaEvent ? '0px 40px' : '0px 54.5px'};
    height: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  `,

  ScrollUpBtn: styled(motion.div)<ScrollUpBtnProps>`
    display: flex;
    width: 56px;
    height: 56px;
    right: 50px;
    border-radius: 100px;
    background-color: ${({ isInstaEvent }) =>
      isInstaEvent ? Colors.gf : '#ffffff'};
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  `,

  ScrollUpIcon: styled.img`
    width: 18.14px;
    height: 21.6px;
  `,
}

const { Container, DownloadBtn, ScrollUpBtn, ScrollUpIcon, ScrollUpBtnNew } =
  Styled

const FloatBtn: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  isShowDownBtn,
  setIsShowDownBtn,
  isInstaEvent = false,
}) => {
  const contentBoxVariants = {
    hover: {
      backgroundColor: isInstaEvent ? Colors.green3 : '#FFE299',
    },
  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ScrollUpBtnNew>
      {/* {isShowDownBtn && (
        <DownloadBtn
          variants={contentBoxVariants}
          whileHover='hover'
          onClick={() => {
            setIsOpen(true)
          }}
          isInstaEvent={isInstaEvent}
        >
          <SpoqaMedium
            size={22}
            color={isInstaEvent ? Colors.greenText : Colors.g12}
          >
            {isInstaEvent ? '이벤트 참여하러가기' : '앱 다운로드'}
          </SpoqaMedium>
        </DownloadBtn>
      )} */}
      {/* <ScrollUpBtn
        variants={contentBoxVariants}
        whileHover='hover'
        onClick={() => {
          handleScrollToTop()
        }}
        isInstaEvent={isInstaEvent}
      >
        <ScrollUpIcon
          src={
            isInstaEvent ? 'images/scroll_up_green.png' : 'images/scroll_up.png'
          }
        />
      </ScrollUpBtn> */}
      <NewScrollupBtn
        id={'main_pageup_btn_click'}
        onClick={() => {
          handleScrollToTop()
        }}
      >
        <img src='/icons/scrollUpButton.svg' alt='' />
      </NewScrollupBtn>
    </ScrollUpBtnNew>
  )
}

const NewScrollupBtn = styled.div`
  cursor: pointer;
  border-radius: 40%;
  box-shadow: 0px 8px 16px 0px rgba(190, 190, 190, 0.1);
  overflow: hidden;
  width: 56px;
  height: 56px;
`

export default FloatBtn
