import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.section`
  padding: 40px 24px 0;
  border-bottom: 1px solid #19191b;
  background-color: #28282e;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const Title = styled.p`
  font-size: 22px;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
  color: white;
`
const Count = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-family: Pretendard400, sans-serif;
  color: #eaeef3;
`
// 8 - 0
// 6 - 35
// 4 - 70
// 2 - 105
//y=(8−x)/2 * 35
const List = styled.div<{ length: number }>`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  background-color: sienna;
  left: ${({ length }) => ((8 - length) / 2) * 36}px;
  height: 100%;
  transform: translate(-50%, -50%);
`
const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`
const AddLink = styled(Link)`
  color: #73aef9;
`
const Item = styled.div<{ index: number }>`
  position: absolute;
  top: 0;
  left: ${(props) => props.index * 35.4}px;
  z-index: ${(props) => props.index};
  & > img {
    border-radius: 32px;
  }
`
const EmptyArea = styled.div`
  background-color: #19191b;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 100%;
  margin: 40px 0;
  & > span {
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-family: Pretendard400, sans-serif;
    color: #eaeef3;
  }
`
type Props = {
  list: {
    code: string
    name: string
    imgUrl: string
  }[]
}

const MySecurities = ({ list }: Props) => {
  return (
    <Wrapper>
      <TitleContainer>
        <Title>내가 선택한 증권사</Title>
        <Count>{list.length}개</Count>
      </TitleContainer>
      {list.length === 0 ? (
        <EmptyArea>
          <span>
            증권사 선택 후<br />
            게임 진행이 가능합니다.
          </span>
        </EmptyArea>
      ) : (
        <CenterCompanyWrapper>
          <CompanyWrapper>
            <List length={list.length}>
              {list.map(({ code, imgUrl, name }, index) => (
                <Item key={code} index={index}>
                  <img width={64} height={64} src={imgUrl} alt={name} />
                </Item>
              ))}
            </List>
          </CompanyWrapper>
        </CenterCompanyWrapper>
      )}
      {list.length !== 8 && (
        <Center>
          <AddLink
            to={'/event/simulate/v2/share'}
            id={'event_160_03_main_securitiesadd_btn_click'}
          >
            증권사 추가하기
          </AddLink>
        </Center>
      )}
    </Wrapper>
  )
}

const CenterCompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`
const CompanyWrapper = styled.div`
  min-height: 64px;
  position: relative;
  margin-top: 40px;
  max-width: 312px;
  min-width: 312px;
`
export default MySecurities
