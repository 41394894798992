import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface V2KeyboardState {
  isKeyboardOpen: boolean
  height: number
}

const initialState: V2KeyboardState = {
  isKeyboardOpen: false,
  height: 0,
}
const V2KeyboardSlice = createSlice({
  name: 'v2Keyboard',
  initialState,
  reducers: {
    setOpenKeyboard(state, action: PayloadAction<number>) {
      state.isKeyboardOpen = true
      state.height = action.payload
    },
    setCloseKeyboard(state) {
      state.isKeyboardOpen = false
      state.height = 0
    },
  },
})
export const { setCloseKeyboard, setOpenKeyboard } = V2KeyboardSlice.actions
export default V2KeyboardSlice.reducer
