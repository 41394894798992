import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  description: string
}
const Wrapper = styled.section`
  width: 100%;
  display: flex;
  padding: 56px 0 36px;
  justify-content: center;
  background-color: white;
`
const Container = styled.div`
  max-width: 800px;
  width: 100%;
  background-color: #f5f5f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 108px;
`
const Title = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
`
const SubTitle = styled.span`
  color: #999ba9;
  font-size: 18px;
  line-height: 22px;
`

const EventSubTitleForWeb = ({ title, description }: Props) => {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <SubTitle>{description}</SubTitle>
      </Container>
    </Wrapper>
  )
}

export default EventSubTitleForWeb
