import React from 'react'
import SimulatedLayout from '@layouts/SimulatedLayout'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { Link, useHistory } from 'react-router-dom'
import useSearchParams from '@hooks/useSearchParams'
import useGetEventList from '@hooks/useGetEventList'

const EventDesktopSize = 1280
const MAX_ITEM_WIDTH = 640
const TabContainer = styled.div`
  border-bottom: 1px solid #dcdeeb;
  display: flex;
  justify-content: center;
  height: 100%;
`
const Tab = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: ${MAX_ITEM_WIDTH * 2}px;
  overflow: hidden;
  height: 80px;
  @media screen and (max-width: 1280px) {
    max-width: 100%;
    height: 48px;
  }
`
const TabItem = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  > span {
    font-size: 26px;
    font-family: ${(props) =>
      props.isSelected
        ? 'Pretendard700, sans-serif'
        : 'Pretendard400, sans-serif'};
    color: ${(props) => (props.isSelected ? '#19191B' : '#999ba9')};
    @media screen and (max-width: ${EventDesktopSize}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`
const CurrentUnderLine = styled.div<{ isEnd: boolean }>`
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.isEnd ? '50%' : 0)};
  transition: ease-in-out 0.3s;
  width: 100%;
  max-width: ${MAX_ITEM_WIDTH}px;
  height: 2px;
  background-color: ${Colors.g7};
  @media screen and (max-width: ${EventDesktopSize}px) {
    width: 50%;
  }
`
const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  font-family: Pretendard400, sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: #999ba9;
  gap: 30px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
    gap: 16px;
  }
  > img {
    width: 74px;
    height: 74px;
    @media screen and (max-width: ${Desktop}px) {
      width: 40px;
      height: 40px;
    }
  }
`
const EventList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 36px;
  padding-top: 56px;
  margin-bottom: 140px;
  @media screen and (max-width: ${Desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    margin-bottom: 56px;
  }
  @media screen and (max-width: ${EventDesktopSize}px) {
    gap: 24px;
    padding: 24px;
    margin-bottom: 56px;
  }
`
const EventItem = styled.li`
  width: 620px;
  @media screen and (max-width: ${EventDesktopSize}px) {
    width: 312px;
  }
`
const EventImage = styled.img`
  height: 360px;
  width: 620px;
  border-radius: 24px;
  box-shadow: 0 12px 24px 0 rgba(109, 109, 109, 0.2);

  @media screen and (max-width: ${EventDesktopSize}px) {
    border-radius: 12px;
    width: 312px;
    height: 180px;
    box-shadow: 0 6px 12px 0 rgba(109, 109, 109, 0.2);
  }
`
const EventTitle = styled.h2`
  font-family: Pretendard700, sans-serif;
  text-align: start;
  margin: 24px 0 8px 0;
  word-break: auto-phrase;
  @media screen and (max-width: ${EventDesktopSize}px) {
    margin: 16px 0 8px;
    font-size: 18px;
    line-height: 22px;
  }
`
const EventDesc = styled.span`
  font-family: Pretendard400, sans-serif;
  text-align: start;
  color: #999ba9;
  @media screen and (max-width: ${EventDesktopSize}px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const ONGOING = '?tab=ongoing'
const END = '?tab=end'

const Event = () => {
  const history = useHistory()
  const { getParam } = useSearchParams()
  const { data: eventList } = useGetEventList()

  const currentTab = getParam('tab') || 'ongoing'
  const isEndTab = currentTab === 'end'
  /**
   *
   * @param isEnd 종료된 이벤트 인지 여부
   */
  const handleTabChange = (isEnd: boolean = false) => {
    if (isEnd) {
      history.replace(END)
    } else {
      history.replace(ONGOING)
    }
  }
  const formattingLinkPath = (path: string) => {
    try {
      return path.split('https://160ipo.com')[1].split('?')[0]
    } catch (err) {
      return '/event'
    }
  }

  return (
    <SimulatedLayout paddingBottom={0} hasHeaderUnderLine={false}>
      <TabContainer>
        <Tab>
          <CurrentUnderLine isEnd={isEndTab} />
          <TabItem onClick={() => handleTabChange()} isSelected={!isEndTab}>
            <span>진행중인 이벤트</span>
          </TabItem>
          <TabItem onClick={() => handleTabChange(true)} isSelected={isEndTab}>
            <span>종료된 이벤트</span>
          </TabItem>
        </Tab>
      </TabContainer>
      <ListWrapper>
        {!isEndTab ? (
          !eventList?.ongoingEvents || eventList.ongoingEvents.length === 0 ? (
            <EmptyList>
              <img
                src={'/images/event/empty-event.png'}
                alt={'empty-ongoing-event'}
              />
              진행중인 이벤트가 없습니다.
            </EmptyList>
          ) : (
            <EventList>
              {eventList.ongoingEvents.map(({ id, web, linkUrl }) => {
                const { title, imgUrl, dateDescription } = web
                return (
                  <EventItem key={id}>
                    <Link to={formattingLinkPath(linkUrl)}>
                      <EventImage src={imgUrl} alt={title} />
                      <EventTitle>{title}</EventTitle>
                      <EventDesc>
                        <>{dateDescription}</>
                      </EventDesc>
                    </Link>
                  </EventItem>
                )
              })}
            </EventList>
          )
        ) : !eventList?.endedEvents || eventList.endedEvents.length === 0 ? (
          <EmptyList>
            <img
              src={'/images/event/empty-event.png'}
              alt={'empty-ongoing-event'}
            />
            진행중인 이벤트가 없습니다.
          </EmptyList>
        ) : (
          <EventList>
            {eventList.endedEvents.map(({ id, web, linkUrl }) => {
              const { title, imgUrl, dateDescription } = web
              return (
                <EventItem key={id}>
                  <Link to={formattingLinkPath(linkUrl)}>
                    <EventImage src={imgUrl} alt={title} />
                    <EventTitle>{title}</EventTitle>
                    <EventDesc>
                      <>{dateDescription}</>
                    </EventDesc>
                  </Link>
                </EventItem>
              )
            })}
          </EventList>
        )}
      </ListWrapper>
    </SimulatedLayout>
  )
}

export default Event
