import { SpoqaBold, SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'
import useGetSimulatedData from '@hooks/useGetSimulatedData'
import dayjs from 'dayjs'
import { getNow } from '@utils/dateHelper'

const Section = styled.section`
  margin-top: 40px;
  max-width: 800px;
`
const ScheduleList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 24px 0 56px;
  @media screen and (max-width: ${Desktop}px) {
    display: flex;
    padding: 12px 24px 40px 24px;
    overflow: scroll;
    gap: 16px;
  }
`
const Item = styled.li`
  width: 250px;
  height: 250px;
  @media screen and (max-width: ${Desktop}px) {
    min-width: 180px;
    height: 173px;
  }
`
const ItemCard = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  background-color: ${Colors.gf};
  cursor: pointer;
  box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.07);
  @media screen and (max-width: ${Desktop}px) {
    padding: 16px;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  > img {
    width: 111px;
    height: 111px;
    @media screen and (max-width: ${Desktop}px) {
      width: 80px;
      height: 80px;
    }
  }
`
const Subtitle = styled(SpoqaBold)`
  font-size: 26px;
  letter-spacing: -0.33px;
  color: ${Colors.g12};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 18px;
    padding-left: 24px;
  }
`
const DDay = styled(SpoqaBold)`
  position: absolute;
  top: 24px;
  left: 24px;
  color: #0e6dfb;
  letter-spacing: -0.33px;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
    top: 16px;
    left: 16px;
  }
`
const CompanyName = styled(SpoqaMedium)`
  font-size: 22px;
  line-height: 26px;
  color: ${Colors.g5};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 16px;
  }
`
const EmptyWrapper = styled.section`
  padding: 0;
  margin-bottom: 56px;
  @media screen and (max-width: ${Desktop}px) {
    padding: 0 24px;
    margin-bottom: 36px;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
  background-color: #eaeef3;
  margin-top: 24px;
  padding: 48px;
  @media screen and (max-width: ${Desktop}px) {
    padding: 24px;
    margin-top: 12px;
  }
  > img {
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${Desktop}px) {
      width: 68px;
      height: 71px;
    }
  }
`
const EmptyText = styled(SpoqaMedium)`
  color: ${Colors.g5};
  margin-top: 24px;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 17px;
    font-size: 14px;
    line-height: 18px;
  }
`
const LastItemStyle = styled(Item)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
  background-color: #eaeef3;
  border-radius: 16px;
  border: 1px solid #dcdeeb;
  @media screen and (max-width: ${Desktop}px) {
    gap: 8px;
    border-radius: 12px;
  }
  > span {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-family: Pretendard400, sans-serif;
    color: #999ba9;
    @media screen and (max-width: ${Desktop}px) {
      font-family: Pretendard500, sans-serif;

      font-size: 14px;
      line-height: 17px;
    }
  }
  > img {
    width: 60px;
    height: 60px;
    @media screen and (max-width: ${Desktop}px) {
      width: 40px;
      height: 40px;
    }
  }
`
type Props = {
  onClickCard: (code: string) => void
}
const LastItem = () => {
  return (
    <LastItemStyle>
      <img src={'/images/event/empty-event.png'} alt={'empty'} />
      <span>
        마지막
        <br />
        종목이에요
      </span>
    </LastItemStyle>
  )
}

const EmptyList = () => {
  return (
    <EmptyWrapper>
      <EmptyContainer>
        <img
          src={'/images/event/simulated-empty-next.png'}
          alt={'empty_investment'}
        />
        <EmptyText>투자 예정 종목이 없어요</EmptyText>
      </EmptyContainer>
    </EmptyWrapper>
  )
}

const ScheduleListSection = ({ onClickCard }: Props) => {
  const {
    scheduledOfferData: { data },
  } = useGetSimulatedData('1')
  const diffDate = (target: string) => {
    const now = dayjs(getNow()).startOf('day')
    const targetDate = dayjs(target).endOf('day')
    return Math.abs(now.diff(targetDate, 'day'))
  }
  const isSolo = data && data.length === 1

  return (
    <Section>
      <Subtitle>다음 투자</Subtitle>
      {data?.length === 0 ? (
        <EmptyList />
      ) : (
        <ScheduleList>
          {data?.map(({ code, name, imgUrl, offeringStartDate }, index) => (
            <Item key={name + index}>
              <ItemCard
                onClick={() => onClickCard(code)}
                id={`event_160_01_main_stock_btn_click_${code}`}
              >
                <Flex>
                  <DDay>D-{diffDate(offeringStartDate)}</DDay>
                  <img src={imgUrl} alt={name} />
                </Flex>

                <CompanyName>{name}</CompanyName>
              </ItemCard>
            </Item>
          ))}
          {isSolo && <LastItem />}
        </ScheduleList>
      )}
    </Section>
  )
}

export default ScheduleListSection
