import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { S2MaxWidth } from '@constants/mediaQuery'

type Props = {
  LeftComponent: ReactNode
  RightComponent: ReactNode
}
const Header = styled.header`
  height: 56px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: ${Colors.bg_dark};
  top: 78px;
  max-width: ${S2MaxWidth}px;
  z-index: 3;
`

const V2MobileHeader = ({ LeftComponent, RightComponent }: Props) => {
  if (!LeftComponent && !RightComponent) return null

  return (
    <Header>
      <div>{LeftComponent}</div>
      <div>{RightComponent}</div>
    </Header>
  )
}

export default V2MobileHeader
