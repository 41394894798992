import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { useAppSelector } from '@store/selectors/utils'
import useSetEntryPoint from '@hooks/useSetEntryPoint'
import { useMediaQuery } from 'react-responsive'
import { Desktop, S2MaxWidth } from '@constants/mediaQuery'
import WebHeader from '@components/layout/WebHeader'
import V2AppHeader from '@pages/Event/simulate/v2/component/V2AppHeader'
import V2MobileHeader from '@pages/Event/simulate/v2/component/V2MobileHeader'
import V2Toast from '@pages/Event/simulate/v2/component/V2Toast'
import useSearchParams from '@hooks/useSearchParams'
import CommonMobileHeader from '@pages/Event/simulate/v2/component/CommonMobileHeader'
import { Helmet } from 'react-helmet-async'

type Props = {
  children: ReactNode
  paddingBottom?: number
  paddingTop?: number
  LeftComponent?: ReactNode
  RightComponent?: ReactNode
}

const Main = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Container = styled.div<{ paddingBottom: number; paddingTop: number }>`
  min-height: 100svh;
  width: 100%;
  max-width: ${S2MaxWidth}px;
  display: flex;
  z-index: 2;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
`
const Background = styled.div`
  position: fixed;
  bottom: 0;
  height: 100dvh;
  width: 100%;
  z-index: 1;
  background-color: ${Colors.bg_dark};
  max-width: ${S2MaxWidth}px;
`
const V2Layout = ({
  children,
  paddingBottom = 88,
  paddingTop = 78,
  LeftComponent,
  RightComponent,
}: Props) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { getParam } = useSearchParams()
  const source = getParam('source')
  const version = getParam('version')

  const paddingTopHeight = () => {
    if (!LeftComponent && !RightComponent) {
      if (isFromApp) {
        return 0
      } else {
        return 78
      }
    } else if (isFromApp) return 56
    else if (isMobile) return 56 + 78
    else return paddingTop
  }

  const { isOpen } = useAppSelector((state) => state.v2ToastReducer)
  useSetEntryPoint()

  useEffect(() => {
    // 외부 공유 시 출처 관련 tag 관리를 위한 storage set
    if (source) {
      localStorage.setItem('source', source)
    }
    if (version) {
      // 160 app version
      localStorage.setItem('version', version)
    }
  }, [source, version])
  return (
    <>
      <Helmet>
        <title>일육공 160 - 공모주 투자의 확률을 높이다</title>
        <meta
          property={'og:image'}
          content={
            process.env.PUBLIC_URL + '/images/simulate-v2/share-image.png'
          }
        />
        <meta property={'og:image:alt'} content={'공모주 게임 시즌2'} />
        <meta
          property={'description'}
          content={
            '참가비 무료, 신청만 하면 바로 게임 시작! 최대 35만원의 주인공이 되어보세요.'
          }
        />
      </Helmet>
      <Main>
        {isOpen ? <V2Toast /> : null}
        <Background />
        {isMobile ? (
          <>
            {isFromApp ? (
              <V2AppHeader
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
              />
            ) : (
              <>
                <CommonMobileHeader hasHeaderUnderLine={false} />
                <V2MobileHeader
                  LeftComponent={LeftComponent}
                  RightComponent={RightComponent}
                />
              </>
            )}
          </>
        ) : (
          <WebHeader />
        )}
        <Container
          paddingTop={paddingTopHeight()}
          paddingBottom={paddingBottom}
        >
          {children}
        </Container>
      </Main>
    </>
  )
}

export default V2Layout
