export const RangeOfAlimTalk = [
  {
    min: -Infinity,
    max: 0,
    text: '',
    imageUrl: '/images/alim/alim-0.png',
    exceptionText: '아쉽게도 수익이\n 나지 않았어요',
  },
  {
    min: 1,
    max: 1000,
    text: '츄파춥스',
    imageUrl: '/images/alim/alim-1.png',
  },
  {
    min: 1001,
    max: 2000,
    text: '초콜릿',
    imageUrl: '/images/alim/alim-2.png',
  },
  {
    min: 2001,
    max: 4000,
    text: '아메리카노',
    imageUrl: '/images/alim/alim-3.png',
  },
  {
    min: 4001,
    max: 6000,
    text: '샌드위치',
    imageUrl: '/images/alim/alim-4.png',
  },
  {
    min: 6001,
    max: 8000,
    text: '짜장면',
    imageUrl: '/images/alim/alim-5.png',
  },
  {
    min: 8001,
    max: 12000,
    text: '햄버거세트',
    imageUrl: '/images/alim/alim-6.png',
  },
  {
    min: 12001,
    max: 16000,
    text: '영화티켓',
    imageUrl: '/images/alim/alim-7.png',
  },
  {
    min: 16001,
    max: 20000,
    text: '치킨 한마리',
    imageUrl: '/images/alim/alim-8.png',
  },
  {
    min: 20001,
    max: 24000,
    text: '셔츠 한장',
    imageUrl: '/images/alim/alim-9.png',
  },
  {
    min: 24001,
    max: 30000,
    text: 'VR 체험권',
    imageUrl: '/images/alim/alim-10.png',
  },
  {
    min: 30001,
    max: 40000,
    text: '회전초밥',
    imageUrl: '/images/alim/alim-11.png',
  },
  {
    min: 40001,
    max: 50000,
    text: '놀이공원 자유이용권',
    imageUrl: '/images/alim/alim-12.png',
  },
  {
    min: 50001,
    max: 60000,
    text: '향수',
    imageUrl: '/images/alim/alim-13.png',
  },
  {
    min: 60001,
    max: 70000,
    text: '홍삼',
    imageUrl: '/images/alim/alim-14.png',
  },
  {
    min: 70001,
    max: 80000,
    text: '신발',
    imageUrl: '/images/alim/alim-15.png',
  },
  {
    min: 80001,
    max: 90000,
    text: '커피머신',
    imageUrl: '/images/alim/alim-16.png',
  },
  {
    min: 90001,
    max: 100000,
    text: '스테이크와 와인',
    imageUrl: '/images/alim/alim-17.png',
  },
  {
    min: 100001,
    max: Infinity,
    text: '',
    imageUrl: '/images/alim/alim-18.png',
    exceptionText: '와우! 이 돈으로\n무엇을 하고 싶으신가요?',
  },
]
