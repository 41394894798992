import { createRouterItem, RouterItemType } from '@router/routerItem'
import V2AlimTalkPage from '@pages/Event/simulate/v2/alim-talk/V2AlimTalkPage'
import V2BenefitPage from '@pages/Event/simulate/v2/benefit/V2BenefitPage'
import V2ShareGuidePage from '@pages/Event/simulate/v2/bridge/V2ShareGuidePage'
import V2SignUpBridgePage from '@pages/Event/simulate/v2/bridge/V2SignUpBridgePage'
import V2SignCheckPage from '@pages/Event/simulate/v2/sign-check/V2SignCheckPage'
import V2SignUpPage from '@pages/Event/simulate/v2/sign-up/V2SignUpPage'
import V2MarketingAgreementPage from '@pages/Event/simulate/v2/sign-up/V2MarketingAgreementPage'
import V2MainPage from '@pages/Event/simulate/v2/V2MainPage'
import SelectSecuritiesPage from '@pages/Event/simulate/v2/select-securities/SelectSecuritiesPage'
import CompletedReadyPage from '@pages/Event/simulate/v2/bridge/CompletedReadyPage'
import V2BenefitDetail from '@pages/Event/simulate/v2/benefit/V2BenefitDetail'

const PageItems = [
  {
    name: 'season2-alim-talk-page',
    path: '/event/simulate/v2/alim-talk',
    component: V2AlimTalkPage,
  },
  {
    name: 'season2-benefit-page',
    path: '/event/simulate/v2/benefit',
    component: V2BenefitPage,
  },
  {
    name: 'season2-benefit-detail-page',
    path: '/event/simulate/v2/benefit-detail',
    component: V2BenefitDetail,
  },
  {
    name: 'season2-bridge-share-guide-page',
    path: '/event/simulate/v2/share',
    component: V2ShareGuidePage,
  },
  {
    name: 'season2-bridge-sign-bridge-page',
    path: '/event/simulate/v2/signup-bridge',
    component: V2SignUpBridgePage,
  },
  {
    name: 'season2-bridge-completed-ready',
    path: '/event/simulate/v2/completed-ready',
    component: CompletedReadyPage,
  },
  {
    name: 'season2-sign-check-page',
    path: '/event/simulate/v2/sign-check',
    component: V2SignCheckPage,
  },
  {
    name: 'season2-sign-up-page',
    path: '/event/simulate/v2/sign-up',
    component: V2SignUpPage,
  },
  {
    name: 'season2-marketing-agreement-page',
    path: '/event/simulate/v2/marketing-agreement',
    component: V2MarketingAgreementPage,
  },
  {
    name: 'season2-select-securities',
    path: '/event/simulate/v2/select-securities',
    component: SelectSecuritiesPage,
  },
  {
    name: 'season2-main-page',
    path: '/event/simulate/v2',
    component: V2MainPage,
  },
]
const CreatedSimulateSeason2PageList = PageItems.map(
  ({ path, component, name }) =>
    createRouterItem({
      name,
      path,
      component,
      type: RouterItemType.DEFAULT,
      exact: true,
    })
)
export default CreatedSimulateSeason2PageList
