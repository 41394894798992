import React, { useEffect, useRef, useState } from 'react'
import ProgressMain from '@components/simulated-trading/Progress'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { SpoqaBold, SpoqaLight } from '@components/base/TextStyle/Spoqa'
import SimulatedSections from '@components/simulated-trading/SimulatedSections'
import useSearchParams from '@hooks/useSearchParams'
import { Desktop } from '@constants/mediaQuery'
import SimulatedLayout from '@layouts/SimulatedLayout'
import useGetSimulatedData from '@hooks/useGetSimulatedData'
import { useMediaQuery } from 'react-responsive'
import { WEB_HEADER_HEIGHT } from '@constants/elements'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import TradingNowButton from '@components/simulated-trading/TradingNowButton'
import { useAppSelector } from '@store/selectors/utils'
import useGetBottomInset from '@hooks/useGetBottomInset'
import ExpandedDetail from '@components/simulated-trading/ExpandedDetail'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'

const Main = styled.div`
  background-color: #f5f5f9;
  min-height: 100vh;
  height: 100%;
`

const BackgroundTopImage = styled.img`
  position: absolute;
  top: -46px;
  right: -24px;
`
const BackgroundBottomImage = styled.img`
  position: absolute;
  top: 470px;
  left: -46px;
`
const Section = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 24px;
  background-color: #2833bf;
  overflow: hidden;
`
const Wrapper = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`

const DetailLink = styled.span`
  font-family: Pretendard500, sans-serif;
  color: ${Colors.gf};
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: ${Desktop}px) {
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.33px;
  }
`

const LinkToDetail = styled.button`
  display: flex;
  margin-top: 24px;
  padding: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${Colors.gf};
  border-radius: 16px;
  height: 54px;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 16px;
    border-radius: 12px;
    height: 50px;
  }
`

const WebTooltipWrapper = styled.div`
  padding: 0 150px;
`
const WebTooltip = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  padding: 40px 32px;
  align-items: center;
  background: ${Colors.gf};
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.07);
  height: 112px;
  top: ${WEB_HEADER_HEIGHT + 20}px;
  z-index: 200;
  width: calc(100% - 300px);
`

const TooltipComponentWrapper = styled.div`
  padding-left: 24px;
`
const MobileTooltip = styled.div`
  display: flex;
  position: absolute;
  bottom: 118px;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: calc(100% - 48px);
  opacity: 0.9;
  z-index: 10;
  background-color: ${Colors.g12};
  gap: 8px;
  border-radius: 4px;
`
// Tooltip 노출 시간
const MAX_TIMER = 3

const TooltipComponent = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  return isMobile ? (
    <TooltipComponentWrapper>
      <MobileTooltip>
        <img
          src={'/icons/check_white.png'}
          alt={'check'}
          width={16}
          height={11}
        />

        <SpoqaLight size={14} color={Colors.gf} lineHeight={18}>
          이벤트 신청 완료!
        </SpoqaLight>
      </MobileTooltip>
    </TooltipComponentWrapper>
  ) : (
    <WebTooltipWrapper>
      <WebTooltip>
        <img
          src={'/icons/check_black.png'}
          width={32}
          height={32}
          alt={'success_event'}
        />
        <SpoqaBold size={22} lineHeight={26} color={'#19191B'}>
          이벤트 신청이 완료됐어요!
        </SpoqaBold>
      </WebTooltip>
    </WebTooltipWrapper>
  )
}

const SimulatedTradingMain = () => {
  const [count, setCount] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const { getParam } = useSearchParams()
  const history = useHistory()

  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const bottomInset = useGetBottomInset()

  const {
    simulatedSummaryData: { data },
  } = useGetSimulatedData('1')
  // 이전 화면에서 정보 정상적으로 넘어오는 경우 Tooltip 노출을 위해 만들어둠
  const isWelcome = getParam('welcome') === 'true'
  const interval = useRef<NodeJS.Timer>()

  const toNavigateDetailPage = () => {
    setIsExpanded((prev) => !prev)
  }

  // 몇일째 투자중 - 개선 사항에서 제외 됨
  // const currentEventDateHandler = () => {
  //   const nowDate = getNow()
  //   const startDate = dayjs(data?.eventSchedule.startDate).startOf('day')
  //   const endDate = dayjs(data?.eventSchedule.endDate).endOf('day')
  //   const currentDate = nowDate.startOf('day').diff(startDate, 'day') + 1
  //   // 이벤트 시작전
  //   if (nowDate.isBefore(startDate)) {
  //     return '시작전'
  //     // 이벤트 종료
  //   } else if (nowDate.isAfter(endDate)) {
  //     return '게임 종료'
  //   } else {
  //     return `${currentDate}일차`
  //   }
  // }

  useEffect(() => {
    if (isWelcome) {
      interval.current = setInterval(() => {
        setCount((prev) => prev + 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [isWelcome])

  useEffect(() => {
    if (count > MAX_TIMER) {
      clearInterval(interval.current)
      // 기존 welcome queryString 제거를 위한 replace
      history.replace(history.location.pathname)
    }
  }, [count, history])

  return (
    <SimulatedLayout
      isFirst={true}
      paddingBottom={isFromApp ? 98 : 132}
      hasShareInHeader={true}
    >
      <Main>
        {!isWelcome || count > MAX_TIMER ? null : <TooltipComponent />}
        <Section>
          <BackgroundTopImage
            src={'/images/event/simulated_main_top.png'}
            alt={'background_image'}
            width={156}
            height={163}
          />
          <BackgroundBottomImage
            src={'/images/event/simulated_main_bottom.png'}
            width={220}
            height={169}
            alt={'background_image'}
          />
          <Wrapper>
            <ProgressMain
              startDate={dayjs(data?.eventSchedule.startDate).format('M.D')}
              endDate={dayjs(data?.eventSchedule.endDate).format('M.D')}
              eventProfitPercentage={data?.eventProfitPercentage || 0}
              eventProfit={data?.eventProfit || 10000}
              totalAmountReceived={data?.totalAmountReceived || 0}
            />
            {isExpanded ? <ExpandedDetail /> : null}
            <LinkToDetail onClick={toNavigateDetailPage}>
              <DetailLink id={'event_160_01_main_detail_btn_click'}>
                {isExpanded ? '접기' : '혜택 자세히 보기'}
              </DetailLink>
            </LinkToDetail>
          </Wrapper>
        </Section>
        <SimulatedSections />
        <NoticeBox type={'season1'} />
        <TradingNowButton
          paddingBottom={!isFromApp ? bottomInset : 24}
          hasShare={true}
        />
      </Main>
    </SimulatedLayout>
  )
}

export default SimulatedTradingMain
