import React, { ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'
import BonusJoinForm from '@pages/Event/simulate/v1bonus/components/BonusJoinForm'
import { Desktop, Desktop600 } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { useHistory } from 'react-router-dom'
import { clearUser, setUser } from '@store/modules/userSlice'
import usePostMessage from '@hooks/usePostMessage'
import {
  BONUS_BENEFIT_PATH,
  BONUS_MARKETING_AGREEMENT_PATH,
} from '@router/paths/simulate/pathMap'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import AlertPopup from '@pages/Event/simulate/v1bonus/sign-up/components/AlertPopup'
import Snackbar from '@pages/Event/simulate/v1bonus/sign-up/components/Snackbar'
import useSearchParams from '@hooks/useSearchParams'
import usePostSimulatedData from '@pages/Event/simulate/v1bonus/hooks/usePostSimulatedData'
import CustomHeader from '@pages/Event/simulate/v1bonus/components/CustomHeader'
import { normalizeSpaces } from '@utils/index'

const ImageArea = styled.img`
  width: 100%;
  height: auto;
  border: none;
`
const PaddingView = styled.div`
  padding: 0;
  @media (max-width: 448px) {
    padding: 0 24px;
  }
`
const Article = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 79px 0 16px;
  @media (max-width: ${Desktop}px) {
    padding: 56px 0 16px;
  }
`
const Title = styled.h2`
  font-family: Pretendard700, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  color: #19191b;
  margin: 32px 0 24px;
`
const MarketingBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
`
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
`

const FlexBox = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  gap: 8px;
  padding-block: 0;
  cursor: pointer;
  padding-inline: 0;
  & > img {
    width: 24px;
    height: 24px;
  }
`
const CheckInfoArea = styled.div<{ isFromApp?: boolean }>`
  display: flex;
  margin: 26px 0 48px;
  flex-direction: column;
  @media (max-width: ${Desktop600}px) {
    border-radius: ${(props) => (props.isFromApp ? '4px' : '16px')};
  }
`
const InfoTitle = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  color: #555564;
`
const InfoDescriptionArea = styled.ul`
  list-style-type: none;
  color: #555564;
`
const InfoDescription = styled.li`
  display: flex;
  font-family: Pretendard400, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #555564;
  gap: 8px;
`
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  @media (max-width: 448px) {
    padding: 0 24px;
  }
`
const JoinButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  padding: 0;
  border-radius: 12px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  font-size: 16px;
  background-color: #0e6dfb;
  color: ${Colors.gf};
  cursor: pointer;
  &:disabled {
    color: #999ba9;
    cursor: not-allowed;
    background-color: #dcdeeb;
  }
`
const ErrorMessage = styled.span`
  color: #ef4444;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
`
type InputStatus = 'name' | 'password' | 'mobile'
type InputProps = Record<InputStatus, string>
const AlreadyErrorMessage = '이전 시즌에 참여한 사용자는 참여할 수 없습니다'
const UnCheckIconSrc = '/icons/simulate-bonus/un-check.png'
const CheckedIconSrc = '/icons/simulate-bonus/check.png'
const SignupPage = () => {
  const history = useHistory()
  const { postMessageHandler } = usePostMessage()
  const {
    simulatedGameStartData: { mutate },
  } = usePostSimulatedData()
  const { getParams, removeParam } = useSearchParams()
  const { agreement } = getParams(['agreement'])
  const isAgree = agreement === 'true'
  const [isMarketingCheck, setIsMarketingCheck] = React.useState<boolean>(false)
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const userStoreData = useAppSelector((state) => state.userReducer)
  const dispatch = useAppDispatch()
  const [errorMsg, setErrorMsg] = React.useState('')
  const [inputData, setInputData] = React.useState<InputProps>({
    name: '',
    password: '',
    mobile: '',
  })
  const [isOpen, setIsOpen] = React.useState(false)
  const isValidName = inputData.name.length > 0
  const isValidMobile = inputData.mobile.length > 0
  const isValidPassword = inputData.password.length === 4
  const isValidInputValues =
    isValidName && isValidMobile && isValidPassword && !errorMsg

  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    let formattedValue
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    if (type === 'password') {
      setErrorMsg('')
    }

    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }

  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }

  const handleMarketingCheckBox = () => {
    if (isMarketingCheck) {
      setIsMarketingCheck(false)
      removeParam('agreement')
    } else {
      setIsMarketingCheck(true)
    }
  }

  const navigateMarketingAgree = () => {
    const { name, password, mobile } = inputData
    dispatch(
      setUser({
        name,
        password,
        phone: mobile,
      })
    )
    history.push(BONUS_MARKETING_AGREEMENT_PATH)
  }
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = () => {
    const { name, password, mobile } = inputData

    const postData = {
      name: normalizeSpaces(name),
      mobile: formatPhoneNumber(mobile),
      password,
      season: '1bonus',
    }
    mutate(postData, {
      onSuccess: () => {
        dispatch(clearUser)
        if (isFromApp) {
          postMessageHandler({
            action: 'isJoin',
          })
        }
        history.replace(BONUS_BENEFIT_PATH + '?welcome=true')
      },
      onError: (error) => {
        if (!error.response) {
          return
        }
        if (error.response.data) {
          const { message, status } = error.response.data
          if (status === 409) {
            // 다른 시즌 가입한 이용자
            setErrorMsg(AlreadyErrorMessage)
            setIsOpen(false)
            return
          }
          if (message === '접수 기간이 종료되었습니다.') {
            alert('이벤트 기간이 종료되었습니다.')
            return
          }
          // 이전 단계에서 한번 거르기 때문에 발생 가능성은 매우 낮음
          if (message === '이미 존재하는 사용자 정보입니다') {
            history.replace(BONUS_BENEFIT_PATH)
          }
        }
      },
    })
  }

  useEffect(() => {
    const { name, phone, password } = userStoreData
    setInputData({
      ...inputData,
      name,
      mobile: phone,
      password: password,
    })
    setIsMarketingCheck(isAgree)
  }, [agreement])

  return (
    <>
      <Snackbar />
      <AlertPopup
        handleSubmit={handleSubmit}
        mobile={inputData.mobile}
        handleClose={handleClose}
        isOpen={isOpen}
      />
      <CustomHeader onClickClose={() => null} hasClose={false} />
      <Article>
        <FlexContainer>
          <ImageArea
            src='/images/event/simulation-banner.png'
            alt='simulation-banner'
          />

          <PaddingView>
            <Title>이벤트 참여 정보를 입력해 주세요</Title>
            <BonusJoinForm
              onChangeInput={onChangeInput}
              clearFields={clearFields}
              inputData={inputData}
            />
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            <MarketingBox>
              <FlexBox onClick={handleMarketingCheckBox}>
                <img
                  src={isMarketingCheck ? CheckedIconSrc : UnCheckIconSrc}
                  alt={'marketing-agree'}
                />
                개인정보 수집 이용 동의(필수)
              </FlexBox>

              <button onClick={navigateMarketingAgree}>
                <img
                  src='/icons/arrow_right.png'
                  width={24}
                  height={24}
                  alt={'navigate-marketing-agreement'}
                />
              </button>
            </MarketingBox>
            <CheckInfoArea isFromApp={isFromApp}>
              <InfoTitle>확인해주세요</InfoTitle>
              <InfoDescriptionArea>
                <InfoDescription>
                  <span>•</span>
                  <span>
                    회원 정보가 부정확할 경우 경품이나 상금을 받지 못할 수
                    있습니다.
                  </span>
                </InfoDescription>
                <InfoDescription>
                  <span>•</span>
                  <span>
                    일육공 가입 정보(이름 및 전화번호)와 이벤트 응모 정보가
                    일치해야 경품 및 상금이 지급됩니다.
                  </span>
                </InfoDescription>
              </InfoDescriptionArea>
            </CheckInfoArea>
          </PaddingView>
        </FlexContainer>
        <ButtonWrapper>
          <JoinButton
            onClick={handleOpen}
            disabled={!isValidInputValues || !isMarketingCheck}
          >
            공모주 게임 시작
          </JoinButton>
        </ButtonWrapper>
      </Article>
    </>
  )
}

export default SignupPage
