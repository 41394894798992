import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import FooterInLogo from './FooterInLogo'
import { Link } from 'react-router-dom'
import { useScrollBlock } from '@hooks/useScrollBlock'

type IsOpenProps = {
  isOpen?: boolean
  onClose: () => void
  height?: number
}

const Menu = ({ isOpen, onClose, height = 78 }: IsOpenProps) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const [deviceType, setDeviceType] = useState('Unknown Device')
  const detectDevice = () => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera
    const platform = navigator.platform

    if (/android/i.test(userAgent)) {
      return 'Android'
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      return 'iOS'
    } else if (platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      return 'iOS'
    } else {
      return 'Other'
    }
  }

  const redirectToPlatformLink = () => {
    const device = detectDevice()
    setDeviceType(device)

    if (device === 'Android') {
      try {
        window.open(
          'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
        )
      } catch (error) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
      }
    } else if (device === 'iOS') {
      // try {
      //   window.webkit.messageHandlers.openDeepLink.postMessage('https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484');
      // } catch (error) {
      //   window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';
      // }
      window.location.href =
        'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
    } else {
      try {
        window.open('https://qr-efbc6.web.app/')
      } catch (error) {
        window.location.href = 'https://qr-efbc6.web.app/'
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }

    return () => {
      allowScroll()
    }
  }, [isOpen])

  if (!isOpen) return null

  // return (
  //   <Container>
  //     <Header>
  //       <XIcon src={"/img/X.svg"} onClick={onClose} />
  //     </Header>
  //     <Content>
  //       <AnimatedSection isSectionOpen={isSectionOpen}>
  //         <Title>{`공모주,\n쉽고 편리하게`}</Title>
  //         <Download onClick={redirectToPlatformLink}>앱 다운로드</Download>
  //         <MenuItem>
  //           <Name onClick={() => window.open("https://160ipo.com/inquire")}>1:1 문의</Name>
  //           <Desc>{`평일 (오전 9시 ~ 오후 6시)\n점심시간 (오전 11시30분 ~ 오후 1시)`}</Desc>
  //         </MenuItem>
  //       </AnimatedSection>
  //       <BottomContainer>
  //         <NavigateSection>
  //           <Navigate>개인정보처리방침</Navigate>
  //           <Navigate>서비스이용약관</Navigate>
  //         </NavigateSection>
  //         <FooterInLogo />
  //       </BottomContainer>
  //     </Content>
  //   </Container>
  // );
  return (
    <>
      <MenuContainer height={height}>
        <a href='/event'>
          <MenuItem id={'menu_event_btn_click'}>🎉 이벤트</MenuItem>
        </a>
        <DotLine />
        <a href='https://www.ml-invest.co.kr/' target='_blank' rel='noreferrer'>
          <MenuItem>엠엘투자자문↗</MenuItem>
        </a>
      </MenuContainer>
    </>
  )
}

const DotLine = styled.div`
  border-top: 1px dotted #c9d3de;
  margin: 8px 24px;
`

const MenuContainer = styled.div<{ height: number }>`
  position: absolute;
  top: ${({ height }) => height}px;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 4;
  padding: 16px 0;
`

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  display: flex;
  width: 100vw;
  &:hover {
    color: #0e6dfb;
  }
`

const Download = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #0e6dfb;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  line-height: normal;
  font-family: Pretendard, sans-serif;
  padding: 16px 8px;
  box-sizing: border-box;
`

const dropdownAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 539px; 
    opacity: 1;
  }
`

const Container = styled.div`
  width: 100%;
  z-index: 500;
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 360px;
  height: 100vh;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 79px;
  border-bottom: 1px solid #c9d3de;
  padding: 16px;
  box-sizing: border-box;
`

const XIcon = styled.img`
  width: 24px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const AnimatedSection = styled.div<{ isSectionOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;
  height: ${(props) => (props.isSectionOpen ? '539px' : '0')};
  overflow: hidden;
  opacity: ${(props) => (props.isSectionOpen ? 1 : 0)};
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  animation: ${(props) => (props.isSectionOpen ? dropdownAnimation : 'none')}
    0.5s forwards;
`

const Title = styled.div`
  font-family: Pretendard, sans-serif;
  font-size: 28px;
  font-style: normal;
  line-height: 100%;
  white-space: pre-line;
`

// const MenuItem = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

const Name = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  cursor: pointer;
`

const Desc = styled.div`
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 120%;
  color: #81828a;
`

const NavigateSection = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
`

const Navigate = styled.div``

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

export default Menu
