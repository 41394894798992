import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Colors } from '../styles/colors'
import ContactBox from '@components/contactBox'
import QnaBox from '@components/qnaBox'

const InquirePage = () => {
  // const [isMobile, setIsMobile] = useState(false)

  const Styled = {
    Container: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100%;
      background-color: #fff;
    `,
    MobileContainer: styled.div`
      @media (min-width: 999px) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: ${Colors.gf};
    `,
  }

  const { Container, MobileContainer } = Styled
  const isMobile = useMediaQuery({
    query: '(max-width: 586px) and (min-width: 360px)',
  })

  return (
    <>
      <Container>
        <ContentWrapper>
          <Header>
            {isMobile ? (
              <>
                <LogoImage src={'/icons/title-logo.svg'} alt='logo' />
                <LogoImage src={'/icons/close.svg'} alt='colse' />
              </>
            ) : (
              <LogoImage src={'/icons/title-logo.svg'} alt='logo' />
            )}
          </Header>
          <TitleContainer>
            <Title>문의하기</Title>
            <SubTitle>무엇을 도와드릴까요?</SubTitle>
          </TitleContainer>
          <ContentContainer>
            <ContactBox />
            <QnaBox />
          </ContentContainer>
        </ContentWrapper>
      </Container>
    </>
  )
}

const ContentWrapper = styled.div`
  background-color: #f5f5f9;
  width: 600px;
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  justify-content: space-between;
`

const LogoImage = styled.img`
  cursor: pointer;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 72px 24px;
  box-sizing: border-box;
`

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  color: #555564;
`

const SubTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
  color: #19191b;
`

const ContentContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`
export default InquirePage
