import React, { ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'
import { Link, useHistory } from 'react-router-dom'
import usePostMessage from '@hooks/usePostMessage'
import usePostSimulatedData from '@hooks/usePostSimulatedData'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { IsSimulationGameSeasonOneEnd, normalizeSpaces } from '@utils/index'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import { clearUser, setUser } from '@store/modules/userSlice'
import { SEASON1_BENEFIT_PATH } from '@router/paths/simulate/pathMap'

const Title = styled.h3`
  font-family: Pretendard700, sans-serif;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.84px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.72px;
  }
`
const ClearButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 28px;
`
const ClearIcon = styled.img`
  transform: translateY(-50%);
  color: #999ba9;
  cursor: pointer;
  transition: color 0.3s ease;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`
const ForgetLink = styled(Link)`
  color: #0e6dfb;
  font-family: Pretendard400, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
  }
`

const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background-color: ${Colors.gf};
  width: 500px;
  height: fit-content;
  border: 1px solid ${Colors.ge};
  padding: 36px 36px;

  @media screen and (max-width: ${Desktop}px) {
    padding: 24px 24px 16px 24px;
    width: 326px;
    height: fit-content;
  }
`
const FormArea = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Label = styled.span`
  font-family: Pretendard400, sans-serif;
  margin-bottom: 8px;

  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
const InputArea = styled.div`
  position: relative;
`
const Input = styled.input`
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 0 16px;
  height: 56px;
  outline: none;
  border: 1px solid #dcdeeb;
  background: #eaeef3;
  margin-bottom: 16px;
  &:active {
    background-color: #eaeef3;
  }
`
const PasswordErrorArea = styled.div`
  height: 40px;
  font-family: Pretendard400, sans-serif;
  font-size: 12px;
  color: red;
`

const ButtonWrapper = styled.div`
  display: flex;
`
const Button = styled.button<{ isApply?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 18px 24px;
  border-radius: 12px;
  flex: 1;
  color: ${({ isApply }) => (isApply ? Colors.gf : '#555564')};
  background-color: ${({ isApply }) => (isApply ? '#0E6DFB' : Colors.gf)};
  font-family: Pretendard400, sans-serif;
  max-width: ${({ isApply }) => (isApply ? '100%' : '112px')};

  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
    max-width: ${({ isApply }) => (isApply ? '100%' : '76px')};
  }
`

type Props = {
  handleCloseModal: () => void
}
type InputStatus = 'name' | 'password' | 'mobile'
type InputProps = Record<InputStatus, string>

type InputComponentProps = {
  type: InputStatus
  onChangeInput: (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  clearFields: (
    type: InputStatus,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void
  value: string
}

const PLACE_HOLDER = {
  name: '이름을 입력하세요',
  mobile: '-없이 입력하세요',
  password: '숫자 4자리',
}

const InputComponent = ({
  onChangeInput,
  clearFields,
  type,
  value,
}: InputComponentProps) => {
  const MaxLength = useMemo(() => {
    if (type === 'mobile') {
      return 12
    } else if (type === 'password') {
      return 4
    }
    return 10
  }, [type])

  return (
    <InputArea>
      <Input
        autoComplete={'off'}
        maxLength={MaxLength}
        value={value}
        onChange={(event) => onChangeInput(type, event)}
        placeholder={PLACE_HOLDER[type]}
      />
      {value ? (
        <ClearButton
          type={'button'}
          onClick={(event) => clearFields(type, event)}
        >
          <ClearIcon src={'/icons/clearIcon.svg'} />
        </ClearButton>
      ) : null}
    </InputArea>
  )
}

const BlurPagePopup = ({ handleCloseModal }: Props) => {
  const [inputData, setInputData] = React.useState<InputProps>({
    name: '',
    password: '',
    mobile: '',
  })
  const history = useHistory()
  const [errorMsg, setErrorMsg] = React.useState('')
  const { simulatedIsExistData } = usePostSimulatedData()
  const { mutate: postSimulatedIsExistData } = simulatedIsExistData
  const { postMessageHandler } = usePostMessage()
  const dispatch = useAppDispatch()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isEndGame = IsSimulationGameSeasonOneEnd()

  const isValidName = inputData.name.length > 0
  const isValidMobile = inputData.mobile.length > 0
  const isValidPassword = inputData.password.length === 4
  const isValidInputValues =
    isValidName && isValidMobile && isValidPassword && !errorMsg

  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    let formattedValue
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    if (type === 'password') {
      setErrorMsg('')
    }

    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }
  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }
  const onSubmit = () => {
    const postData = {
      name: normalizeSpaces(inputData.name),
      mobile: formatPhoneNumber(inputData.mobile),
      password: inputData.password,
      season: '1',
    }
    dispatch(
      setUser({
        name: inputData.name,
        phone: inputData.mobile,
        password: '',
        infoAgree: false,
      })
    )
    postSimulatedIsExistData(postData, {
      onSuccess: () => {
        dispatch(clearUser())
        if (isFromApp) {
          postMessageHandler({
            action: 'isJoin',
          })
        }
        history.push(SEASON1_BENEFIT_PATH)
      },
      onError: (error: any) => {
        if (error.response?.data?.message === '비밀번호가 일치하지 않습니다') {
          setErrorMsg('비밀번호가 일치하지 않습니다')
        } else if (
          error.response?.data?.message === '이벤트 참여자가 아닙니다'
        ) {
          if (isEndGame) {
            setErrorMsg('참여이력이 없어 확인이 불가능합니다')
          } else {
            // sign page 이동
            history.push('/simulated-sign?type=yet')
          }
        }
        console.log('occured Error at sign Event')
      },
    })
  }

  return (
    <ModalStyle>
      <Flex>
        <Title>가입 정보 확인</Title>
        <ForgetLink to={'/simulated-forget'}>비밀번호 찾기</ForgetLink>
      </Flex>
      <FormArea autoComplete={'off'}>
        <Label>이름</Label>
        <InputComponent
          type={'name'}
          onChangeInput={onChangeInput}
          clearFields={clearFields}
          value={inputData.name}
        />
        <Label>전화번호</Label>
        <InputComponent
          type={'mobile'}
          onChangeInput={onChangeInput}
          clearFields={clearFields}
          value={inputData.mobile}
        />
        <Label>비밀번호</Label>
        <InputComponent
          type={'password'}
          onChangeInput={onChangeInput}
          clearFields={clearFields}
          value={inputData.password}
        />
      </FormArea>
      <PasswordErrorArea>
        {errorMsg ? <span>{errorMsg}</span> : null}
      </PasswordErrorArea>

      <ButtonWrapper>
        <Button type={'button'} onClick={handleCloseModal}>
          취소
        </Button>
        <Button
          onClick={onSubmit}
          type={'button'}
          isApply={true}
          disabled={!isValidInputValues}
        >
          누적상금보기
        </Button>
      </ButtonWrapper>
    </ModalStyle>
  )
}

export default BlurPagePopup
