import { getNow } from './dateHelper'
import dayjs from 'dayjs'

export * as price from './price'

export function shorteningTitle(title: string, count: number): string {
  if (count >= 1) {
    return title.length >= 12 ? `${title.slice(0, 10)}...` : title
  }
  return title.length >= 7 ? `${title.slice(0, 5)}...` : title
}
export const CopyURL = () => {
  const url = window.location.href
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert('URL을 복사하였습니다.')
    })
    .catch((err) => {
      console.error('URL 복사 실패:', err)
    })
}
export const BONUS_EVENT_SHARE_PATH =
  'https://160ipo.com/event/simulate/v1bonus'
export const SHARE_DATA = {
  title: '일육공 160 - 공모주 투자의 확률을 높이다',
  text: '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.',
  url: 'https://160ipo.com/simulated-event',
}

export const NavigateShare = async (url?: string) => {
  const ShareData = {
    title: SHARE_DATA.title,
    text: SHARE_DATA.text,
    url: url ? url : SHARE_DATA.url,
  }
  try {
    if (navigator.canShare(ShareData)) {
      await navigator.share(ShareData)
    } else {
      // 공유하기 web API error
      CopyURL()
    }
  } catch (error) {
    console.log(error)
  }
}
export const IsSimulationGameSeasonOneEnd = () => {
  const now = getNow()
  // 공모주 게임 시즌1 종료 Date
  const endDate = dayjs('2025-02-06')
  // true = 이벤트 종료
  return now.isAfter(endDate)
}
// 보너스 시즌
export const IsSimulationGameBonusSeasonEnd = () => {
  const now = getNow()
  // 공모주 게임 시즌1 종료 Date
  const endDate = dayjs('2025-03-21')
  // true = 이벤트 종료
  return now.isAfter(endDate)
}

// 공백제거
export const normalizeSpaces = (str: string): string =>
  str.trim().replaceAll(' ', '')

export const isKakaoInAppBrowser = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.includes('kakaotalk')
}

export const openInExternalBrowser = (url: string) => {
  window.location.href = `intent://${url.replace(
    /^https?:\/\//,
    ''
  )}#Intent;scheme=https;package=com.android.chrome;end;`
}
