import React from 'react'
import styled from 'styled-components'
import { INVESTMENT_NOTICE_LIST, NOTICE_LIST } from '@constants/simulateNotice'
import { Desktop } from '@constants/mediaQuery'

const Section = styled.section<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  padding: 56px;
  @media (max-width: ${Desktop}px) {
    padding: 24px;
  }
`
const MaxWidthView = styled.div`
  max-width: 800px;
  margin: 0 auto;
`
const Title = styled.p`
  font-family: Pretendard700, sans-serif;
  color: #555564;
  opacity: 0.8;
  line-height: 29px;
  margin-bottom: 16px;
  font-size: 26px;
  @media (max-width: ${Desktop}px) {
    line-height: 13px;
    font-size: 12px;
    margin-bottom: 8px;
  }
`

const List = styled.ul`
  margin-bottom: 24px;
  @media (max-width: ${Desktop}px) {
    margin-bottom: 12px;
  }
`
const Item = styled.li`
  font-family: Pretendard400, sans-serif;
  font-size: 26px;
  line-height: 31px;
  color: #999;
  opacity: 0.8;
  @media (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 15px;
  }
`
const FlexBox = styled.div`
  display: flex;
  gap: 4px;
`
const Caption = styled.div`
  display: flex;
  flex-direction: column;
  color: #555564;
  letter-spacing: -0.24px;
  opacity: 0.8;
  font-size: 26px;
  line-height: 30px;
  gap: 4px;
  @media (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 14px;
    gap: 2px;
  }
`

const NoticeBox = ({
  type,
  backgroundColor = '#eaeef3',
}: {
  type: 'bonus' | 'season1' | 'bonus-complete'
  backgroundColor?: string
}) => {
  return (
    <Section bgColor={backgroundColor}>
      <MaxWidthView>
        <Title>확인해 주세요.</Title>
        <List>
          {NOTICE_LIST({ type }).map(({ content, id }) => (
            <FlexBox key={id}>
              <Item>•</Item>
              <Item>{content}</Item>
            </FlexBox>
          ))}
        </List>
        <Title>투자자 유의사항</Title>
        <List>
          {INVESTMENT_NOTICE_LIST.map(({ content, id }) => (
            <FlexBox key={id}>
              <Item>•</Item>
              <Item>{content}</Item>
            </FlexBox>
          ))}
        </List>
        <Caption>
          <span>
            ※엠엘투자자문(주)의 내부통제기준에 따라 준법감시 심의를 필하였음.
          </span>
          <span>
            - 엠엘광고준법 {type === 'season1' ? '2024-제20' : '2025-제3'}호
          </span>
          <span>※한국금융투자협회 심사필 제 24-05289호</span>
          <span>(2025.01.02 - 2026.01.01)</span>
        </Caption>
      </MaxWidthView>
    </Section>
  )
}

export default NoticeBox
