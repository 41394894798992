import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import V2Layout from '@layouts/V2Layout'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import useGetSecurities from '@pages/Event/simulate/v2/hooks/useGetSecurities'
import Skeleton from '@pages/Event/simulate/v2/component/Skeleton'
import usePostSecurities from '@pages/Event/simulate/v2/hooks/usePostSecurities'
import { useOverlay } from '@toss/use-overlay'
import OverlayBackground from '@pages/Event/simulate/v2/component/OverlayBackground'
import { useGetMe } from '@pages/Event/simulate/v2/hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import HeaderClose from '@pages/Event/simulate/v2/component/HeaderClose'

const Wrapper = styled.div`
  padding: 0 24px;
  width: 100%;
`
const Title = styled.h2`
  padding: 16px 0;
  line-height: 29px;
  font-size: 24px;
  font-family: Pretendard700, sans-serif;
  letter-spacing: -0.72px;
  color: white;
`
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  padding: 24px 0;
`
const Item = styled.button<{ isSelected: boolean; isDisabledTarget: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 16px;
  width: 100%;
  padding: 24px;
  background-color: #28282e;
  border-width: 1px;
  border-color: ${({ isSelected }) => (isSelected ? '#dcdeeb' : '#28282e')};
  justify-content: center;
  border-style: solid;
  flex-direction: column;
  gap: 16px;
  & > img {
    border-radius: 48px;
    opacity: ${({ isDisabledTarget }) => (isDisabledTarget ? '0.3' : 1)};
  }
  & > span {
    font-size: 16px;
    font-family: Pretendard400, sans-serif;
    line-height: 19px;
    opacity: ${({ isDisabledTarget }) => (isDisabledTarget ? '0.3' : 1)};

    color: white;
  }
`
const PopupStyle = styled.div`
  display: flex;
  padding: 24px;
  background-color: #28282e;
  align-items: center;
  z-index: 40;
  width: 100%;
  border-radius: 16px;
  flex-direction: column;
`
const AddText = styled.p`
  color: white;
  font-family: Pretendard700, sans-serif;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
`

type SelectedDataType = {
  code: string
  imgUrl: string
}
const PopupImageBox = styled.div`
  display: flex;
  margin: 48px 0 40px;
  gap: 24px;
  & > img {
    border-radius: 40px;
  }
`
const PopupNextButton = styled.button`
  background-color: #237bff;
  border-radius: 12px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  width: 100%;
`

const SelectSecuritiesPage = () => {
  const [selectedData, setSelectedData] = useState<SelectedDataType[]>([])
  const { data } = useGetSecurities()
  const { data: myData } = useGetMe()
  const history = useHistory()
  const { mutate } = usePostSecurities()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const overlay = useOverlay()
  const queryClient = useQueryClient()
  const onSelectItem = ({ code, imgUrl }: { code: string; imgUrl: string }) => {
    const list = [...selectedData]
    // 이미 선택된 경우
    const isSelected =
      selectedData.findIndex((item) => item.code === code) !== -1
    if (isSelected) {
      const filteredList = list.filter((item) => item.code !== code)
      setSelectedData(filteredList)
      return
    } else if (selectedData.length > 1) {
      return
    } else {
      list.push({ code, imgUrl })
      setSelectedData(list)
    }
  }
  const handleClose = () => {
    history.replace('/event/simulate/v2/benefit')
  }
  const handleNext = () => {
    const isFirst = localStorage.getItem('first') === 'true'

    //clean up
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (!myData) return
    if (myData?.securities.length === 8) {
      // 이미 모든 증권사를 추가 한 경우
      history.replace('/event/simulate/v2/benefit')
      return
    } else if (myData?.securities.length < 5) {
      // 최초 2개 추가한 다음
      history.replace('/event/simulate/v2/share')
    }
    // 공유에서 한번도 카카오톡 번호 안내 페이지 안간 경우
    else if (isFirst) {
      history.replace('/event/simulate/v2/completed-ready')
    } else {
      history.replace('/event/simulate/v2/benefit')
    }
  }
  const openPopupHandler = () => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        handleNext()
      }, 2000)
    }

    overlay.open(({ isOpen }) => (
      <OverlayBackground isOpen={isOpen}>
        <PopupStyle>
          <AddText>증권사가 추가되었습니다.</AddText>
          <PopupImageBox>
            {selectedData.map(({ code, imgUrl }) => (
              <img width={80} height={80} src={imgUrl} key={code} alt={code} />
            ))}
          </PopupImageBox>
          <PopupNextButton onClick={handleNext}>다음</PopupNextButton>
        </PopupStyle>
      </OverlayBackground>
    ))
  }

  const onClickHandler = () => {
    mutate(
      {
        securitiesCodes: selectedData.map((item) => item.code),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['me'],
          })
          openPopupHandler()
        },
      }
    )
  }
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])
  return (
    <V2Layout RightComponent={<HeaderClose onClick={handleClose} />}>
      <Wrapper>
        <Title>증권사 2개를 선택하세요</Title>
        <List>
          {data
            ? data.securities.map(({ name, imgUrl, code }) => {
                const isSelected =
                  selectedData.findIndex((item) => item.code === code) !== -1
                const isDisabledTarget =
                  (myData &&
                    myData.securities.findIndex(
                      (item) => item.code === code
                    ) !== -1) ||
                  false
                return (
                  <Item
                    isDisabledTarget={isDisabledTarget}
                    disabled={isDisabledTarget}
                    key={code}
                    onClick={() => onSelectItem({ code, imgUrl })}
                    isSelected={isSelected}
                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                  >
                    <img width={48} height={48} alt={name} src={imgUrl} />
                    <span>{name}</span>
                  </Item>
                )
              })
            : Array.from({ length: 8 }).map((_, index) => (
                <Skeleton key={index} width={'100%'} height={'120px'} />
              ))}
        </List>
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <CommonButton
          id={'event_160_03_ready_securitiesdone_click'}
          onClick={onClickHandler}
          buttonText={'다음'}
          disabled={selectedData.length < 2}
        ></CommonButton>
      </BottomButtonFixer>
    </V2Layout>
  )
}

export default SelectSecuritiesPage
