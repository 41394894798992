import styled from 'styled-components'
import Modal from 'react-modal'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { clearUser } from '@store/modules/userSlice'
import usePostMessage from '@hooks/usePostMessage'
import usePostSimulatedData from '@hooks/usePostSimulatedData'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import useDebounce from '@hooks/useDebounce'
import { useMediaQuery } from 'react-responsive'
import { normalizeSpaces } from '@utils/index'
import { useHistory } from 'react-router-dom'
import { SEASON1_BENEFIT_PATH } from '@router/paths/simulate/pathMap'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isFromApp: boolean
}

const talkModalStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 56px;
    padding-bottom: 32px;
    justify-content: center;

    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
      padding: 40px;
      padding-bottom: 0;
    }
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: 800px) {
      font-family: 'SpoqaHanSansNeoBold', sans-serif;
      font-size: 18px;
      line-height: 22.54px;
      margin-bottom: 32px;
    }
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    color: #555564;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-family: 'SpoqaHanSansNeoRegular', sans-serif;
      font-size: 14px;
      color: #777777;
      line-height: 18px;
    }
  `,
  BlueButton: styled.span`
    color: #0e6dfb;
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    flex: 1;
    @media (max-width: 800px) {
      font-family: 'SpoqaHanSansNeoBold', sans-serif;
      font-size: 18px;
      line-height: 22.54px;
    }
  `,
  Line: styled.div`
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    @media (max-width: 800px) {
      margin-bottom: 0;
    }
  `,
  VerticalLine: styled.div`
    width: 1px;
    height: 90px;
    background-color: #eeeeee;
    @media (max-width: 800px) {
      margin-bottom: 0;
      height: 55px;
    }
  `,
  NumberArea: styled.div`
    border: 1px solid #eeeeee;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: 8px;
    width: 100%;
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  `,
  TalkImage: styled.img`
    width: 56px;
    height: 56px;
    @media (max-width: 800px) {
      width: 40px;
      height: 40px;
    }
  `,
  PhoneNumberText: styled.span`
    font-family: Pretendard500, sans-serif;
    font-size: 18px;
    line-height: 21.6px;
    margin-top: 4px;

    @media (max-width: 800px) {
      font-family: 'SpoqaHanSansNeoMedium', 'sans-serif';
      font-size: 14px;
      line-height: 17.53px;
    }
  `,
  NextButton: styled.span`
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    color: #999999;
    cursor: pointer;
    flex: 1;
    /* border-right: 1px solid #EEEEEE; */
    text-align: center;
    @media (max-width: 800px) {
      font-family: 'SpoqaHanSansNeoBold', sans-serif;
      font-size: 18px;
      line-height: 22.54px;
    }
  `,
  ButtonArea: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 22.54px;
      padding: 16px 0;
      height: 55px;
    }
  `,
}

const {
  ModalContainer,
  Title,
  Description,
  BlueButton,
  Line,
  VerticalLine,
  NumberArea,
  TalkImage,
  PhoneNumberText,
  NextButton,
  ButtonArea,
} = Styled

const TalkModal: React.FC<Props> = ({ isOpen, setIsOpen, isFromApp }) => {
  const data = useAppSelector((state) => state.userReducer)
  const dispatch = useAppDispatch()
  const { postMessageHandler } = usePostMessage()
  const { simulatedGameStartData } = usePostSimulatedData()
  const { mutate: postSimulatedStartGameData } = simulatedGameStartData
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const history = useHistory()

  const postData = {
    name: normalizeSpaces(data.name),
    mobile: formatPhoneNumber(data.phone),
    password: data.password,
  }

  const handleNextButton = () => {
    postSimulatedStartGameData(postData, {
      onSuccess: () => {
        dispatch(clearUser())
        postMessageHandler({
          action: 'isJoin',
        })
        history.push(SEASON1_BENEFIT_PATH + '?welcome=true')
      },
      onError: (error: any) => {
        console.log('eror >>>>> ', error)
      },
    })
  }

  const debouncedHandleNextButton = useDebounce(handleNextButton)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...talkModalStyle,
          content: {
            ...talkModalStyle.content,
            width: '80%',
            maxWidth: '500px',
            borderRadius: !isMobile ? '24px' : isFromApp ? '4px' : '16px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>
            이벤트 알림톡 <br />
            수신번호를 확인해주세요
          </Title>
          <Description>
            공모주 게임 투자 일정마다, 아래 번호로 <br />
            카카오톡 알림 메시지가 발송돼요!
          </Description>
          <NumberArea>
            <TalkImage src='images/talk-bell.png' />
            <PhoneNumberText>{formatPhoneNumber(data?.phone)}</PhoneNumberText>
          </NumberArea>
        </ModalContainer>
        <Line />

        <ButtonArea>
          <NextButton onClick={() => setIsOpen(false)}>다시입력</NextButton>
          <VerticalLine />
          <BlueButton onClick={debouncedHandleNextButton}>확인</BlueButton>
        </ButtonArea>
      </Modal>
    </>
  )
}

export default TalkModal
