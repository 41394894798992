import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { Colors } from '@styles/colors'

const FormArea = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Label = styled.span`
  font-family: Pretendard400, sans-serif;
  margin-bottom: 8px;

  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 19px;
  }
`
const InputArea = styled.div`
  position: relative;
`
const Input = styled.input`
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 0 16px;
  height: 56px;
  outline: none;
  border: 1px solid #dcdeeb;
  background: #eaeef3;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  font-family: Pretendard400, sans-serif;
  &:not(:placeholder-shown) {
    background-color: ${Colors.gf};
  }
  &:focus {
    border: 1px solid #555564;
    background-color: ${Colors.gf};
  }
`
const ClearButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 28px;
  z-index: 1;
`
const ClearIcon = styled.img`
  transform: translateY(-50%);
  color: #999ba9;
  cursor: pointer;
  transition: color 0.3s ease;
`
type InputStatus = 'name' | 'password' | 'mobile'
type InputProps = Record<InputStatus, string>
type InputComponentProps = {
  type: InputStatus
  onChangeInput: (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  clearFields: (
    type: InputStatus,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void
  value: string
}
const PLACE_HOLDER = {
  name: '이름을 입력하세요',
  mobile: '-없이 입력하세요',
  password: '숫자 4자리',
}

const InputComponent = ({
  onChangeInput,
  clearFields,
  type,
  value,
}: InputComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus)
      inputElement.addEventListener('blur', handleBlur)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus)
        inputElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [])

  const MaxLength = useMemo(() => {
    if (type === 'mobile') {
      return 12
    } else if (type === 'password') {
      return 4
    }
    return 10
  }, [type])

  return (
    <InputArea>
      <Input
        ref={inputRef}
        autoComplete={'off'}
        maxLength={MaxLength}
        value={value}
        onChange={(event) => onChangeInput(type, event)}
        placeholder={PLACE_HOLDER[type]}
      />
      {value && isFocused ? (
        <ClearButton
          type={'button'}
          onMouseDown={(event) => clearFields(type, event)}
        >
          <ClearIcon
            src={'/icons/simulate-bonus/input-clear.png'}
            width={12}
            height={12}
          />
        </ClearButton>
      ) : null}
    </InputArea>
  )
}
type Props = {
  onChangeInput: (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  clearFields: (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => void
  inputData: InputProps
}
const BonusJoinForm = ({ onChangeInput, clearFields, inputData }: Props) => {
  return (
    <FormArea autoComplete={'off'}>
      <Label>이름</Label>
      <InputComponent
        type={'name'}
        onChangeInput={onChangeInput}
        clearFields={clearFields}
        value={inputData.name}
      />
      <Label>전화번호</Label>
      <InputComponent
        type={'mobile'}
        onChangeInput={onChangeInput}
        clearFields={clearFields}
        value={inputData.mobile}
      />
      <Label>비밀번호</Label>
      <InputComponent
        type={'password'}
        onChangeInput={onChangeInput}
        clearFields={clearFields}
        value={inputData.password}
      />
    </FormArea>
  )
}

export default BonusJoinForm
