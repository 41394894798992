import { getData } from '@pages/Event/simulate/v2/axios/axios'
import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { useQuery } from '@tanstack/react-query'
import useSearchParams from '@hooks/useSearchParams'
const AlimTalkStatus = {
  OFFERED_DONE: 'OFFERED_DONE',
  ASSIGNMENT_DONE: 'ASSIGNMENT_DONE',
  SOLD_DONE: 'SOLD_DONE',
}
type ResponseType = {
  assignedInfo: {
    assignedAmount: number
    minAssignedAmount: number // 최소 배정 주수
    minAssignmentProbability: number // 최소 배정 확률
    maxAssignedAmount: number // 최대 배정 주수
    maxAssignmentProbability: number // 최대 배정 확률
  }
  minSubscriptionAmount: number // 최소 청약 증거금
  id: number
  status: keyof typeof AlimTalkStatus
  offeringPrice: number // 공모가
  amountProfit: number
  stock: {
    code: string
    name: string
    imgUrl: string
  }
  securities: {
    code: string
    name: string
    imgUrl: string
  }
}
type Params = {
  investmentId: string
}

const getAlimTalkFunction = async ({ investmentId }: Params) => {
  const res = await getData<ResponseType, Params>(
    V2ApiUrls.ALIM_TALK + `/${investmentId}`
  )
  return res.data.data
}

const useGetAlimTalkData = () => {
  const { getParam } = useSearchParams()
  const investmentId = getParam('investmentId')
  return useQuery({
    queryKey: [`alimTalk-${investmentId}`],
    queryFn: () => getAlimTalkFunction({ investmentId }),
    enabled: !!investmentId,
  })
}
export default useGetAlimTalkData
