import React from 'react'
import styled from 'styled-components'
import {
  SpoqaBold,
  SpoqaLight,
  SpoqaMedium,
} from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import { Desktop600 } from '@constants/mediaQuery'
import useGetSimulatedData from '@hooks/useGetSimulatedData'
import { IpoStockGubun } from '@enums/StockEnum'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  @media screen and (max-width: ${Desktop600}px) {
    margin-top: 40px;
  }
`
const SpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${Desktop600}px) {
    padding: 0 24px;
  }
`

const Subtitle = styled(SpoqaBold)`
  color: ${Colors.g12};
  font-size: 26px;
  letter-spacing: -0.78px;
  line-height: 31px;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 18px;
    letter-spacing: -0.33px;
  }
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0 56px;
  @media screen and (max-width: ${Desktop600}px) {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    gap: 12px;
    padding: 12px 24px 40px 24px;
  }
`
const Item = styled.li`
  width: 100%;
  background-color: ${Colors.gf};
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.07);
  min-width: 388px;
  @media screen and (max-width: ${Desktop600}px) {
    min-width: 292px;
    border-radius: 12px;
  }
`
const ItemTopContent = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  height: 114px;
  border-bottom: 1px solid #eaeef3;
  @media screen and (max-width: ${Desktop600}px) {
    padding: 16px;
    height: 80px;
  }
`

const TopMiddleBox = styled.div`
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`
const CompanyName = styled.p`
  font-family: Pretendard700, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.33px;
  }
`
const AmountText = styled.p`
  font-family: Pretendard400, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #999ba9;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const RatioBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  gap: 2px;
  background-color: #dcdeeb;
  @media screen and (max-width: ${Desktop600}px) {
    width: 40px;
    height: 40px;
  }
`
const RatioNumber = styled.p`
  font-family: GmarketSans, sans-serif;
  font-size: 22px;
  letter-spacing: -0.34px;
  font-weight: 700;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 16px;
    letter-spacing: -0.245px;
  }
`
const RatioText = styled(SpoqaLight)`
  font-size: 12px;
  line-height: 14px;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 8px;
    margin-top: 0;
    line-height: 8px;
  }
`

const ItemBottomContent = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  @media screen and (max-width: ${Desktop600}px) {
    padding: 20px 16px;
  }
`
const BottomLeftBox = styled.div`
  font-family: Pretendard400, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #999ba9;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 14px;
    line-height: 17px;
  }
`
const BottomMiddleBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-family: Pretendard400, sans-serif;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.72px;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.33px;
  }
`
const ReturnRatio = styled.div<{ isLoss: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard500, sans-serif;
  font-size: 20px;
  line-height: 26px;
  background-color: ${(props) => (props.isLoss ? '#E0EFFF' : '#fee2e2')};
  border-radius: 8px;
  padding: 4px 8px;
  color: ${(props) => (props.isLoss ? '#3860DF' : '#ea363f')};
  margin-left: 8px;
  @media screen and (max-width: ${Desktop600}px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const Logo = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  @media screen and (max-width: ${Desktop600}px) {
    margin-right: 8px;
  }
  > img {
    width: 66px;
    height: 66px;
    @media screen and (max-width: ${Desktop600}px) {
      width: 48px;
      height: 48px;
    }
  }
`
const EmptyWrapper = styled.section`
  padding: 0;
  margin-bottom: 56px;
  @media screen and (max-width: ${Desktop600}px) {
    padding: 0 24px;
    margin-bottom: 36px;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 246px;
  width: 100%;
  border-radius: 12px;
  background-color: #eaeef3;
  margin-top: 24px;
  @media screen and (max-width: ${Desktop600}px) {
    height: 153px;
    margin-top: 12px;
  }
  > img {
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${Desktop600}px) {
      width: 70px;
      height: 70px;
    }
  }
`
const EmptyText = styled(SpoqaMedium)`
  color: ${Colors.g5};
  margin-top: 24px;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: ${Desktop600}px) {
    margin-top: 17px;
    font-size: 14px;
    line-height: 18px;
  }
`
const EmptyList = () => {
  return (
    <EmptyWrapper>
      <EmptyContainer>
        <img
          src={'/images/event/simulated_empty_completed.png'}
          alt={'empty_investment'}
        />
        <EmptyText>아직 투자가 완료된 종목이 없어요</EmptyText>
      </EmptyContainer>
    </EmptyWrapper>
  )
}
type Props = {
  onClickCard: (code: string) => void
}

const ScheduleListSectionBonus = ({ onClickCard }: Props) => {
  const {
    simulatedCompletedData: { data = [] },
  } = useGetSimulatedData('1bonus')
  return (
    <Section>
      <SpaceBetween>
        <Subtitle>지난 투자</Subtitle>
      </SpaceBetween>
      {data.length === 0 ? (
        <EmptyList />
      ) : (
        <List>
          {data.map(
            ({
              stockTicker,
              amountProfit = 0,
              amountReceived = 0,
              name,
              imgUrl,
              offeringPrice,
              score,
              stockCode,
            }) => {
              const isLoss = amountReceived < 0
              return (
                <Item
                  key={stockTicker}
                  onClick={() => onClickCard(stockCode)}
                  id={`event_160_02_main_stock_btn_click_${stockCode}`}
                >
                  <ItemTopContent>
                    <Logo>
                      <img src={imgUrl} alt={name} />
                    </Logo>
                    <TopMiddleBox>
                      <CompanyName>{name}</CompanyName>
                      <AmountText>
                        {IpoStockGubun.normal} | 공모가&nbsp;
                        {offeringPrice.toLocaleString()}원
                      </AmountText>
                    </TopMiddleBox>
                    <RatioBox>
                      <RatioNumber>{score}</RatioNumber>
                      <RatioText>매력지수</RatioText>
                    </RatioBox>
                  </ItemTopContent>
                  <ItemBottomContent>
                    <BottomLeftBox>투자손익</BottomLeftBox>
                    <BottomMiddleBox>
                      {amountReceived.toLocaleString()}원
                    </BottomMiddleBox>
                    <ReturnRatio isLoss={isLoss}>{amountProfit}%</ReturnRatio>
                  </ItemBottomContent>
                </Item>
              )
            }
          )}
        </List>
      )}
    </Section>
  )
}

export default ScheduleListSectionBonus
