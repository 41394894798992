import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import { Divider } from '@mui/material'
import { Dispatch } from 'redux'
import { useAppSelector } from '@store/selectors/utils'
import { setUser } from '@store/modules/userSlice'
import { useHistory } from 'react-router-dom'
import { SEASON1_MARKETING_PATH } from '@router/paths/simulate/pathMap'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  dispatch: Dispatch<any>
  infoAgree: boolean
  setInfoAgree: React.Dispatch<React.SetStateAction<boolean>>
  setShowTalkModal: React.Dispatch<React.SetStateAction<boolean>>
  isFromApp: boolean
}

const SimulatedBottomSheet: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  dispatch,
  infoAgree,
  setInfoAgree,
  setShowTalkModal,
  isFromApp,
}) => {
  const history = useHistory()
  const data = useAppSelector((state) => state.userReducer)

  function handleGoToEvent() {
    if (infoAgree) {
      setIsOpen(false)
      setShowTalkModal(true)
      localStorage.removeItem('isAgree')
    } else {
      return
    }
  }

  return (
    <>
      <BottomSheet
        open={isOpen}
        onDismiss={() => {
          setIsOpen(false)
        }}
        color={'white'}
      >
        <Container>
          <Title>이벤트 신청 정보가 없어요</Title>
          <Description>
            이벤트 참여를 원하실 경우, 이용약관에 동의해주세요
          </Description>
          {/* <Divider /> */}
          <MobileDivider />
          <MarketingArea>
            <MarKetingItem
              onClick={() => {
                dispatch(
                  setUser({
                    name: data.name,
                    phone: data.phone,
                    password: data.password,
                    infoAgree: !data.infoAgree,
                  })
                )
                setInfoAgree(!infoAgree)
              }}
            >
              <CheckIcon
                src={
                  infoAgree
                    ? '/icons/simulate-bonus-check-able.svg'
                    : '/icons/simulate-bonus-check.svg'
                }
              />
              개인정보 수집 이용 동의(필수)
              <RightIcon
                src='/icons/arrow_right.svg'
                onClick={(e) => {
                  e.stopPropagation()
                  localStorage.setItem('isAgree', 'true')
                  history.push(SEASON1_MARKETING_PATH)
                }}
              />
            </MarKetingItem>
          </MarketingArea>
        </Container>
        <WebDivider />
        <SheetFooter>
          <NextButton
            onClick={() => {
              setIsOpen(false)
              localStorage.removeItem('isAgree')
            }}
          >
            다음에
          </NextButton>
          <AgreeButton
            isAble={infoAgree}
            onClick={handleGoToEvent}
            isFromApp={isFromApp}
          >
            동의하고 이벤트 참여하기
          </AgreeButton>
        </SheetFooter>
      </BottomSheet>
    </>
  )
}

const WebDivider = styled(Divider)`
  display: block;
  width: 100%;
  padding-top: 32px;
  @media (max-width: 800px) {
    display: none;
  }
`

const MobileDivider = styled(Divider)`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`

const RightIcon = styled.img`
  margin-left: auto;
  cursor: pointer;
`
const CheckIcon = styled.img`
  margin-right: 14px;
  cursor: pointer;
`

const MarKetingItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: left;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  @media (max-width: 800px) {
    font-family: Pretendard400, sans-serif;
    font-size: 14px;
    line-height: 16.8px;
  }
`

const MarketingArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 24px;
`

const Title = styled.div`
  font-family: Pretendard700, sans-serif;
  font-size: 32px;
  line-height: 28.19px;
  margin-bottom: 16px;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 21.6px;
    margin-bottom: 8px;
  }
`
const Description = styled.div`
  font-family: Pretendard400, sans-serif;
  font-size: 22px;
  line-height: 26.4px;
  color: #555564;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 16.8px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
  width: 800px;
  justify-content: center;
  margin: auto;
  padding-top: 80px;
  @media (max-width: 800px) {
    width: 100%;
    padding-top: 24px;
  }
`

const SheetFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-left: 24px;
  align-items: center;
  text-align: center;
  width: 800px;
  justify-content: center;
  flex: 1;
  margin: auto;
  padding: 24px;
  @media (max-width: 800px) {
    padding-top: 20px;
    width: 100%;
    padding-left: 44px;
    padding-top: 0;
  }
`

const NextButton = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 22px;
  line-height: 26.4px;
  background-color: #fff;
  color: #aaaaaa;
  flex: 0.3;
  text-align: left;
  cursor: pointer;
  @media (max-width: 800px) {
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
`
const AgreeButton = styled.span<{ isAble: boolean; isFromApp: boolean }>`
  font-family: Pretendard700, sans-serif;
  font-size: 20px;
  line-height: 24px;
  background-color: ${(props) => (props.isAble ? '#0E6DFB' : '#E2E2E2')};
  color: ${(props) => (props.isAble ? '#FFF' : '#AAAAAA')};
  border-radius: 16px;
  padding: 17px 27px;
  flex: 0.7;
  cursor: pointer;
  @media (max-width: 800px) {
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
  }
`

export default SimulatedBottomSheet
