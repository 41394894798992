import React from 'react'
import styled from 'styled-components'

type Props = {
  onClick: () => void
}
const Button = styled.button`
  background-color: transparent;
  padding: 0;
`
const HeaderClose = ({ onClick }: Props) => {
  return (
    <Button onClick={onClick}>
      <img
        src={'/images/simulate-v2/close-white.png'}
        width={24}
        height={24}
        alt={'close'}
      />
    </Button>
  )
}

export default HeaderClose
