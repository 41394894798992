import { useHistory, useLocation } from 'react-router-dom'

const useSearchParams = () => {
  const { search } = useLocation()
  const history = useHistory()

  const getParam = (key: string) => {
    return new URLSearchParams(search).get(key) || ''
  }
  const getParams = (keys: string[]) => {
    return keys.reduce((acc, key) => {
      acc[key] = getParam(key)
      return acc
    }, {} as Record<string, string>)
  }
  const removeParam = (key: string) => {
    const params = new URLSearchParams(search)
    const pathname = history.location.pathname
    params.delete(key)
    return history.replace(`${pathname}?${params.toString()}`)
  }

  return { getParam, getParams, removeParam }
}
export default useSearchParams
