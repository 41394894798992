import React from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
`

const Modal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px 24px 24px;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 5;
`
const ModalClose = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 32px;
`
const ModalTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 80px;
`
const ModalTitle = styled.div`
  color: #0e6dfb;
  text-align: center;
  font-family: Pretendard, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  padding: 0 60px;
  line-height: normal;
  @media (max-width: ${Desktop}px) {
    font-size: 24px;
    padding: 0 40px;
  }
`
const ModalDownloadContainer = styled.div`
  display: flex;
  gap: 32px;
`
const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
`
const DownloadTitle = styled.div`
  color: #999ba9;
  font-family: Pretendard, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  @media (max-width: ${Desktop}px) {
    font-size: 18px;
    line-height: 22px;
  }
`
const DeviceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
  @media (max-width: ${Desktop}px) {
    gap: 12px;
  }
`
const QR = styled.img`
  height: 150px;
  width: 150px;
  @media (max-width: ${Desktop}px) {
    width: 120px;
    height: 120px;
  }
`
const Device = styled.div`
  display: flex;
  gap: 8px;
  margin-left: -16px;
  font-family: Pretendard, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  height: 56px;
  @media (max-width: ${Desktop}px) {
    font-size: 18px;
    height: 46px;
  }
  & > img {
    width: 48px;
    height: 48px;
    @media (max-width: ${Desktop}px) {
      width: 38px;
      height: 38px;
    }
  }
`
const ModalBottom = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dde1eb;
  padding: 24px 24px 0;
  color: #000;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: ${Desktop}px) {
    font-size: 14px;
  }
`
const Line = styled.img`
  height: 192px;
  @media (max-width: ${Desktop}px) {
    height: 153px;
  }
`
type Props = {
  onClose: () => void
}

const InstallBottomSheet = ({ onClose }: Props) => {
  return (
    <>
      <Background onClick={onClose} />
      <Modal>
        <ModalClose>
          <img src={'/img/download/X.svg'} alt='닫기 버튼' onClick={onClose} />
        </ModalClose>
        <ModalTop>
          <ModalTitle>
            일육공 모바일 앱 설치 후 더 많은 공모주 정보를 알아보세요!
          </ModalTitle>
          <ModalDownloadContainer>
            <DownloadContainer>
              <DownloadTitle>QR코드 다운로드</DownloadTitle>
              <QR src={'/img/samsung/QR.svg'} />
            </DownloadContainer>
            <Line src={'/img/samsung/line.svg'} />
            <DownloadContainer>
              <DownloadTitle>스토어 다운로드</DownloadTitle>
              <DeviceContainer>
                <Device
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
                    )
                  }
                >
                  <img src={'/icons/app_store.png'} alt='app-store' />
                  아이폰
                </Device>
                <Device
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
                    )
                  }
                >
                  <img src={'/icons/play_store.png'} alt='play-store' />
                  안드로이드폰
                </Device>
              </DeviceContainer>
            </DownloadContainer>
          </ModalDownloadContainer>
        </ModalTop>
        <ModalBottom>
          <img src={'/img/download/Camera.svg'} alt='카메라' />
          모바일 카메라로 QR 코드를 스캔하면 다운로드 화면으로 이동합니다.
        </ModalBottom>
      </Modal>
    </>
  )
}

export default InstallBottomSheet
