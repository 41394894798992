import React, { useCallback, useEffect, useState } from 'react'
import V2Layout from '@layouts/V2Layout'
import styled from 'styled-components'
import V2Progress from '@pages/Event/simulate/v2/benefit/components/V2Progress'
import {
  useGetLatestMessage,
  useGetOnGoingStock,
  useGetRecentStock,
} from '@pages/Event/simulate/v2/hooks/useBenefit'
import Marquee from '@pages/Event/simulate/v2/benefit/components/Marquee'
import MySecurities from '@pages/Event/simulate/v2/benefit/components/MySecurities'
import { useGetMe } from '@pages/Event/simulate/v2/hooks/useAuth'
import OnGoingStock from '@pages/Event/simulate/v2/benefit/components/onGoingStock'
import { useAppSelector } from '@store/selectors/utils'
import usePostMessage from '@hooks/usePostMessage'
import useSearchParams from '@hooks/useSearchParams'
import RecentInvestment from '@pages/Event/simulate/v2/benefit/components/RecentInvestment'
import V2Notice from '@pages/Event/simulate/v2/component/V2Notice'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import { Link, useHistory } from 'react-router-dom'
import HeaderClose from '@pages/Event/simulate/v2/component/HeaderClose'
import { useOverlay } from '@toss/use-overlay'
import OverlayBackground from '@pages/Event/simulate/v2/component/OverlayBackground'
import InstallBottomSheet from '@components/simulated-trading/InstallBottomSheet'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import { useScrollBlock } from '@hooks/useScrollBlock'
import V2DownloadSheet from '@pages/Event/simulate/v2/benefit/components/V2DownloadSheet'
import {
  AVAILABLE_MY_LINK_VERSION,
  AVAILABLE_SHARE_FOR_ANDROID_VERSION,
} from '@constants/index'
import { isAndroid, isMobile } from 'react-device-detect'
import { isKakaoInAppBrowser } from '@utils/index'
import AlimTalkPopup from '@pages/Event/simulate/v2/component/AlimTalkPopup'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const PaddingView = styled.article`
  padding: 0 24px;
`
const TopLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0;
  min-height: 125px;
  & > img {
    width: 312px;
    height: 77px;
  }
`
const MissionContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  & > span {
    font-size: 16px;
    font-family: Pretendard700, sans-serif;
    line-height: 19px;
    color: white;
  }
`

const MissionBox = styled.div`
  background-color: #ff5597;
  border-radius: 8px;
  padding: 8px;
  font-family: Pretendard600, sans-serif;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShareLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShareText = styled.span`
  color: white;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  height: 19px;
`

const PopupStyle = styled.div`
  background-color: #28282e;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.07);
  padding: 24px;
`
const PopupTitle = styled.h3`
  color: white;
  white-space: pre-wrap;
  margin-bottom: 32px;
  font-size: 22px;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
`
const PopupDescription = styled.p`
  color: white;
  white-space: pre-wrap;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  font-family: Pretendard400, sans-serif;
`
const PopupLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #237bff;
  height: 56px;
  color: white;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
`
const BlurBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(4px);
  z-index: 5;
`

const ALIM_TALK_STATUS = {
  OFFERED_DONE: 'OFFERED_DONE',
  ASSIGNMENT_DONE: 'ASSIGNMENT_DONE',
  SOLD_DONE: 'SOLD_DONE',
}

const POP_UP_STATUS = {
  NOT_SECURITY: {
    title: '선택된 증권사가 없어요',
    description: '증권사 선택 후 게임 진행이 가능합니다.',
    buttonText: '증권사 추가하기',
    navigatePath: '/event/simulate/v2/select-securities',
  },
  NOT_TOKEN: {
    title: '이벤트 참가 정보를\n확인해주세요',
    description:
      '이벤트 참가 신청 확인 후\n내 게임현황을 조회하실 수 있습니다.',
    buttonText: '참가 신청 확인하기',
    navigatePath: '/event/simulate/v2/sign-check',
  },
}
type POPUP_TYPE = 'NOT_SECURITY' | 'NOT_TOKEN'

const HeaderInShare = ({ onClick }: { onClick: () => void }) => {
  return (
    <ShareLink onClick={onClick} id={'event_160_03_main_share_btn_click'}>
      <ShareText>공유</ShareText>
    </ShareLink>
  )
}

const V2BenefitPage = () => {
  const { data, isError } = useGetMe()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const { data: messageList } = useGetLatestMessage()
  const history = useHistory()
  const overlay = useOverlay()
  const isMobileDevice = isMobile
  const isMobileSize = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const [blockScroll, allowScroll] = useScrollBlock()
  const appVersion = localStorage.getItem('version')

  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  const { data: onGoingStockList, refetch } = useGetOnGoingStock()
  const { data: recentStockList } = useGetRecentStock()
  const { getParams } = useSearchParams()

  const isValidStatus = (
    status: string
  ): status is keyof typeof ALIM_TALK_STATUS => {
    return Object.values(ALIM_TALK_STATUS).includes(status as any)
  }

  const { fromApp, token, investmentId, status } = getParams([
    'fromApp',
    'token',
    'investmentId',
    'status',
  ])

  const openPopupHandler = (type: POPUP_TYPE) => {
    overlay.open(({ isOpen }) => (
      <OverlayBackground isOpen={isOpen}>
        <PopupStyle>
          <PopupTitle>{POP_UP_STATUS[type].title}</PopupTitle>
          <PopupDescription>{POP_UP_STATUS[type].description}</PopupDescription>
          <PopupLink to={POP_UP_STATUS[type].navigatePath}>
            <span>{POP_UP_STATUS[type].buttonText}</span>
          </PopupLink>
        </PopupStyle>
      </OverlayBackground>
    ))
  }

  const onClickCloseWebview = () => {
    if (fromApp || isFromApp) {
      postMessageHandler({
        action: 'close',
      })
    } else {
      history.push('/event/simulate/v2')
    }
  }
  const onClickCard = useCallback(
    (code: string) => {
      const formatted = {
        action: 'toStock',
        stockId: code,
      }
      // 앱에서 온 경우
      if (isFromApp || fromApp) {
        postMessageHandler(formatted)
      } else {
        setOpenBottomSheet(true)
      }
    },
    [isFromApp, fromApp, isMobileSize]
  )
  const V2_SHARE_DATA = {
    title: '일육공 160 - 공모주 투자의 확률을 높이다',
    text: '참가비 무료, 신청만 하면 바로 게임 시작! 최대 35만원의 주인공이 되어보세요.',
    url: 'https://160ipo.com/event/simulate/v2',
  }
  const onClickHeaderShare = async () => {
    // 이미 다 추가한 경우
    if (data?.securities.length === 8) {
      if (!isMobileSize) {
        navigator.clipboard
          .writeText(V2_SHARE_DATA.url)
          .then(() => {
            alert('URL을 복사하였습니다.')
          })
          .catch((err) => {
            console.error('URL 복사 실패:', err)
          })
        return
      }
      if (!navigator.share) {
        const version = localStorage.getItem('version')
        if (version && Number(version) >= AVAILABLE_SHARE_FOR_ANDROID_VERSION) {
          // 안드로이드 웹뷰 공유하기 기능 사용 가능자
          postMessageHandler({
            action: 'share',
            data: V2_SHARE_DATA,
          })
        }
        console.error('Web Share API is not supported.')
        return
      }
      try {
        await navigator.share(V2_SHARE_DATA)
      } catch (error) {
        console.log('close share')
      }
    } else {
      // 아직 추가할 증권사가 남은 경우
      if (!data) {
        // 유저 데이터 없는 경우 never
        return
      }
      history.push('/event/simulate/v2/share')
    }
  }
  const navigateTabLink = () => {
    if (appVersion && Number(appVersion) >= AVAILABLE_MY_LINK_VERSION) {
      return 'toMyTab'
    } else {
      return 'toInvestmentTab'
    }
  }

  const onClickToInvest = () => {
    const formatted = {
      action: navigateTabLink(),
    }
    if (isFromApp || fromApp) {
      postMessageHandler(formatted)
    } else {
      setOpenBottomSheet(true)
    }
  }
  const isShowShareInAndroid = () => {
    if (isAndroid && data?.securities.length === 8) {
      if (
        fromApp &&
        appVersion &&
        Number(appVersion) < AVAILABLE_SHARE_FOR_ANDROID_VERSION
      ) {
        return false
      }
      if (isKakaoInAppBrowser() && /android/i.test(navigator.userAgent)) {
        return false
      }
    }
    return true
  }
  useEffect(() => {
    if (token) {
      localStorage.setItem('accessToken', token)
    }
    if (isError) {
      openPopupHandler('NOT_TOKEN')
    }
    if (data && data.securities.length < 1) {
      openPopupHandler('NOT_SECURITY')
    }
  }, [isError, data, token, status])
  useEffect(() => {
    if (openBottomSheet) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [openBottomSheet])

  return (
    <>
      {isValidStatus(status) ? (
        <>
          <BlurBackground />
          <OverlayBackground isOpen={true}>
            <AlimTalkPopup status={status} />
          </OverlayBackground>
        </>
      ) : null}
      {openBottomSheet && (
        <>
          {isMobileDevice ? (
            <V2DownloadSheet
              isOpen={openBottomSheet}
              handleClose={() => setOpenBottomSheet(false)}
            />
          ) : (
            <InstallBottomSheet onClose={() => setOpenBottomSheet(false)} />
          )}
        </>
      )}
      <V2Layout
        LeftComponent={
          isShowShareInAndroid() ? (
            <HeaderInShare onClick={onClickHeaderShare} />
          ) : null
        }
        RightComponent={<HeaderClose onClick={onClickCloseWebview} />}
      >
        <Wrapper>
          <PaddingView>
            <TopLogo>
              <img
                src={'/images/simulate-v2/benefit-logo.png'}
                alt={'simulate-game'}
              />
            </TopLogo>
            <MissionContainer>
              <MissionBox>미션</MissionBox>
              <span>공모주 수익금 7만원을 달성하세요</span>
            </MissionContainer>
            <V2Progress />
          </PaddingView>
          <Marquee items={messageList?.messages || []} />
          <MySecurities list={(data && data.securities) || []} />
          <OnGoingStock
            onClickCard={onClickCard}
            list={onGoingStockList?.stocks || []}
            refetch={refetch}
          />
          <RecentInvestment
            onClickCard={onClickCard}
            list={recentStockList?.stocks || []}
          />
          <V2Notice />
        </Wrapper>
        <BottomButtonFixer hasBorder={false}>
          <WebBottomButtonWrapper>
            {!isMobileSize && (
              <WebShareBottomButton
                onClick={onClickHeaderShare}
                id={'event_160_03_main_share_btn_click'}
              >
                공유
              </WebShareBottomButton>
            )}
            <CommonButton
              id={'event_160_03_main_startinvest_btn_click'}
              onClick={onClickToInvest}
              buttonText={'일육공 간편투자 시작하기'}
              disabled={false}
            />
          </WebBottomButtonWrapper>
        </BottomButtonFixer>
      </V2Layout>
    </>
  )
}

const WebBottomButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`
const WebShareBottomButton = styled.button`
  background-color: #19191b;
  color: #237bff;
  min-width: 76px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
`

export default V2BenefitPage
