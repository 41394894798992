import React from 'react'
import EventSubTitleForWeb from '@pages/Event/components/EventSubTitleForWeb'
import SimulatedLayout from '@layouts/SimulatedLayout'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import styled from 'styled-components'
import HowInvestment from '@pages/Event/simulate/v1/EventCompletePage/components/HowInvestment'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@store/selectors/utils'

const Wrapper = styled.article`
  max-width: 800px;
  margin: 0 auto;
`
const Image = styled.img<{ ratio: number }>`
  max-width: 800px;
  width: 100%;
  object-fit: contain;
  object-position: top;
  aspect-ratio: ${({ ratio }) => ratio};
  height: auto;
  display: block;
  margin: 0;
  border: none;
`
const Divider = styled.div`
  height: 18px;
  background-color: #f7f8fa;
  @media (max-width: ${Desktop}px) {
    height: 8px;
  }
`
const BonusCompletePage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  return (
    <SimulatedLayout isFirst={true} hasClose={false} hasFooter={!isMobile}>
      {!isMobile && (
        <EventSubTitleForWeb
          title={'또 한 번의 기회! 공모주 게임 보너스 시즌 참여하기'}
          description={'~ 2025.04.08(조건 충족 기간)'}
        />
      )}
      <Wrapper>
        <Image
          ratio={5 / 7.77}
          src={'/images/simulate-bonus/bonus-complete-1.png'}
          alt={'bonus-season-information-1'}
        />
        <Image
          ratio={5 / 6}
          src={'/images/simulate-bonus/bonus-complete-2.png'}
          alt={'bonus-season-information-2'}
        />
        <Divider />
        {isFromApp ? (
          <Image
            ratio={5 / 7}
            src={'/images/simulate-bonus/bonus-complete-for-app.png'}
            alt={'bonus-season-information-3'}
          />
        ) : (
          <Image
            ratio={3.1 / 4.5}
            src={'/images/simulate-bonus/bonus-complete-3.png'}
            alt={'bonus-season-information-3'}
          />
        )}
        <HowInvestment
          guideId={'event_160_02_finish_guide_click'}
          buttonId={
            !isFromApp
              ? 'event_160_02_finish_appgo_click'
              : 'event_160_02_finish_startinvest_click'
          }
        />

        <NoticeBox type={'bonus-complete'} backgroundColor={'#F5F5F9'} />
        {!isMobile && (
          <CenterBox>
            <NavigateEvent to={'/event'}>이벤트 목록보기</NavigateEvent>
          </CenterBox>
        )}
      </Wrapper>
    </SimulatedLayout>
  )
}
const CenterBox = styled.button`
  display: flex;
  width: 100%;
  margin: 62px 0;
  justify-content: center;
`
const NavigateEvent = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 40px;
  color: #0e6dfb;
  cursor: pointer;
  border: 2px solid #0e6dfb;
  border-radius: 12px;
  font-family: Pretendard500, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
`

export default BonusCompletePage
