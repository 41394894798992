import { V2_INVESTMENT_NOTICE_LIST, V2_NOTICE_LIST } from '@constants/simulate2'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  padding: 32px 24px 0;
`
const Title = styled.h4`
  color: #ffffff;
  opacity: 0.8;
  font-family: Pretendard700, sans-serif;
  margin-bottom: 8px;
  font-size: 12px;
`
const Flex = styled.div`
  display: flex;
  gap: 4px;
`
const DescriptionText = styled.span`
  line-height: 14.4px;
  font-family: Pretendard400, sans-serif;
  font-size: 12px;
  color: #eaeef3;
  opacity: 0.8;
`
const MiddleNoticeBox = styled.div`
  padding: 16px 0 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`
const BoldBox = styled.div`
  display: flex;
  padding: 24px 0 32px;
`
const BoldNoticeText = styled(DescriptionText)`
  font-family: Pretendard700, sans-serif;
`

const V2Notice = () => {
  return (
    <Wrapper>
      <Title>확인해 주세요</Title>
      {V2_NOTICE_LIST.map(({ id, description }) => (
        <Flex key={id}>
          <DescriptionText>・</DescriptionText>
          <DescriptionText>{description}</DescriptionText>
        </Flex>
      ))}
      <MiddleNoticeBox>
        <DescriptionText>
          「소득세법]제21조(기타소득), 제127조(원천징수 의무),
          제164조(지급명세서의 제출)에 따라 제세공과금 납부 및 지급명세서 제출을
          위해 당첨자의 주민등록번호를 수집•이용합니다.
        </DescriptionText>
        <DescriptionText>
          이벤트를 통해 수집 • 이용된 개인정보는 해당 이벤트 당첨자 선정 및
          경품/상금 발송 용도로만 활용되며, 이벤트 종료(경품/상금 지급 완료) 후
          즉시 파기합니다.
        </DescriptionText>
      </MiddleNoticeBox>
      <Title>투자자 유의사항</Title>
      {V2_INVESTMENT_NOTICE_LIST.map(({ id, description }) => (
        <Flex key={id}>
          <DescriptionText>・</DescriptionText>
          <DescriptionText>{description}</DescriptionText>
        </Flex>
      ))}
      <BoldBox>
        <BoldNoticeText>
          * 엠엘투자자문㈜의 내부통제기준에 따라 준법감시 심의를 필함.
          <br /> ・ 엠엘광고준법 2025-제4호
          <br /> * 한국금융투자협회 심사필 제 25-00864호
          <br />
          (2025.03.10 - 2026.03.09)
        </BoldNoticeText>
      </BoldBox>
    </Wrapper>
  )
}

export default V2Notice
