import React from 'react'
import V2Layout from '@layouts/V2Layout'
import styled from 'styled-components'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '@store/selectors/utils'
import { setUser } from '@store/modules/v2UserSlice'
import { Colors } from '@styles/colors'
import HeaderBack from '@pages/Event/simulate/v2/component/HeaderBack'

const Wrapper = styled.article`
  padding: 24px 24px 0;
  background-color: ${Colors.bg_dark};
  flex-direction: column;
  display: flex;
  height: 100%;
`

const Title = styled.h2`
  color: white;
  line-height: 29px;
  font-size: 24px;
  letter-spacing: -0.72px;
  font-family: Pretendard700, sans-serif;
  margin-bottom: 40px;
`
const Subtitle = styled.h3`
  font-family: Pretendard700, sans-serif;
  line-height: 22px;
  font-size: 18px;
  color: white;
`
const Box = styled.div`
  padding: 16px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > span {
    color: #dcdeeb;
    line-height: 24px;
    font-size: 16px;
    font-family: Pretendard400, sans-serif;
  }
`

const V2MarketingAgreementPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const onClickHandler = () => {
    history.goBack()
    dispatch(
      setUser({
        agree: true,
      })
    )
  }
  return (
    <V2Layout LeftComponent={<HeaderBack onClick={() => history.goBack()} />}>
      <Wrapper>
        <Title>개인정보 수집 이용 동의(필수)</Title>
        <Subtitle>개인정보 수집 · 이용 동의 고지</Subtitle>
        <Box>
          <span>
            엠엘투자자문㈜는 (이하’회사’)고객님의 개인정보를 중요시하며,
            개인정보보호법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 및
            신용 정보의 이용 및 보호에 관한 법률 등을 준수하고 있습니다. 회사는
            관련 법률에 의하여 고객님께서 제공하시는 개인정보가 어떠한 용도와
            방식으로 수집, 이용되는지를 알려드리며, 이에 대한 고객님의 동의를
            받고자 합니다. 또한 회사는 개인정보취급방침을 개정하는 경우
            웹사이트(ml-invest.co.kr) 기업공시 (또는 개별 공지)를 통하여 공지할
            것입니다.
          </span>
          <span>
            회사는 이벤트의 신청, 경품/상금 지급 관리 등을 위하여 고객님의
            개인정보를 수집, 이용하고 있으며, 이를 위하여 회사는
            “개인정보보호법” 제15조, 제17조제1항제1호, 제22조제3항, “신용 정보의
            이용 및 보호에 관한 법률” 제 32조제1항, 제33조 및 제34조에 따라
            고객님으로부터 동의를 얻어야 합니다. 고객님은 아래의 내용을
            확인하시고, 고객님의 개인정보 수집, 이용 동의 여부를 표시하여 주시기
            바랍니다.
          </span>
        </Box>
        <Subtitle>개인정보 수집 · 이용에 대한 동의</Subtitle>
        <Box>
          <span>
            ① 개인정보의 수집, 이용 목적 : 이벤트의 신청, 관리 및 경품 지급에
            활용
            <br />
            ② 수집, 이용할 개인정보 항목 : 수집항목 - 이름, 핸드폰 번호, 이벤트
            확인용 비밀번호
            <br />③ 개인정보의 보유 및 이용 기간 : 이벤트 지원 대상 기간 종료 후
            1년
          </span>
          <span>
            고객님께서는 개인정보 수집, 이용에 대한 동의를 거부할 수 있습니다.
            다만 동의하지 아니한 경우 개인정보 수집, 이용 목적과 관련된 이벤트
            참여 및 경품/상금 발송 등의 제공을 받으실 수 없습니다.
          </span>
        </Box>
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <CommonButton
          onClick={onClickHandler}
          buttonText={'동의'}
          disabled={false}
        />
      </BottomButtonFixer>
    </V2Layout>
  )
}

export default V2MarketingAgreementPage
