import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { patchData } from '@utils/api'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number
  message: string
}

type RequestDataType = {
  name: string | null
  mobile: string | null
  newPassword: string | null
  season?: string
}

const usePatchSimulatedPassword = () => {
  const patchPasswordUrl = '/mock-investment/user/password'

  const simulatedPasswordData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError,
    RequestDataType
  >({
    mutationFn: (data) =>
      patchData<ReturnType<CurrentDataType>, RequestDataType>(
        patchPasswordUrl,
        data
      ),
    retry: false,
  })

  return {
    simulatedPasswordData,
  }
}

export default usePatchSimulatedPassword
