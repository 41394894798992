import React, { ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'
import V2JoinForm from '@pages/Event/simulate/v2/component/V2JoinForm'
import V2Layout from '@layouts/V2Layout'
import { Colors } from '@styles/colors'
import { useHistory } from 'react-router-dom'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { clearUser, setUser } from '@store/modules/v2UserSlice'
import useSignup, {
  ReturnErrorType,
} from '@pages/Event/simulate/v2/hooks/useSignup'
import HeaderClose from '@pages/Event/simulate/v2/component/HeaderClose'
import { setOpenToast } from '@store/modules/v2ToastSlice'
import useSearchParams from '@hooks/useSearchParams'
import { AxiosError } from 'axios'
import { normalizeSpaces } from '@utils/index'
import usePostMessage from '@hooks/usePostMessage'
import { S2MaxWidth } from '@constants/mediaQuery'
import { setCloseKeyboard } from '@store/modules/v2KeyboardSlice'
import { usePostSignIn } from '@pages/Event/simulate/v2/hooks/useAuth'

type InputStatus = 'name' | 'mobile'
type InputProps = Record<InputStatus, string>

const UnCheckIconSrc = '/images/simulate-v2/checkbox.png'
const CheckedIconSrc = '/images/simulate-v2/checkbox-active.png'

const V2SignUpPage = () => {
  const dispatch = useAppDispatch()
  const {
    name: storeName,
    mobile: storeMobile,
    agree: storeAgree,
  } = useAppSelector((state) => state.v2UserReducer)
  const history = useHistory()
  const { mutate } = useSignup()
  const { mutate: signIn } = usePostSignIn()
  const [errorMsg, setErrorMsg] = React.useState('')
  const { getParam, getParams } = useSearchParams()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const queryUserData = getParams(['name', 'mobile'])

  const [isMarketingCheck, setIsMarketingCheck] = React.useState<boolean>(false)

  const [inputData, setInputData] = React.useState<InputProps>({
    name: '',
    mobile: '',
  })
  const isValidName = inputData.name.length > 0
  const isValidMobile = inputData.mobile.length > 9
  const isValidButton = isValidName && isValidMobile && isMarketingCheck
  const handleMarketingCheckBox = () => {
    dispatch(setCloseKeyboard())
    if (isMarketingCheck) {
      setIsMarketingCheck(false)
    } else {
      setIsMarketingCheck(true)
    }
  }

  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    let formattedValue
    if (errorMsg) {
      setErrorMsg('')
    }
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }
  const navigateMarketingAgree = () => {
    const { name, mobile } = inputData
    dispatch(
      setUser({
        name,
        mobile,
        agree: isMarketingCheck,
      })
    )
    history.push(`/event/simulate/v2/marketing-agreement`)
  }
  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }
  const handleSignup = () => {
    const requestBody = {
      name: normalizeSpaces(inputData.name),
      mobile: normalizeSpaces(inputData.mobile),
      season: '2',
    }
    mutate(requestBody, {
      onSuccess: (data) => {
        if (data?.token) {
          localStorage.setItem('first', 'true')
        }
        dispatch(clearUser())
        if (isFromApp) {
          postMessageHandler({
            action: 'isJoin',
          })
        }
        history.replace(`/event/simulate/v2/signup-bridge`)
      },
      onError: (error) => {
        function isAxiosError<T>(err: unknown): err is AxiosError<T> {
          return err instanceof AxiosError
        }
        if (
          isAxiosError<
            ReturnErrorType<
              'APPLICATION_PERIOD_CLOSED' | 'ALREADY_PARTICIPATED'
            >
          >(error)
        ) {
          if (!error.response) {
            console.log(error)
            return
          }
          if (error.response.status === 400) {
            switch (error.response.data.info.code) {
              case 'APPLICATION_PERIOD_CLOSED': {
                return setErrorMsg('이벤트 기간이 종료되었습니다.')
              }
              case 'ALREADY_PARTICIPATED': {
                return setErrorMsg(
                  '이전 공모주 게임 참가자는 참가할 수 없습니다.'
                )
              }
              default: {
                console.log(error)
              }
            }
          }
          if (error.response.status === 409) {
            // 이미 가입된 계정
            signIn(requestBody, {
              onSuccess: () => {
                if (isFromApp) {
                  postMessageHandler({
                    action: 'isJoin',
                  })
                }
                history.push('/event/simulate/v2/benefit')
              },
            })
          }
        } else {
          console.log(error)
        }
      },
    })
  }
  const handleBack = () => {
    history.goBack()
  }
  useEffect(() => {
    const isNotApplyUser = getParam('notFound') === 'true'
    if (isNotApplyUser) {
      dispatch(setOpenToast('이벤트 참가 정보가 없습니다.'))
    }
  }, [])
  // 마케팅에서 온 경우 or signIn에서 온 경우
  useEffect(() => {
    if (storeName && storeMobile) {
      setInputData({
        name: storeName,
        mobile: storeMobile,
      })
    }
    if (queryUserData.name && queryUserData.mobile) {
      setInputData({
        name: queryUserData.name,
        mobile: queryUserData.mobile,
      })
    }

    if (storeAgree) {
      setIsMarketingCheck(true)
    }
  }, [])

  return (
    <V2Layout RightComponent={<HeaderClose onClick={handleBack} />}>
      <Wrapper>
        <Banner>
          <img
            src={'/images/simulate-v2/sign-up-banner.png'}
            alt={'simulate-game-season-2'}
          />
        </Banner>
        <Padding>
          <Title>참가 신청</Title>
          <V2JoinForm
            isError={!!errorMsg}
            onChangeInput={onChangeInput}
            clearFields={clearFields}
            inputData={inputData}
          />
          {errorMsg && <ErrorMessageText>{errorMsg}</ErrorMessageText>}
          <MarketingBox>
            <FlexBox onClick={handleMarketingCheckBox}>
              <img
                src={isMarketingCheck ? CheckedIconSrc : UnCheckIconSrc}
                alt={'marketing-agree'}
              />
              개인정보 수집 이용 동의(필수)
            </FlexBox>

            <NavigateButton onClick={navigateMarketingAgree}>
              <img
                src='/icons/arrow-right-white.png'
                width={24}
                height={24}
                alt={'navigate-marketing-agreement'}
              />
            </NavigateButton>
          </MarketingBox>
          <NoticeBox>
            <p>확인해주세요</p>
            <Flex>
              <span>・</span>
              <span>이전 공모주 게임 참가자는 참가할 수 없습니다.</span>
            </Flex>
            <Flex>
              <span>・</span>
              <span>
                참가 정보가 정확하지 않을 경우, 경품이나 상금을 받을 수
                없습니다.
              </span>
            </Flex>
            <Flex>
              <span>・</span>
              <span>
                참가 정보와 일육공 가입 정보(이름 및 전화번호)가 일치해야 경품
                및 상금이 지급됩니다.
              </span>
            </Flex>
          </NoticeBox>
        </Padding>
        <BottomButtonFixer hasBorder={false}>
          <CommonButton
            id={'event_160_03_sign_next_click'}
            disabled={!isValidButton}
            onClick={handleSignup}
            buttonText={'다음'}
          />
        </BottomButtonFixer>
      </Wrapper>
    </V2Layout>
  )
}
const Banner = styled.div`
  position: relative;
  background-color: ${Colors.bg_dark};
  align-items: flex-end;
  display: flex;
  > img {
    width: 100%;
    aspect-ratio: 3/1;
    max-width: ${S2MaxWidth}px;
  }
`
const Title = styled.h2`
  color: white;
  font-family: Pretendard700, sans-serif;
  line-height: 29px;
  letter-spacing: -0.72px;
  font-size: 24px;
  margin-bottom: 24px;
`

const Wrapper = styled.div`
  background-color: ${Colors.bg_dark};
  height: 100%;
`
const Padding = styled.div`
  padding: 32px 24px 0;
`
const MarketingBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
`
const NavigateButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
`
const FlexBox = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: white;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  gap: 8px;
  padding-block: 0;
  cursor: pointer;
  padding-inline: 0;
  & > img {
    width: 24px;
    height: 24px;
  }
`
const NoticeBox = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  & > p {
    color: #999ba9;
    font-size: 14px;
    font-family: Pretendard700, sans-serif;
    line-height: 17px;
    margin-bottom: 8px;
  }
`
const Flex = styled.div`
  display: flex;
  gap: 8px;
  & > span {
    color: #999ba9;
    font-family: Pretendard400, sans-serif;
    line-height: 17px;
    font-size: 14px;
  }
`
const ErrorMessageText = styled.p`
  color: #f57077;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  margin: 12px 0;
`

export default V2SignUpPage
