import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const Button = styled.button<{ isDisabled: boolean }>`
  border-radius: 12px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isDisabled }) => (isDisabled ? '#555564' : '#237bff')};
  color: ${({ isDisabled }) => (isDisabled ? '#999ba9' : '#ffffff')};
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  cursor: pointer;
  font-family: Pretendard400, sans-serif;
  &:disabled {
    cursor: not-allowed;
  }
`

type Props = {
  onClick: () => void
  buttonText: string
  disabled: boolean
  id?: string
}
const CommonButton = ({ buttonText, onClick, disabled, id }: Props) => {
  const debounceClick = _.debounce(() => {
    onClick()
  }, 300)

  return (
    <Button
      disabled={disabled}
      isDisabled={disabled}
      onClick={debounceClick}
      id={id}
      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      {buttonText}
    </Button>
  )
}

export default CommonButton
