import React, { useState } from 'react'

import { SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import { getNow } from '@utils/dateHelper'
import styled from 'styled-components'
import {
  InvestmentStatus,
  OnGoingStockType,
} from '@pages/Event/simulate/v2/hooks/useBenefit'

const Section = styled.section`
  background-color: #28282e;
  padding: 40px 24px;
  border-bottom: 1px solid #19191b;
`

const Subtitle = styled.p`
  font-size: 22px;
  font-family: Pretendard700, sans-serif;
  color: white;
  line-height: 26px;
`
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
const RefreshButton = styled.button<{ rotation: number }>`
  cursor: pointer;
  padding: 0;
  transition: transform 1s;
  transform: ${(props) => `rotate(${props.rotation}deg)`};
`
const NowDateText = styled.div`
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DateText = styled.span`
  font-family: Pretendard500, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #999ba9;
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Item = styled.li`
  display: flex;
  width: 100%;
  height: 88px;
`
const ItemCard = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: 12px;
  padding: 0 16px;
  background-color: ${Colors.bg_dark};
  height: 100%;
  cursor: pointer;
`

const CompanyLogo = styled.div`
  min-width: 40px;
  margin-right: 8px;
  > img {
    border-radius: 20px;
    width: 40px;
    height: 40px;
  }
`

const CompanyName = styled(SpoqaMedium)`
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  letter-spacing: -0.33px;
  color: white;
  font-size: 16px;
  line-height: 20px;
`
const CompanyStatus = styled.div<{ color: string }>`
  display: flex;
  text-align: right;
  justify-content: flex-end;
  padding: 0;
  margin-left: 34px;
  min-width: 50px;
  color: ${({ color }) => color};
`
const StatusName = styled.span`
  font-family: Pretendard500, sans-serif;
  font-size: 14px;
  line-height: 18px;
`

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  border-radius: 12px;
  gap: 12px;
  background-color: #19191b;
  > img {
    width: 70px;
    height: 70px;
  }
`
const EmptyText = styled.span`
  color: #eaeef3;
  font-size: 16px;
  font-family: Pretendard500, sans-serif;
  line-height: 24px;
`

const EmptyList = () => {
  return (
    <EmptyContainer>
      <img
        src={'/images/simulate-v2/empty-ongoing.png'}
        alt={'empty_investment'}
      />
      <EmptyText>현재 운용중인 종목이 없어요</EmptyText>
    </EmptyContainer>
  )
}
type Props = {
  onClickCard: (code: string) => void
  list: OnGoingStockType[]
  refetch: () => void
}

const OnGoingStock = ({ onClickCard, list, refetch }: Props) => {
  const [rotation, setRotation] = useState(0)
  const statusFormatingHandler = (status: keyof typeof InvestmentStatus) => {
    switch (status) {
      // 청약 완료
      case InvestmentStatus.OFFERED_DONE: {
        return {
          color: '#0EA5E9',
          krName: '청약완료',
        }
      }
      // 청약 예정
      case InvestmentStatus.OFFER_PENDING: {
        return {
          color: '#DCDEEB',
          krName: '청약예정',
        }
      }
      // 몇주 배정
      default: {
        return {
          color: '#86EFAC',
          krName: '주 배정',
        }
      }
    }
  }
  const refreshHandler = async () => {
    await refetch()
    setRotation((prev) => prev + 360)
  }
  return (
    <Section>
      <SpaceBetween>
        <Subtitle>진행 중인 투자</Subtitle>
        <NowDateText>
          <RefreshButton rotation={rotation} onClick={refreshHandler}>
            <img
              src={'/images/simulate-v2/refresh.png'}
              width={24}
              height={24}
              alt={'refresh'}
            />
          </RefreshButton>
          <DateText>{getNow().format('YY.MM.DD')} 기준</DateText>
        </NowDateText>
      </SpaceBetween>
      <List>
        {list.length === 0 ? (
          <EmptyList />
        ) : (
          list.map(({ name, code, securities, status, assignedAmount }) => {
            return (
              <Item key={code}>
                <ItemCard
                  onClick={() => onClickCard(code)}
                  id={`event_160_03_main_stock_btn_click_${code}`}
                >
                  <>
                    <CompanyLogo>
                      <img src={securities.imgUrl} alt={securities.name} />
                    </CompanyLogo>
                    <CompanyName>{name}</CompanyName>
                  </>

                  <CompanyStatus color={statusFormatingHandler(status).color}>
                    <StatusName>
                      {assignedAmount}
                      {statusFormatingHandler(status).krName}
                    </StatusName>
                  </CompanyStatus>

                  {/*{status === '매도완료' && (*/}
                  {/*  <ItemBottom>*/}
                  {/*    <ProfitText>투자손익</ProfitText>*/}
                  {/*    <ProfitBox>*/}
                  {/*      <ProfitAmount>*/}
                  {/*        {amountReceived?.toLocaleString()}원*/}
                  {/*      </ProfitAmount>*/}
                  {/*      <ReturnRatio isLoss={amountProfit < 0}>*/}
                  {/*        {amountProfit?.toLocaleString()}%*/}
                  {/*      </ReturnRatio>*/}
                  {/*    </ProfitBox>*/}
                  {/*  </ItemBottom>*/}
                  {/*)}*/}
                </ItemCard>
              </Item>
            )
          })
        )}
      </List>
    </Section>
  )
}

export default OnGoingStock
