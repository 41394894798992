import React, { useEffect } from 'react'
import styled from 'styled-components'

const FirstImageArea = styled.img`
  min-height: 550px;
  object-position: top;
  height: auto;
  display: block;
  margin: 0;
  border: none;
  object-fit: contain;
  width: 100%;
  max-width: 800px;
`
const ImageArea = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  border: none;
  max-width: 800px;
`
const lowSrc = '/images/simulate-bonus/bonus-detail-1-row.png'
const highSrc = '/images/simulate-bonus/bonus-detail-1.png'
const BonusDetail = () => {
  const [src, setSrc] = React.useState(lowSrc)
  useEffect(() => {
    const img = new Image()
    img.src = highSrc
    img.onload = () => setSrc(highSrc)
  }, [])
  return (
    <>
      <FirstImageArea src={src} alt={'simulate-event-1'} />
      <ImageArea
        src={'/images/simulate-bonus/bonus-detail-2.png'}
        alt={'simulate-event-2'}
      />
      <ImageArea
        src={'/images/simulate-bonus/bonus-detail-3.png'}
        alt={'simulate-event-3'}
      />
      <ImageArea
        src={'/images/simulate-bonus/bonus-detail-4.png'}
        alt={'simulate-event-4'}
      />
    </>
  )
}

export default BonusDetail
