import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import { useHistory } from 'react-router-dom'
import TradingNowButton from '@components/simulated-trading/TradingNowButton'
import SimulatedLayout from '@layouts/SimulatedLayout'
import { useMediaQuery } from 'react-responsive'
import { useScrollBlock } from '@hooks/useScrollBlock'
import Spinner from '@components/spinner/Spinner'
import BlurPagePopup from './components/BlurPagePopup'

const Background = styled.div`
  position: fixed;
  z-index: 2000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const Wrapper = styled.article`
  background-color: #fafafa;
  width: 100%;
  padding-bottom: 98px;
`
const Container = styled.section`
  width: 100%;
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: ${Desktop}px) {
    max-width: 800px;
  }
`

const BlurImage = styled.img`
  width: 100%;
  min-height: 100vh;
  filter: blur(8px);
  @media screen and (max-width: ${Desktop}px) {
    max-height: 100%;
    filter: blur(0);
  }
`

const SimulatedBlurPage = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isReady, setIsReady] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const [blockScroll, allowScroll] = useScrollBlock()

  const handleNavigate = (already: boolean = false) => {
    const SIGH_PAGE_URL = '/simulated-sign'
    if (already) {
      history.push(SIGH_PAGE_URL + '?param=alreadyapply')
    } else {
      history.push(SIGH_PAGE_URL)
    }
  }

  const handleOnLoad = () => {
    setIsReady(true)
  }
  const handleCloseModal = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => {
      allowScroll()
    }
  }, [isOpen])

  useEffect(() => {
    if (isReady) setIsOpen(true)
    else setIsOpen(false)
  }, [isReady])

  return (
    <Wrapper>
      {isOpen && (
        <>
          <Background onClick={handleCloseModal} />
        </>
      )}
      <SimulatedLayout hasClose={false} hasFooter={false} paddingBottom={0}>
        <Container>
          {isOpen ? (
            <BlurPagePopup handleCloseModal={handleCloseModal} />
          ) : null}
          {(!isReady || !isOpen) && <Spinner />}

          <BlurImage
            onClick={() => setIsOpen(true)}
            onLoad={handleOnLoad}
            src={
              !isMobile
                ? '/images/event/blur_web_image.png'
                : '/images/event/simulated_blur_page.png'
            }
            alt={'blur_event_page'}
          />
        </Container>
      </SimulatedLayout>

      <TradingNowButton />
    </Wrapper>
  )
}

export default SimulatedBlurPage
