import CalendarPage from '@pages/CalendarPage'
import FaqPage from '@pages/FaqPage'
import InfoPage from '@pages/InfoPage'
import EventKbPage from '@pages/EventKbPage'
import AppLinkBridge from '@pages/AppLinkBridge'
import WelcomeBridgePage from '@pages/WelcomeBridge'
import WelcomeCallSchemePage from '@pages/WelcomeCallSchemePage'
import RedirectSamsungPage from '@pages/RedirectSamsungPage'
import SamsungTest from '@pages/SamsungTest'
import InquirePage from '@pages/InquirePage'
import MainTestPage from '@pages/MainTestPage '
import GuidePage from '@pages/Guide'
import NewMainPage from '@pages/NewMainPage/index'
import SamsungEvent from '@pages/NewMainPage/samsungEvent/index'
import SamsungEvent_160 from '@pages/NewMainPage/samsungEvent/index-160'
import MobileMainPage from '@pages/NewMobilePage/index'
import TabletMainPage from '@pages/NewTabletPage/index'
import EventPage from '@pages/Event/Event'

import EventDeepLinkPage from '@pages/EventDeepLinkPage'
import { createRouterItem, RouterItemType } from '../routerItem'

export const MainRouterItem = createRouterItem({
  name: 'main',
  path: '/',
  component: NewMainPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const InfoRouterItem = createRouterItem({
  name: 'info',
  path: '/info',
  component: InfoPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const FaqRouterItem = createRouterItem({
  name: 'faq',
  path: '/faq',
  component: FaqPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const CalendarRouterItem = createRouterItem({
  name: 'calendar',
  path: '/calendar',
  component: CalendarPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const EventKbRouteItem = createRouterItem({
  name: 'kb-event',
  path: '/kb-event',
  component: EventKbPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})
export const AppLinkRouteItem = createRouterItem({
  name: 'app-link-bridge',
  path: '/app-link-bridge/:ci?',
  component: AppLinkBridge,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const WelcomeBridgeRouteItem = createRouterItem({
  name: 'welcome-bridge',
  path: '/welcome-bridge',
  component: WelcomeBridgePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const WelcomeCallSchemeRouteItem = createRouterItem({
  name: 'welcome-call-scheme',
  path: '/welcome-call-scheme',
  component: WelcomeCallSchemePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const RedirectSamsungRouteItem = createRouterItem({
  name: 'redirect-samsung',
  path: '/redirect-samsung',
  component: RedirectSamsungPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const SamsungTestRouteItem = createRouterItem({
  name: 'test-samsung',
  path: '/test-samsung',
  component: SamsungTest,
  type: RouterItemType.DEFAULT,
  exact: true,
})

// 문의하기 페이지
export const InquireItem = createRouterItem({
  name: 'inquire',
  path: '/inquire',
  component: InquirePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const MainTestRouterItem = createRouterItem({
  name: 'main-test',
  path: '/main-test',
  component: MainTestPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

//가이드
export const GuideItem = createRouterItem({
  name: 'guide',
  path: '/guide',
  component: GuidePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const NewMainItem = createRouterItem({
  name: 'new-main',
  path: '/new-main',
  component: NewMainPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const SamsungEventItem = createRouterItem({
  name: 'samsung-event',
  path: '/samsung-event',
  component: SamsungEvent,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const SamsungEvent160Item = createRouterItem({
  name: 'samsung-event-160',
  path: '/samsung-event-160',
  component: SamsungEvent_160,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const MobileMainPageItem = createRouterItem({
  name: 'mobile-main',
  path: '/mobile-main',
  component: MobileMainPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const TabletMainPageItem = createRouterItem({
  name: 'tablet-main',
  path: '/tablet-main',
  component: TabletMainPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

// 출시 후 작업내용 (event tab)
export const EventItem = createRouterItem({
  name: 'event',
  path: '/event',
  component: EventPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const EventDeepLinkItem = createRouterItem({
  name: 'event-connect',
  path: '/event-connect',
  component: EventDeepLinkPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

const baseRouterItems = [
  MainRouterItem,
  InfoRouterItem,
  FaqRouterItem,
  CalendarRouterItem,
  EventKbRouteItem,
  AppLinkRouteItem,
  WelcomeBridgeRouteItem,
  WelcomeCallSchemeRouteItem,
  RedirectSamsungRouteItem,
  SamsungTestRouteItem,
  InquireItem,
  MainTestRouterItem,
  GuideItem,
  NewMainItem,
  SamsungEventItem,
  MobileMainPageItem,
  TabletMainPageItem,
  SamsungEvent160Item,
  EventItem,
  EventDeepLinkItem,
]

export default baseRouterItems
