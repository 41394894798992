import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import usePostSimulatedData from '@hooks/usePostSimulatedData'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import { clearUser } from '@store/modules/userSlice'
import { useAppSelector } from '@store/selectors/utils'
import { useDispatch } from 'react-redux'
import usePostMessage from '@hooks/usePostMessage'
import useDebounce from '@hooks/useDebounce'
import { IsSimulationGameSeasonOneEnd } from '@utils/index'
import { useHistory } from 'react-router-dom'
import { SEASON1_BENEFIT_PATH } from '@router/paths/simulate/pathMap'

const Footer = (props: {
  isAble: boolean
  showMarketing: boolean
  isAlready: boolean
  setShowPwModal: Dispatch<SetStateAction<boolean>>
  setShowTalkModal: Dispatch<SetStateAction<boolean>>
  setIsBottomSheetOpen: Dispatch<SetStateAction<boolean>>
  paddingBottom: number
  bottomPosition: number
  isUp: boolean
}) => {
  const {
    isAble,
    showMarketing,
    isAlready,
    setShowPwModal,
    setShowTalkModal,
    setIsBottomSheetOpen,
    paddingBottom,
    bottomPosition,
    isUp,
  } = props
  const { simulatedIsExistData } = usePostSimulatedData()
  const { mutate: postSimulatedIsExistData } = simulatedIsExistData
  const dispatch = useDispatch()
  const history = useHistory()
  const data = useAppSelector((state) => state.userReducer)
  const { postMessageHandler } = usePostMessage()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isEndGame = IsSimulationGameSeasonOneEnd()

  const formatted = {
    action: 'isJoin',
  }
  const handleNextButton = () => {
    if (!isAble) return
    const postData = {
      name: data.name,
      mobile: formatPhoneNumber(data.phone),
      password: data.password,
    }

    if (isAlready) {
      postSimulatedIsExistData(postData, {
        onSuccess: () => {
          dispatch(clearUser())
          postMessageHandler(formatted)
          history.push(SEASON1_BENEFIT_PATH)
        },
        onError: (error: any) => {
          console.error('실패:', error)
          if (
            error.response?.data?.message === '비밀번호가 일치하지 않습니다'
          ) {
            setShowPwModal(true)
          } else if (
            error.response?.data?.message === '이벤트 참여자가 아닙니다'
          ) {
            setIsBottomSheetOpen(true)
          }
        },
      })

      return
    }

    postSimulatedIsExistData(postData, {
      onSuccess: () => {
        if (isFromApp) {
          postMessageHandler(formatted)
        }
        dispatch(clearUser())
        history.push(SEASON1_BENEFIT_PATH)
      },
      onError: (err: any) => {
        console.log(err)
        if (err.response?.data.message === '비밀번호가 일치하지 않습니다') {
          setShowPwModal(true)
          return
        } else if (err.response?.data.message === '이벤트 참여자가 아닙니다') {
          setShowTalkModal(true)
          return
        } else {
          setIsBottomSheetOpen(true)
        }
      },
    })
  }

  const debouncedHandleNextButton = useDebounce(handleNextButton)
  return (
    <>
      <Container>
        <ButtonContainer
          paddingBottom={paddingBottom}
          bottomPosition={bottomPosition}
          isUp={isUp}
        >
          <Participation
            disabled={isEndGame}
            isAble={isEndGame ? false : isAble}
            isFromApp={isFromApp}
            showMarketing={showMarketing}
            onClick={debouncedHandleNextButton}
            id={
              isAlready
                ? 'event_160_01_sign_alreadydone_btn_click'
                : !showMarketing
                ? 'event_160_01_sign_signin_btn_click'
                : ''
            }
          >
            {isEndGame
              ? '이벤트 종료'
              : isAlready
              ? '입력완료'
              : !showMarketing
              ? '공모주 게임 시작'
              : '동의'}
          </Participation>
        </ButtonContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 48px;
  margin-top: -29px;
  position: relative;
`

const ButtonContainer = styled.div<{
  paddingBottom: number
  bottomPosition: number
  isUp: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 800px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  margin: auto;
  @media (max-width: 800px) {
    padding: 16px 24px;
    width: 100%;
    padding-bottom: ${({ paddingBottom }) =>
      paddingBottom ? paddingBottom : 24}px;
  }
`
const Participation = styled.button<{
  isAble?: boolean
  showMarketing?: boolean
  isFromApp?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isAble || props.showMarketing ? '#0E6DFB' : '#DCDEEB'};
  color: ${(props) =>
    props.isAble || props.showMarketing ? '#FFF' : '#999BA9'};
  cursor: ${(props) =>
    props.isAble || props.showMarketing ? 'pointer' : 'not-allowed'};
  text-align: center;
  font-family: Pretendard700, sans-serif;
  font-size: 20px;
  line-height: 24px;
  border-radius: 16px;
  padding: 16px 24px;
  box-sizing: border-box;
  min-width: 312px;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 0;
    border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
  }
`
export default Footer
