const V2_NOTICE_LIST = [
  {
    id: 1,
    description:
      '이벤트 혜택은 참여 기간(25.3.10-4.30) 내 참여 신청을 하신 분들을 대상으로 지급됩니다.',
  },
  {
    id: 2,
    description:
      '이벤트 기간 내 탈퇴 후 재가입 이력이 있는 경우 이벤트 대상에서 제외됩니다.',
  },
  {
    id: 3,
    description:
      '신청 일자와 상관없이 이벤트 기간 내 일육공이 청약 대행한 모든 공모주 투자 성과는 확인 가능합니다.',
  },
  {
    id: 4,
    description:
      '일육공은 자체 알고리즘을 통해 종목별 매력지수를 산정하여 50점 미만이 나올 경우, 고객의 리스크 관리를 위해 청약을 진행하지 않습니다.',
  },
  {
    id: 5,
    description:
      '공모주게임은 일육공과 아직 제휴가 완료되지 않은 증권사의 공모주 투자도 포함합니다.',
  },
  {
    id: 6,
    description:
      '공모주게임의 매도금액은 일육공의 실제 매도금액과 차이가 발생할 수 있습니다.',
  },
  {
    id: 7,
    description: '중복 참여는 불가하며, 참여 취소 및 변경이 불가합니다.',
  },
  {
    id: 8,
    description:
      "5만원(금), 4만원(은), 3만원(동) 상금은 미션 '공모주 수익금 7만원'을 달성한 참가자들을 기준으로 각 50명씩 추첨으로 선정합니다.",
  },
  {
    id: 9,
    description:
      '미션 달성 고객이 150명 미만일 경우, 미션 달성 고객을 대상으로 금, 은, 동 상금 수령 대상자를 추첨하고, 전체 참가자 중 남은 금, 은, 동 상금 수령 대상자를 추첨합니다.',
  },
  {
    id: 10,
    description: '행운상 30만원은 전체 참가자 중 3명을 추첨, 선발합니다.',
  },
  {
    id: 11,
    description:
      '모든 경품/상금을 수령하기 위해서는 이벤트 참여 신청 기간 종료 후, 한 달 내 일육공을 통해 증권사 계좌 개설/연결 후 실제 청약 서비스를 1회 이상 이용하셔야 합니다.',
  },
  {
    id: 12,
    description:
      '혜택은 경품 수령 조건을 충족시킨 분에 한하여 상금 대상자 추첨 완료 후, 2주 내 지급합니다.',
  },
  {
    id: 13,
    description: '마케팅 활용 동의 회원 대상으로 추첨이 진행됩니다.',
  },
  {
    id: 14,
    description: '경품/상금은 일육공에 연결된 본인 증권 계좌로 지급됩니다.',
  },
  {
    id: 15,
    description: '5만 원 초과 경품/상금은 제세 공과금(22%) 공제 후 지급됩니다.',
  },
  {
    id: 16,
    description:
      '경품/상금 증정일 기준, 고객 정보 오류(연락처 결번, 착신 정지, 수신 정지 등)일 경우에는 경품/상금 지급이 불가할 수 있습니다.',
  },
  {
    id: 17,
    description:
      '일부 경품/상금의 경우, 당첨 고객 대상 개별 연락드릴 예정이며 5회 이상 부재 시 당첨이 취소될 수 있습니다.',
  },
  {
    id: 18,
    description:
      '회원 정보가 잘못 기재되어 경품/상금을 받지 못하시는 경우 경품/상금은 지급되지 않습니다.',
  },
  {
    id: 19,
    description:
      '고객의 일육공 앱 서비스 가입 정보(성명, 연락처)와 이벤트 신청 정보가 동일해야 경품/상금이 지급됩니다.',
  },
  {
    id: 20,
    description:
      '다른 이름과 연락처로 경품/상금을 요구하는 경우 당첨이 임의 취소될 수 있습니다.',
  },
  {
    id: 21,
    description:
      '이벤트 경품/상금의 내용과 기간은 당사 사정과 신청인 사정에 따라 변동될 수 있습니다.',
  },
  {
    id: 22,
    description:
      '경품/상금 수령 이후 모든 사후 처리의 책임은 당첨자 본인에게 있습니다.',
  },
  {
    id: 23,
    description:
      '이벤트 전체 기간 및 경품 지급 일정은 실제 공모주 청약 일정 및 당사 내부 사정상 변경될 수 있습니다.',
  },
]
const V2_INVESTMENT_NOTICE_LIST = [
  {
    id: 24,
    description:
      '투자자는 본 서비스에 대하여 당사로부터 충분한 설명을 받을 권리가 있으며, 당사와 투자일임계약을 체결하기 전에 투자일임계약 권유문서 및 투자일임계약서를 반드시 읽어 보시기 바랍니다.',
  },
  {
    id: 25,
    description:
      '공모주 및 투자일임계약은 예금자보호법에 따라 보호되지 않습니다.',
  },
  {
    id: 26,
    description:
      '공모주는 가격 변동에 따라 투자 원금의 일부 또는 전부 손실이 발생할 수 있으며, 투자 손익은 전부 투자자 본인에게 귀속됩니다.',
  },
  {
    id: 27,
    description:
      '투자일임 수수료는 투자일임계약서 상 고객과의 합의에 의하며, 이외에도 거래 수수료 및 청약수수료가 발생합니다.',
  },
  {
    id: 28,
    description:
      '공모주에 대한 청약의 권유는 주관 증권회사에서 제공하는 투자설명서에 따릅니다.',
  },
  {
    id: 29,
    description:
      '공모주 일반투자자에게는 균등 배정 방식과 비례 배정 방식이 적용되어 각 배정 방식에 따라 공모주 배정 결과가 다를 수 있습니다.',
  },
  {
    id: 30,
    description:
      '공모주는 통상 상장 초기 가격 변동성이 크며, 상장 후 시가가 공모가를 하회할 경우 투자 손실이 크게 발생할 수도 있습니다.',
  },
]

export { V2_NOTICE_LIST, V2_INVESTMENT_NOTICE_LIST }
