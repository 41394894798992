import React from 'react'
import styled from 'styled-components'
import { useAppSelector } from '@store/selectors/utils'
import SimulatedLayout from '@layouts/SimulatedLayout'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import StatsOverview from '@pages/Event/simulate/v1bonus/benefit/components/StatsOverview'
import Sections from '@pages/Event/simulate/v1bonus/benefit/components/Sections'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'
import DetailView from './components/DetailView'
import WelcomeSnackbar from '@pages/Event/simulate/v1bonus/benefit/components/welcomeSnackbar'
import BottomNavigateButton from '@pages/Event/simulate/v1bonus/benefit/components/BottomNavigateButton'
import useGetSimulatedData from '@hooks/useGetSimulatedData'

const Article = styled.article`
  display: flex;
  flex-direction: column;
`
const MaxWidthView = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > p {
    font-family: Pretendard700, sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #0e6dfb;
  }
`
const AmountText = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 28px;
  line-height: 34px;
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 26px;
  }
`
const AmountBox = styled.div`
  height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: #f0f9ff;
  border-bottom: 1px solid #bae6fd;
`
const WonText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
`

// SummaryPage
const BenefitPage = () => {
  const {
    simulatedSummaryData: { data },
  } = useGetSimulatedData('1bonus')
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  return (
    <SimulatedLayout
      isFirst={true}
      paddingBottom={isFromApp ? 98 : isMobile ? 96 : 106}
      hasShareInHeader={true}
    >
      <WelcomeSnackbar />
      <Article>
        <StatsOverview summaryData={data} />
        <DetailView />
        {/*<AmountBox>*/}
        {/*  <MaxWidthView>*/}
        {/*    <p>일육공 간편투자 수익</p>*/}
        {/*    <AmountText>*/}
        {/*      {(data?.totalAmountReceived || 0).toLocaleString()}*/}
        {/*      <WonText>원</WonText>*/}
        {/*    </AmountText>*/}
        {/*  </MaxWidthView>*/}
        {/*</AmountBox>*/}
        <Sections />
        <NoticeBox type={'bonus'} />
      </Article>
      <BottomNavigateButton hasShare={true} />
    </SimulatedLayout>
  )
}

export default BenefitPage
