import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '@store/selectors/utils'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import ChangeSuccessPopup from '@pages/Event/simulate/v1bonus/change/components/changeSuccessPopup'
import useSearchParams from '@hooks/useSearchParams'
import usePatchSimulatedPassword from '@pages/Event/simulate/v1bonus/hooks/usePatchSimulatedPassword'
import { useHistory } from 'react-router-dom'
import CustomHeader from '@pages/Event/simulate/v1bonus/components/CustomHeader'
import { Desktop } from '@constants/mediaQuery'
import { normalizeSpaces } from '@utils/index'

// 비밀번호 재설정 페이지
const ChangeInfoPage = () => {
  const {
    simulatedPasswordData: { mutate },
  } = usePatchSimulatedPassword()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const [password, setPassword] = useState('')
  const [isShowModal, setIsShowModal] = useState(false)
  const { getParams } = useSearchParams()
  const history = useHistory()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)

  const { phone, name } = getParams(['phone', 'name'])
  const handlePwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const numericValue = value.replace(/[^0-9]/g, '')
    if (numericValue.length <= 4) {
      setPassword(numericValue)
    }
  }
  function handleSubmit() {
    const postData = {
      name: normalizeSpaces(name),
      mobile: formatPhoneNumber(phone),
      newPassword: password,
      season: '1bonus',
    }
    mutate(postData, {
      onSuccess: () => {
        setIsShowModal(true)
      },
      onError: (error) => {
        console.error('실패:', error)
      },
    })
  }

  // 비밀번호 변경을 누르는 첫 화면으로 이동
  const navigateStartHandler = () => {
    history.go(-2)
  }
  useEffect(() => {
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus)
      inputElement.addEventListener('blur', handleBlur)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus)
        inputElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [])

  return (
    <>
      <ChangeSuccessPopup
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
        isFromApp={isFromApp}
      />
      <CustomHeader onClickClose={navigateStartHandler} />

      <Container>
        <FlexContainer>
          <Title>비밀번호를 다시 설정해주세요</Title>
          <InputArea>
            <InputTitle>비밀번호</InputTitle>
            <Input
              ref={inputRef}
              placeholder='숫자 4자리'
              value={password}
              inputMode='numeric'
              onChange={handlePwChange}
            />
            {password && isFocused ? (
              <ClearButton onMouseDown={() => setPassword('')}>
                <img
                  alt={'clear'}
                  src={'/icons/simulate-bonus/input-clear.png'}
                  width={12}
                  height={12}
                />
              </ClearButton>
            ) : null}
          </InputArea>
        </FlexContainer>
        <Button
          type={'button'}
          disabled={password.length < 4}
          onClick={handleSubmit}
        >
          비밀번호 변경
        </Button>
      </Container>
    </>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`
const ClearButton = styled.button`
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 1;
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  margin-top: 16px;
  border-radius: 12px;
  color: ${Colors.gf};
  background-color: #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  &:disabled {
    background-color: #dcdeeb;
    color: #999ba9;
  }
`
const Input = styled.input`
  background-color: #f5f5f9;
  border-radius: 8px;
  padding: 0 24px;
  height: 56px;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #dcdeeb;
  &:not(:placeholder-shown) {
    background-color: ${Colors.gf};
  }
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const InputTitle = styled.div`
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: #999ba9;
`

const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  margin-bottom: 40px;
  font-family: Pretendard700, sans-serif;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px;
  height: 100%;
  flex: 1;
  padding: 136px 24px 16px;
  margin: 0 auto;
  @media (max-width: ${Desktop}px) {
    padding: 88px 24px 16px;
  }
`

export default ChangeInfoPage
