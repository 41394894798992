import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { RecentStockType } from '@pages/Event/simulate/v2/hooks/useBenefit'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #28282e;
  padding: 40px 0;
`

const SpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Subtitle = styled.p`
  font-size: 22px;
  font-family: Pretendard700, sans-serif;
  color: white;
  line-height: 26px;
  margin-bottom: 16px;
  padding-left: 24px;
`

const List = styled.ul`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 12px;
  padding: 0 24px;
`
const Item = styled.li`
  width: 100%;
  background-color: ${Colors.bg_dark};
  min-width: 292px;
  border-radius: 12px;
`
const ItemTopContent = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  height: 80px;
  border-bottom: 1px solid #28282e;
`

const TopMiddleBox = styled.div`
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`
const CompanyName = styled.p`
  font-family: Pretendard700, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: white;
`
const AmountText = styled.p`
  font-family: Pretendard400, sans-serif;
  color: #999ba9;
  font-size: 14px;
  line-height: 17px;
`

const ItemBottomContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
`
const BottomLeftBox = styled.div`
  font-family: Pretendard400, sans-serif;
  color: white;
  font-size: 14px;
  line-height: 17px;
`
const BottomMiddleBox = styled.div`
  display: flex;
  align-items: center;
`
const AmountReceivedText = styled.span`
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.33px;
  color: white;
`
const ReturnRatio = styled.div<{ isLoss: boolean }>`
  display: flex;
  margin-right: 8px;
  font-family: Pretendard700, sans-serif;
  color: ${(props) => (props.isLoss ? '#3860DF' : '#F87171')};
  font-size: 14px;
  line-height: 17px;
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  > img {
    border-radius: 20px;
    width: 40px;
    height: 40px;
  }
`
const EmptyWrapper = styled.section`
  padding: 0;
  margin: 0 24px;
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  border-radius: 12px;
  gap: 12px;
  background-color: #19191b;
  > img {
    width: 70px;
    height: 70px;
  }
`
const EmptyText = styled.span`
  color: #eaeef3;
  font-size: 16px;
  font-family: Pretendard500, sans-serif;
  line-height: 24px;
`
const EmptyList = () => {
  return (
    <EmptyWrapper>
      <EmptyContainer>
        <img
          src={'/images/simulate-v2/empty-recent.png'}
          alt={'empty_investment'}
        />
        <EmptyText>아직 투자가 완료된 종목이 없어요</EmptyText>
      </EmptyContainer>
    </EmptyWrapper>
  )
}
type Props = {
  onClickCard: (code: string) => void
  list: RecentStockType[]
}

const RecentInvestment = ({ onClickCard, list }: Props) => {
  return (
    <Section>
      <SpaceBetween>
        <Subtitle>지난 투자</Subtitle>
      </SpaceBetween>
      {list.length === 0 ? (
        <EmptyList />
      ) : (
        <List>
          {list.map(
            ({
              code,
              securities,
              offeringPrice,
              name,
              amountReceived,
              amountProfit,
            }) => {
              const isLoss = amountReceived < 0
              return (
                <Item
                  key={code}
                  onClick={() => onClickCard(code)}
                  id={`event_160_03_main_stock_btn_click_${code}`}
                >
                  <ItemTopContent>
                    <Logo>
                      <img src={securities.imgUrl} alt={securities.name} />
                    </Logo>
                    <TopMiddleBox>
                      <CompanyName>{name}</CompanyName>
                      <AmountText>
                        공모가
                        {offeringPrice.toLocaleString()}원
                      </AmountText>
                    </TopMiddleBox>
                  </ItemTopContent>
                  <ItemBottomContent>
                    <BottomLeftBox>투자손익</BottomLeftBox>
                    <BottomMiddleBox>
                      <ReturnRatio isLoss={isLoss}>{amountProfit}%</ReturnRatio>
                      <AmountReceivedText>
                        {amountReceived.toLocaleString()}원
                      </AmountReceivedText>
                    </BottomMiddleBox>
                  </ItemBottomContent>
                </Item>
              )
            }
          )}
        </List>
      )}
    </Section>
  )
}

export default RecentInvestment
