import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { useHistory } from 'react-router-dom'
import InstallBottomSheet from '@components/simulated-trading/InstallBottomSheet'
import { Desktop } from '@constants/mediaQuery'
import { useMediaQuery } from 'react-responsive'
import DownloadSheet from '@pages/Event/simulate/v1/EventCompletePage/components/DownloadSheet'
import { useAppSelector } from '@store/selectors/utils'
import usePostMessage from '@hooks/usePostMessage'
import { useScrollBlock } from '@hooks/useScrollBlock'
import { isMobile } from 'react-device-detect'
import { AVAILABLE_MY_LINK_VERSION } from '@constants/index'

const Wrapper = styled.section`
  padding: 0 53px 89px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  @media (max-width: ${Desktop}px) {
    padding: 0 24px 40px;
  }
`

const ButtonFixer = styled.div`
  position: fixed;
  width: 100%;
  margin: 0 auto;
  bottom: 0;
  align-items: center;
  z-index: 10;
  display: flex;
  background-color: ${Colors.gf};
  height: 106px;
  outline: none;
  @media screen and (max-width: ${Desktop}px) {
    padding: 0 24px;
    height: 98px;
  }
`

const StartButton = styled.button<{ isFromApp?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #0e6dfb;
  border-radius: 12px;
  height: 56px;
  cursor: pointer;
  @media screen and (max-width: ${Desktop}px) {
    border-radius: ${(props) => (props.isFromApp ? '4px' : '12px')};
  }
  > span {
    font-family: Pretendard500, sans-serif;
    color: ${Colors.gf};
    font-size: 16px;
    line-height: 19px;
  }
`
const WebButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 800px;
  display: flex;
`
const GuideBanner = styled.button`
  cursor: pointer;
  > img {
    width: 100%;
    max-height: 224px;
  }
`
type Props = {
  buttonId?: string
  guideId?: string
}

const HowInvestment = ({
  buttonId = 'event_160_01_finish_startinvest_btn_click',
  guideId,
}: Props) => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const isMobileDevice = isMobile
  const isMobileSize = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const history = useHistory()
  const [blockScroll, allowScroll] = useScrollBlock()
  const appVersion = localStorage.getItem('version')

  const navigateTabLink = () => {
    if (appVersion && Number(appVersion) >= AVAILABLE_MY_LINK_VERSION) {
      return 'toMyTab'
    } else {
      return 'toInvestmentTab'
    }
  }

  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const onClickDownloadButton = useCallback(() => {
    const formatted = {
      action: navigateTabLink(),
    }
    if (isFromApp) {
      postMessageHandler(formatted)
    } else {
      setIsBottomSheetOpen(true)
    }
  }, [isMobileSize, isFromApp])

  const onClickInvestmentStart = () => setIsBottomSheetOpen(!isBottomSheetOpen)
  useEffect(() => {
    if (isBottomSheetOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [isBottomSheetOpen])
  return (
    <>
      {isBottomSheetOpen && (
        <>
          {isMobileDevice ? (
            <DownloadSheet
              isOpen={isBottomSheetOpen}
              handleClose={() => setIsBottomSheetOpen(false)}
            />
          ) : (
            <InstallBottomSheet onClose={onClickInvestmentStart} />
          )}
        </>
      )}
      <Wrapper>
        <GuideBanner
          onClick={() => history.push('/guide?hasHeader=true')}
          id={guideId || ''}
        >
          <img src={'/images/simulateUpdate/guide-banner.png'} alt={'guide'} />
        </GuideBanner>
      </Wrapper>
      '
      {isBottomSheetOpen ? null : (
        <ButtonFixer>
          <WebButtonWrapper>
            <StartButton onClick={onClickDownloadButton} id={buttonId}>
              <span>
                {isFromApp
                  ? '간편투자하고 혜택 수령하기'
                  : '일육공앱에서 혜택 수령하기'}
              </span>
            </StartButton>
          </WebButtonWrapper>
        </ButtonFixer>
      )}
    </>
  )
}

export default HowInvestment
