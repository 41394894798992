import styled from 'styled-components'
import Modal from 'react-modal'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const existModalStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 500,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 56px 100px;
    justify-content: center;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
      padding: 40px 58px;
    }
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
    }
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    word-break: auto-phrase;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    color: #555564;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 16.8px;
    }
  `,
  BlueButton: styled.span`
    border: none;
    border-radius: 4px;
    background-color: #0e6dfb;
    color: #fff;
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    padding: 24px 0;
    width: 100%;
    @media (max-width: 800px) {
      font-family: Pretendard500, sans-serif;
      font-size: 16px;
      line-height: 19.2px;
      padding: 16.5px 0;
      width: 100%;
    }
  `,
}

const { ModalContainer, Title, Description, BlueButton } = Styled

const ExistModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...existModalStyle,
          content: {
            ...existModalStyle.content,
            width: '80%',
            maxWidth: '500px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>
            아쉽지만 이벤트
            <br />
            참여 대상이 아니에요
          </Title>
          <Description>
            이번 이벤트는 일육공 청약 무경험자 에게만 제공되는 혜택이에요.
            <br />
            다음에 더 좋은 기회로 찾아올게요!
          </Description>
          <BlueButton
            onClick={() => {
              setIsOpen(false)
            }}
          >
            혜택 홈으로 가기
          </BlueButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default ExistModal
