import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface V2ToastState {
  message: string
  isOpen: boolean
}

const initialState: V2ToastState = {
  isOpen: false,
  message: '',
}
const V2ToastSlice = createSlice({
  name: 'v2Toast',
  initialState,
  reducers: {
    setOpenToast(state, action: PayloadAction<string>) {
      state.isOpen = true
      state.message = action.payload
    },
    setCloseToast(state) {
      state.isOpen = false
      state.message = ''
    },
  },
})
export const { setOpenToast, setCloseToast } = V2ToastSlice.actions
export default V2ToastSlice.reducer
