import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import WebHeader from '@components/layout/WebHeader'

const Header = styled.header`
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: fixed;
  top: 0;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  > button {
    padding: 0;
    cursor: pointer;
  }
`
type Props = {
  hasClose?: boolean
  onClickClose: () => void
}
const CustomHeader = ({ hasClose = true, onClickClose }: Props) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  const handleBack = () => {
    history.goBack()
  }

  return isMobile ? (
    <Header>
      <button onClick={handleBack}>
        <img
          src={'/icons/simulate-bonus/back.png'}
          alt={'back'}
          width={24}
          height={24}
        />
      </button>
      {hasClose ? (
        <button onClick={onClickClose}>
          <img
            src={'/icons/only_x_black.png'}
            alt={'close'}
            width={24}
            height={24}
          />
        </button>
      ) : null}
    </Header>
  ) : (
    <WebHeader hasHeaderUnderLine={true} />
  )
}

export default CustomHeader
