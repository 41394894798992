import React from 'react'
import styled from 'styled-components'

type Props = {
  onClick: () => void
}
const Button = styled.button`
  background-color: transparent;
  padding: 0;
`

const HeaderBack = ({ onClick }: Props) => {
  return (
    <Button onClick={onClick}>
      <img
        src={'/images/simulate-v2/arrow-back-white.png'}
        width={24}
        height={24}
        alt={'back'}
      />
    </Button>
  )
}

export default HeaderBack
