import React from 'react'
import styled from 'styled-components'
import V2Layout from '@layouts/V2Layout'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import { useHistory } from 'react-router-dom'
import HeaderBack from '@pages/Event/simulate/v2/component/HeaderBack'

const Wrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
`
const Title = styled.h2`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  font-family: Pretendard700, sans-serif;
  color: white;
  margin-bottom: 40px;
`
const Label = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-family: Pretendard400, sans-serif;
  color: white;
  margin-bottom: 12px;
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`
const Item = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #28282e;
  border-radius: 16px;
  width: 100%;
`
const ItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    font-size: 14px;
    font-family: Pretendard400, sans-serif;
    line-height: 17px;
    color: white;
  }
`
const AmountText = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #ff5597;
`
const Plus = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #28282e;
`
const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const ConditionLabel = styled.p`
  color: #dcdeeb;
  margin: 48px 0 8px;
`
const ConditionText = styled.span`
  color: #eaeef3;
  font-size: 16px;
  line-height: 19px;
  font-family: Pretendard700, sans-serif;
`
const CloseButton = styled.button`
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  font-size: 16px;
  color: white;
  border: 1px solid white;
  background-color: #28282e;
  cursor: pointer;
  width: 100%;
`

const V2BenefitDetail = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  return (
    <V2Layout LeftComponent={<HeaderBack onClick={handleBack} />}>
      <Wrapper>
        <Title>미션 완료 혜택</Title>
        <Label>공모주 수익금 달성 보상</Label>
        <List>
          <Item>
            <ItemLeft>
              <img
                src={'/images/simulate-v2/gold-medal.png'}
                alt={'first-prize'}
                width={40}
                height={40}
              />
              <span>1~50등</span>
            </ItemLeft>

            <AmountText>5만 원</AmountText>
          </Item>
          <Item>
            <ItemLeft>
              <img
                src={'/images/simulate-v2/silver-medal.png'}
                alt={'first-prize'}
                width={40}
                height={40}
              />
              <span>51~100등</span>
            </ItemLeft>

            <AmountText>4만 원</AmountText>
          </Item>
          <Item>
            <ItemLeft>
              <img
                src={'/images/simulate-v2/bronze-medal.png'}
                alt={'first-prize'}
                width={40}
                height={40}
              />
              <span>101~150등</span>
            </ItemLeft>

            <AmountText>3만 원</AmountText>
          </Item>
          <Item>
            <ItemLeft>
              <span>151등~전원</span>
            </ItemLeft>

            <AmountText>1만 원</AmountText>
          </Item>
        </List>
        <CenterBox>
          <Plus>
            <img
              width={14}
              height={14}
              src={'/images/simulate-v2/gray-plus.png'}
              alt={'bonus'}
            />
          </Plus>
        </CenterBox>
        <Label>행운상</Label>
        <Item>
          <ItemLeft>
            <span>3명</span>
          </ItemLeft>
          <AmountText>각 30만 원</AmountText>
        </Item>
        <ConditionLabel>혜택 받는 조건</ConditionLabel>
        <ConditionText>일육공 간편투자 가입 후 공모주 청약 1회</ConditionText>
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <CloseButton onClick={handleBack}>닫기</CloseButton>
      </BottomButtonFixer>
    </V2Layout>
  )
}

export default V2BenefitDetail
