import { RouterItem } from '../routerItem'
import baseRouterItems from './base'
import CreatedSimulatePageList from './simulate/bonus'
import CreatedSimulateSeason1PageList from '@router/paths/simulate/version1'
import CreatedSimulateSeason2PageList from '@router/paths/simulate/version2'

const routerItems: RouterItem[] = [
  ...baseRouterItems,
  ...CreatedSimulatePageList,
  ...CreatedSimulateSeason1PageList,
  ...CreatedSimulateSeason2PageList,
]

export default routerItems
