import React, { useEffect } from 'react'
import V2Layout from '@layouts/V2Layout'
import styled from 'styled-components'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import { useHistory } from 'react-router-dom'
import HeaderClose from '@pages/Event/simulate/v2/component/HeaderClose'
import useSearchParams from '@hooks/useSearchParams'
import { isKakaoInAppBrowser, openInExternalBrowser } from '@utils/index'
import {
  AVAILABLE_SHARE_FOR_ANDROID_VERSION,
  FROM_APP_MESSAGE_KEY,
} from '@constants/index'
import usePostMessage from '@hooks/usePostMessage'

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`
const Banner = styled.div`
  display: flex;
  padding: 12px 16px;
  border-radius: 32px;
  width: 190px;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  color: #eaeef3;
  line-height: 19px;
  border: 1px solid white;
  margin-bottom: 16px;
`
const Title = styled.h2`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  font-family: Pretendard700, sans-serif;
  color: white;
`
const ImageBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
const ShareImage = styled.img`
  width: 180px;
  height: 170px;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`
const ShareButton = styled.button`
  background-color: #237bff;
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
`
const NextButton = styled.button`
  color: #999ba9;
  font-size: 16px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
`

const V2_SHARE_DATA = {
  title: '일육공 160 - 공모주 투자의 확률을 높이다',
  text: '참가비 무료, 신청만 하면 바로 게임 시작! 최대 35만원의 주인공이 되어보세요.',
  url: 'https://160ipo.com/event/simulate/v2',
}
// 맨처음 회원가입 로직을 타고 온 경우에만 해당함
const V2ShareGuidePage = () => {
  const history = useHistory()
  const { getParam } = useSearchParams()
  const { postMessageHandler } = usePostMessage()

  // 현황페이지에서 바로 공유하기로 접근한 경우
  const storageToken = localStorage.getItem('accessToken')
  const queryToken = getParam('accessToken')
  const storageFirst = localStorage.getItem('first')
  const onClickNextTime = () => {
    // 최초의 경우
    const isFirst = storageFirst === 'true'
    if (isFirst) {
      history.push('/event/simulate/v2/completed-ready')
    } else {
      history.push('/event/simulate/v2/benefit')
    }
    // 이외의 경우
  }

  const navigateToAddPage = () => {
    history.push('/event/simulate/v2/select-securities')
  }

  const onShareClick = async () => {
    //kakao browser 면서 android 사용자의 경우
    if (
      isKakaoInAppBrowser() &&
      /android/i.test(navigator.userAgent) &&
      storageToken
    ) {
      openInExternalBrowser(
        `https://160ipo.com/event/simulate/v2/share?accessToken=${storageToken}&first=${storageFirst}`
      )
      navigateToAddPage()
      return
    }
    if (!navigator.share) {
      // 안드로이드 웹뷰 사용자
      const AppVersion = localStorage.getItem('version')
      if (
        AppVersion &&
        Number(AppVersion) >= AVAILABLE_SHARE_FOR_ANDROID_VERSION
      ) {
        // 160앱과 bridge 통신이 가능한 경우
        postMessageHandler({
          action: FROM_APP_MESSAGE_KEY.SHARE,
          data: V2_SHARE_DATA,
        })
      } else {
        // 이전 버전 사용자
        openInExternalBrowser(
          `https://160ipo.com/event/simulate/v2/share?accessToken=${storageToken}&first=${storageFirst}`
        )
        navigateToAddPage()
      }
      return
    }
    try {
      await navigator.share(V2_SHARE_DATA)
      navigateToAddPage()
    } catch (err) {
      // 공유하기 그냥 닫는 경우
      console.log('close share')
    }
  }
  useEffect(() => {
    if (queryToken) {
      localStorage.setItem('accessToken', queryToken)
    }
    if (storageFirst) {
      localStorage.setItem('first', storageFirst)
    }
  }, [queryToken, storageFirst])
  useEffect(() => {
    const handleMessage = (event: any) => {
      // 다른 event error 메세지 때문에 추가
      const { type } = JSON.parse(event.data)
      try {
        if (type === 'shareResult') {
          // 공유 성공 시
          navigateToAddPage()
        }
      } catch (err) {
        console.error(
          `error occurred at React-Native transfer data errorMessage=${err}`
        )
      }
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: 'rerender',
        })
      )
    }
    window.addEventListener('message', handleMessage)
    document.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      document.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <V2Layout RightComponent={<HeaderClose onClick={onClickNextTime} />}>
      <Wrapper>
        <Banner>수익금 UP! 을 위한 찬스</Banner>
        <Title>
          친구에게 공유하면
          <br />
          증권사를 추가할 수 있어요!
        </Title>
        <ImageBox>
          <ShareImage src={'/images/simulate-v2/big-share.png'} alt={'share'} />
        </ImageBox>
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <ButtonWrapper>
          <NextButton onClick={onClickNextTime}>다음에</NextButton>
          <ShareButton
            id={'event_160_03_ready_share_click'}
            onClick={onShareClick}
          >
            공유하기
          </ShareButton>
        </ButtonWrapper>
      </BottomButtonFixer>
    </V2Layout>
  )
}

export default V2ShareGuidePage
