import React from 'react'
import styled from 'styled-components'
import useDebounce from '@hooks/useDebounce'
import { useAppSelector } from '@store/selectors/utils'

const Footer = (props: {
  isAble: boolean
  isSignCheck: () => void
  paddingBottom: number
}) => {
  const { isAble, isSignCheck, paddingBottom } = props
  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  const debouncedHandleNextButton = useDebounce(isSignCheck)

  return (
    <Container>
      <ButtonContainer
        onClick={debouncedHandleNextButton}
        paddingBottom={paddingBottom}
      >
        <Participation isAble={isAble} isFromApp={isFromApp}>
          비밀번호 변경
        </Participation>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 123px;
  margin-top: -29px;
`

const ButtonContainer = styled.div<{ paddingBottom: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-top: 1px solid #DCDEEB; */
  background-color: #fff;
  width: 800px;
  margin: auto;
  position: fixed;
  bottom: 0;
  z-index: 100;

  @media (max-width: 800px) {
    padding: 16px 24px;
    width: 100%;
    padding-bottom: ${({ paddingBottom }) =>
      paddingBottom ? paddingBottom : 24}px;
  }
`
const Participation = styled.div<{
  isAble?: boolean
  showMarketing?: boolean
  isFromApp?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: ${(props) =>
    props.isAble || props.showMarketing ? '#0E6DFB' : '#DCDEEB'};
  color: ${(props) =>
    props.isAble || props.showMarketing ? '#FFF' : '#999BA9'};
  cursor: ${(props) =>
    props.isAble || props.showMarketing ? 'pointer' : 'not-allowed'};
  text-align: center;
  font-family: Pretendard, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 16px;
  padding: 16px 24px;
  box-sizing: border-box;
  min-width: 312px;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    margin-bottom: 0px;
    border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
  }
`
export default Footer
