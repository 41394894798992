import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop600 } from '@constants/mediaQuery'
import { useMediaQuery } from 'react-responsive'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #2abcff;
  padding: 24px;
`
const MaxWidthView = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
`
const BonusBox = styled.div`
  display: flex;
  position: relative;
  height: 60px;
  background-color: #7dd3fc;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
`
const GiftIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  & > p {
    font-size: 16px;
    line-height: 19px;
  }
  & > span {
    font-size: 22px;
    line-height: 26px;
  }
`
const BonusText = styled.span`
  font-size: 28px;
  line-height: 34px;
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 26px;
  }
`
const BonusFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: Pretendard700, sans-serif;
  & > p {
    font-size: 16px;
    line-height: 19px;
  }
`
const BenefitFlexBox = styled(FlexBox)`
  padding: 16px 0;
`

const DetailButton = styled.button`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard500, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  width: 100%;
`
const ExpandedView = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
const BenefitLabel = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gf};
  color: #0390d0;
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  line-height: 22px;
  border-radius: 24px;
`
const BenefitTarget = styled.span`
  font-family: Pretendard500, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #19191b;
`
const Border = styled.div`
  height: 1px;
  background-color: #13a4e7;
  width: 100%;
  margin-top: 8px;
`
const GabBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const SubTitle = styled.span`
  font-size: 18px;
  font-family: Pretendard500, sans-serif;
  line-height: 22px;
  color: #19191b;
`
const AdditionalText = styled.span`
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  color: #19191b;
`
const Description = styled.span`
  font-size: 14px;
  font-family: Pretendard400, sans-serif;
  line-height: 17px;
  color: #19191b;
`
const ConditionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  & > p {
    font-family: Pretendard700, sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  }
`
const ConditionGabBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > p {
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 19px;
  }
  & > span {
    font-family: Pretendard400, sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const WebExpandButton = styled.button`
  text-decoration: underline;
  font-size: 18px;
  font-family: Pretendard500, sans-serif;
  line-height: 22px;
`

const WonText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
`

const DetailView = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop600}px)` })
  return (
    <Section>
      <MaxWidthView>
        <BonusBox>
          <GiftIcon
            src={'/icons/simulate-bonus/bonus-gift-icon.png'}
            alt={'bonus'}
          />
          <BonusFlexBox>
            <p className={'bonus-text'}>추가 보너스</p>
            <BonusText>
              +500,000<WonText>원</WonText>
            </BonusText>
          </BonusFlexBox>
        </BonusBox>
        {isExpanded && (
          <ExpandedView isExpanded={isExpanded}>
            <>
              <BenefitFlexBox>
                <BenefitLabel>혜택 1</BenefitLabel>
                <BenefitTarget>참가자 전원</BenefitTarget>
              </BenefitFlexBox>
              <GabBox>
                <SubTitle>
                  수익금 + 1만 원 추가 지급
                  <AdditionalText>(최대 3만 원)</AdditionalText>
                </SubTitle>
                <Description>3월 2째 주 중 일괄 지급</Description>
              </GabBox>
            </>
            <Border />
            <>
              <BenefitFlexBox>
                <BenefitLabel>혜택 2</BenefitLabel>
                <BenefitTarget>추첨 2명</BenefitTarget>
              </BenefitFlexBox>
              <GabBox>
                <SubTitle>청약 지원금 50만 원 추가 지급</SubTitle>
                <Description>당첨자 개별 연락</Description>
              </GabBox>
            </>
            <Border />
            <ConditionBox>
              <p>수령 조건</p>
              <ConditionGabBox>
                <p>
                  한 번만 청약해도 혜택 대상이 되며, 수령 고객은 청약 완료 기준
                  선착순 5천 명입니다.
                </p>
                <span>이벤트 기간에 따라 지급일정이 변동될 수 있습니다</span>
              </ConditionGabBox>
            </ConditionBox>
          </ExpandedView>
        )}
        {isMobile ? (
          <DetailButton
            id={'event_160_02_main_detail_btn_click'}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            {isExpanded ? '접기' : '혜택 자세히 보기'}
          </DetailButton>
        ) : (
          <ButtonWrapper>
            <WebExpandButton
              onClick={() => setIsExpanded((prev) => !prev)}
              id={'event_160_02_main_detail_btn_click'}
            >
              {isExpanded ? '접기' : '혜택 자세히 보기'}
            </WebExpandButton>
          </ButtonWrapper>
        )}
      </MaxWidthView>
    </Section>
  )
}

export default DetailView
