import React from 'react'
import styled from 'styled-components'
import { useAppSelector } from '@store/selectors/utils'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import SimulatedLayout from '@layouts/SimulatedLayout'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'
import FixedJoinButton from '@pages/Event/simulate/v1bonus/components/FixedJoinButton'
import ShareFloatingButton from '@pages/Event/simulate/v1bonus/components/ShareFloatingButton'
import BonusDetail from '@pages/Event/simulate/v1bonus/components/BonusDetail'
import EventSubTitleForWeb from '@pages/Event/simulate/v1bonus/components/EventSubTitleForWeb'
import useSetEntryPoint from '@hooks/useSetEntryPoint'
import { Link } from 'react-router-dom'

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const NavigateButton = styled(Link)`
  border: 2px solid #0e6dfb;
  color: #0e6dfb;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 228px;
  margin: 36px 56px;
  font-size: 24px;
  font-family: Pretendard500, sans-serif;
  letter-spacing: -0.72px;
  line-height: 29px;
`

// 보너스 시즌 (1.5) 메인 페이지
const BonusMainPage = () => {
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  useSetEntryPoint()

  return (
    <SimulatedLayout
      isFirst={true}
      paddingBottom={isFromApp ? 98 : isMobile ? 88 : 104}
      hasShareInHeader={true}
      hasClose={false}
    >
      <Article>
        {isMobile ? null : <EventSubTitleForWeb />}
        <BonusDetail />
        <NoticeBox type={'bonus'} />
        <ShareFloatingButton />
        <FixedJoinButton />
        {isMobile ? null : (
          <NavigateButton to={'/event'}>이벤트 목록보기</NavigateButton>
        )}
      </Article>
    </SimulatedLayout>
  )
}

export default BonusMainPage
