import React, { useState } from 'react'

import { SpoqaBold, SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import { getNow } from '@utils/dateHelper'
import styled from 'styled-components'
import { Desktop } from '@constants/mediaQuery'
import useGetSimulatedData from '@hooks/useGetSimulatedData'

const Section = styled.section`
  margin-top: 40px;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 40px;
  }
`

const Subtitle = styled(SpoqaBold)`
  font-size: 26px;
  letter-spacing: -0.33px;
  color: ${Colors.g12};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 18px;
  }
`
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${Desktop}px) {
    padding: 0 24px;
  }
`
const RefreshButton = styled.button<{ rotation: number }>`
  cursor: pointer;
  padding: 0;
  transition: transform 1s;
  transform: ${(props) => `rotate(${props.rotation}deg)`};
`
const NowDateText = styled.div`
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DateText = styled.span`
  font-family: Pretendard500, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.g7};
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
  }
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0 40px;

  @media screen and (max-width: ${Desktop}px) {
    padding: 12px 24px 40px 24px;
    gap: 8px;
  }
`
const Item = styled.li`
  display: flex;
  width: 100%;
`
const ItemCard = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: 12px;
  background-color: ${Colors.gf};
  padding: 38px 48px;
  box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.07);
  cursor: pointer;
  @media screen and (max-width: ${Desktop}px) {
    padding: 16px;
  }
`
const ItemTop = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${Desktop}px) {
    height: 40px;
  }
`
const ItemBottom = styled.div`
  border-top: 1px solid ${Colors.background_sub};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  padding-top: 32px;
  @media screen and (max-width: ${Desktop}px) {
    margin-top: 16px;
    padding-top: 16px;
  }
`
const ProfitText = styled.span`
  font-family: Pretendard400, sans-serif;
  color: ${Colors.g9};
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 13px;
    line-height: 17px;
  }
`
const ProfitBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: ${Desktop}px) {
    gap: 4px;
  }
`
const ProfitAmount = styled.span`
  font-family: Pretendard400, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.33px;
  }
`

const CompanyLogo = styled.div`
  min-width: 60px;
  margin-right: 16px;
  @media screen and (max-width: ${Desktop}px) {
    min-width: 40px;
    margin-right: 8px;
  }
  > img {
    width: 60px;
    height: 60px;
    @media screen and (max-width: ${Desktop}px) {
      width: 40px;
      height: 40px;
    }
  }
`

const CompanyName = styled(SpoqaMedium)`
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  letter-spacing: -0.33px;
  text-overflow: ellipsis;
  line-height: 22px;
  font-size: 22px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 16px;
    line-height: 16px;
  }
`
const CompanyStatus = styled.div<{ bg: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 48px;
  min-width: 118px;
  background-color: ${(props) => props.bg};
  border-radius: 50px;
  @media screen and (max-width: ${Desktop}px) {
    min-width: 74px;
    height: 30px;
  }
`
const StatusName = styled.span`
  font-family: Pretendard500, sans-serif;
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 18px;
  }
`
const ReturnRatio = styled.div<{ isLoss: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard500, sans-serif;
  font-size: 20px;
  line-height: 26px;
  background-color: ${(props) => (props.isLoss ? '#E0EFFF' : '#fee2e2')};
  width: 78px;
  height: 34px;
  border-radius: 8px;
  color: ${(props) => (props.isLoss ? '#3860DF' : '#ea363f')};
  @media screen and (max-width: ${Desktop}px) {
    width: 56px;
    height: 25px;
    font-size: 14px;
    line-height: 17px;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  border-radius: 12px;
  background-color: #eaeef3;
  @media screen and (max-width: ${Desktop}px) {
    padding: 24px 0;
  }
  > img {
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${Desktop}px) {
      width: 64px;
      height: 64px;
    }
  }
`
const EmptyText = styled(SpoqaMedium)`
  margin: 24px 0 11px;
  color: ${Colors.g5};
  letter-spacing: -0.33px;
  font-size: 22px;
  line-height: 26px;

  @media screen and (max-width: ${Desktop}px) {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 18px;
  }
`
const EmptyDesc = styled.span`
  word-break: auto-phrase;
  text-align: center;
  color: ${Colors.g9};
  font-family: SpoqaHanSansNeoMedium, sans-serif;
  @media screen and (max-width: ${Desktop}px) {
    font-family: SpoqaHanSansNeoRegular, sans-serif;
    letter-spacing: -0.33px;
    font-size: 13px;
    line-height: 17px;
  }
`
const EmptyList = () => {
  return (
    <EmptyContainer>
      <img
        src={'/images/event/simulated_empty_investment.png'}
        alt={'empty_investment'}
      />
      <EmptyText>현재 운용중인 종목이 없어요</EmptyText>
    </EmptyContainer>
  )
}
type Props = {
  onClickCard: (code: string) => void
}

const CurrentInvestmentBonus = ({ onClickCard }: Props) => {
  const [rotation, setRotation] = useState(0)
  const statusBackgroundColorHandler = (status: string) => {
    switch (status) {
      case '청약예정': {
        return '#E0EFFF'
      }
      case '청약완료': {
        return '#8FC7FF'
      }
      case '매도완료': {
        return '#FFCCC7'
      }
      // 몇주 배정
      default: {
        return '#CEE9D5'
      }
    }
  }
  const {
    simulatedCurrentData: { data, refetch },
  } = useGetSimulatedData('1bonus')
  const refreshHandler = async () => {
    await refetch()
    setRotation((prev) => prev + 360)
  }
  return (
    <Section>
      <SpaceBetween>
        <Subtitle>진행 중 투자</Subtitle>
        <NowDateText>
          <DateText>{getNow().format('YY.MM.DD')} 기준</DateText>
          <RefreshButton rotation={rotation} onClick={refreshHandler}>
            <img
              src={'/icons/simulated_refresh.png'}
              width={20}
              height={20}
              alt={'refresh'}
            />
          </RefreshButton>
        </NowDateText>
      </SpaceBetween>
      <List>
        {data && data.length === 0 ? (
          <EmptyList />
        ) : (
          data?.map(
            ({
              status,
              amountProfit,
              amountReceived,
              name,
              imgUrl,
              stockCode,
            }) => {
              return (
                <Item key={name}>
                  <ItemCard
                    onClick={() => onClickCard(stockCode)}
                    id={`event_160_02_main_stock_btn_click_${stockCode}`}
                  >
                    <ItemTop>
                      <CompanyLogo>
                        <img src={imgUrl} alt={name} />
                      </CompanyLogo>
                      <CompanyName>{name}</CompanyName>
                      <CompanyStatus bg={statusBackgroundColorHandler(status)}>
                        <StatusName>{status}</StatusName>
                      </CompanyStatus>
                    </ItemTop>

                    {status === '매도완료' && (
                      <ItemBottom>
                        <ProfitText>투자손익</ProfitText>
                        <ProfitBox>
                          <ProfitAmount>
                            {amountReceived?.toLocaleString()}원
                          </ProfitAmount>
                          <ReturnRatio isLoss={amountProfit < 0}>
                            {amountProfit?.toLocaleString()}%
                          </ReturnRatio>
                        </ProfitBox>
                      </ItemBottom>
                    )}
                  </ItemCard>
                </Item>
              )
            }
          )
        )}
      </List>
    </Section>
  )
}

export default CurrentInvestmentBonus
