import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { postData } from '@pages/Event/simulate/v2/axios/axios'
import { useMutation } from '@tanstack/react-query'

type SignupBody = {
  name: string
  mobile: string
  season: string
}
type SignupResponse = {
  token: string
}

export type ReturnErrorType<C> = {
  message: string
  path: string
  requestId: string
  status: number
  timestamp: string
  info: {
    code: C
  }
}

const signUpFunction = async (body: SignupBody) => {
  const res = await postData<SignupResponse, SignupBody>(
    V2ApiUrls.SIGN_UP,
    body
  )
  return res.data.data
}

const useSignup = () => {
  return useMutation({
    mutationFn: signUpFunction,
    onSuccess: (data) => {
      localStorage.setItem('accessToken', data?.token)
    },
  })
}

export default useSignup
