import React, { useEffect } from 'react'
import V2Layout from '@layouts/V2Layout'
import styled from 'styled-components'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import { useHistory } from 'react-router-dom'
import { useGetMe } from '@pages/Event/simulate/v2/hooks/useAuth'

const Wrapper = styled.div`
  width: 100%;
  padding: 72px 24px;
`
const Title = styled.h2`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
  font-family: Pretendard700, sans-serif;
  color: white;
`
const Description = styled.p`
  padding: 16px 0;
  color: #eaeef3;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
`
const CenterBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 80%;
  padding: 0 40px;
`
const MobileNumberBox = styled.div`
  background-color: #28282e;
  border-radius: 32px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: Pretendard700, sans-serif;
  line-height: 29px;
  letter-spacing: -0.72px;
  color: white;
  width: 100%;
  min-width: 237px;
`
const Caption = styled.span`
  color: white;
  margin: 24px 0 12px;
  font-size: 16px;
  font-family: Pretendard500, sans-serif;
  line-height: 24px;
`
const CompletedReadyPage = () => {
  const history = useHistory()
  const { data } = useGetMe()
  const formatPhoneNumber = (phoneNumber: string): string => {
    // 숫자 10자리로 가정하고 하이픈을 추가
    return phoneNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3')
  }
  const onClickNext = () => {
    history.push('/event/simulate/v2/benefit')
  }
  useEffect(() => {
    const isFirst = localStorage.getItem('first') === 'true'
    if (isFirst) {
      localStorage.removeItem('first')
    }
  }, [])

  return (
    <V2Layout>
      <Wrapper>
        <Title>게임 준비 완료</Title>
        <Description>
          실제 공모주 투자와 동일한 일정으로 진행되는 게임 소식을 카카오톡으로
          받아보세요.
        </Description>
        <CenterBox>
          <img
            width={64}
            height={64}
            src={'/images/simulate-v2/kakao-symbol.png'}
            alt={'mobile-number'}
          />
          <Caption>카카오톡 알림 수신 번호</Caption>
          <MobileNumberBox>
            {data && formatPhoneNumber(data.mobile)}
          </MobileNumberBox>
        </CenterBox>
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <CommonButton
          id={'event_160_03_ready_start_click'}
          onClick={onClickNext}
          buttonText={'게임 시작'}
          disabled={false}
        />
      </BottomButtonFixer>
    </V2Layout>
  )
}

export default CompletedReadyPage
