import React from 'react'
import styled from 'styled-components'
import { getNow } from '@utils/dateHelper'
import { useGetDashBoard } from '@pages/Event/simulate/v2/hooks/useBenefit'
import { Link } from 'react-router-dom'
import AnimatedCounter from '@components/simulated-trading/AnimatedCounter'

const Wrapper = styled.section`
  margin: 24px 0;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #28282e;
`
const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const Title = styled.h3`
  color: white;
  font-size: 22px;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
`
const DateBox = styled.div`
  background-color: #19191b;
  border-radius: 16px;
  padding: 4px 8px;
  color: #999ba9;
  font-size: 12px;
  font-family: Pretendard400, sans-serif;
  line-height: 14px;
`
const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  margin: 44px 0 24px;
`
const ProgressBar = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  border-radius: 24px;
  z-index: 1;
  background-color: #555564;
  overflow: hidden;
`
const ProgressActive = styled.div<{ percent: number }>`
  position: relative;
  transition: width 1s ease-in-out;
  border-radius: 0;
  width: ${(props) => props.percent}%;
  background: linear-gradient(90deg, #99335b 0%, #ff5597 82.05%);
  display: flex;
  align-items: center;
`
const ProgressAmount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #999ba9;
  position: relative;
  & > span {
    position: absolute;
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      left: 30.3%;
    }
    &:nth-child(3) {
      left: 63.7%;
    }
    &:nth-child(4) {
      right: 0;
    }
  }
`
const WonText = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: white;
`
const LinkBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
`
const BenefitLink = styled(Link)`
  color: #999ba9;
  text-decoration: underline #999ba9;
  font-size: 14px;
  font-family: Pretendard400, sans-serif;
  line-height: 17px;
`
const AmountTextBox = styled.div`
  display: flex;
  position: absolute;
  align-items: flex-end;
  right: 6px;
  top: 8px;
  color: white;
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  line-height: 22px;
  word-break: keep-all;
  width: 100%;
  left: 16px;
`
const TrophyImage = styled.img`
  position: absolute;
  right: 0;
  width: 48px;
  height: 58px;
  bottom: 8px;
`

const V2Progress = () => {
  const { data } = useGetDashBoard()
  const checkpoints: number[] = [0, 10000, 30000, 70000] // 0원, 1만원, 3만원, 7만원

  const currentValue = data?.totalProfitAmount || 0

  function calculateNewPercent(currentValue: number, checkpoints: number[]) {
    // 0원, 1만원, 3만원, 7만원에 해당하는 범위
    if (currentValue <= checkpoints[1]) {
      // 0원 ~ 1만원 사이
      return (currentValue / checkpoints[1]) * 33.3 // 33.3%까지 비례
    } else if (currentValue <= checkpoints[2]) {
      // 1만원 ~ 3만원 사이
      return (
        33.3 +
        ((currentValue - checkpoints[1]) / (checkpoints[2] - checkpoints[1])) *
          33.4
      ) // 33.3% ~ 66.7%
    } else {
      // 3만원 ~ 7만원 사이
      return (
        66.7 +
        ((currentValue - checkpoints[2]) / (checkpoints[3] - checkpoints[2])) *
          33.3
      ) // 66.7% ~ 100%
    }
  }
  const newPercent = calculateNewPercent(currentValue, checkpoints)
  return (
    <Wrapper>
      <TitleBox>
        <Title>나의 수익금</Title>
        <DateBox>{getNow().format('M월 D일')} 기준</DateBox>
      </TitleBox>
      <ProgressBarWrapper>
        <ProgressBar>
          <ProgressActive percent={Number(newPercent.toFixed(2))}>
            {data && data.totalProfitAmount >= 10000 ? (
              <AmountTextBox>
                {
                  <AnimatedCounter
                    targetAmount={data.totalProfitAmount}
                    hasWon={false}
                  />
                }
                <WonText>원</WonText>
              </AmountTextBox>
            ) : null}
          </ProgressActive>
          <TrophyImage src={'/images/simulate-v2/trophy.png'} alt={'goal'} />
        </ProgressBar>
        <ProgressAmount>
          <span>0원</span>
          <span>1만원</span>
          <span>3만원</span>
          <span>7만원</span>
        </ProgressAmount>
      </ProgressBarWrapper>
      <LinkBox>
        <BenefitLink
          to={'/event/simulate/v2/benefit-detail'}
          id={'event_160_03_main_reward_btn_click'}
        >
          미션 혜택 확인하기
        </BenefitLink>
      </LinkBox>
    </Wrapper>
  )
}

export default V2Progress
