// 공모주게임 시즌 2공유하기 기능 available 한 버전 기준
// 미정
const AVAILABLE_SHARE_FOR_ANDROID_VERSION = 370
const AVAILABLE_MY_LINK_VERSION = 370
const FROM_APP_MESSAGE_KEY = {
  SHARE: 'share',
}
export {
  AVAILABLE_SHARE_FOR_ANDROID_VERSION,
  FROM_APP_MESSAGE_KEY,
  AVAILABLE_MY_LINK_VERSION,
}
