import React, { useEffect, useState } from 'react'
import SignPopup from '@pages/Event/simulate/v1bonus/sign-check/components/signPopup'
import { useScrollBlock } from '@hooks/useScrollBlock'
import styled from 'styled-components'
import SimulatedLayout from '@layouts/SimulatedLayout'
import Spinner from '@components/spinner/Spinner'
import { useMediaQuery } from 'react-responsive'
import { Desktop, Desktop600 } from '@constants/mediaQuery'
import { useHistory } from 'react-router-dom'

const Background = styled.div`
  position: fixed;
  z-index: 2000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`
const BlurImage = styled.img`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  @media screen and (max-width: ${Desktop}px) {
    max-height: 100%;
    filter: blur(0);
  }
`
const SignCheckPage = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isReady, setIsReady] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop600}px)` })
  const history = useHistory()
  const handleOnLoad = () => {
    setIsReady(true)
  }
  const handleClosePopup = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => {
      allowScroll()
    }
  }, [isOpen])

  useEffect(() => {
    if (isReady) setIsOpen(true)
    else setIsOpen(false)
  }, [isReady])
  return (
    <SimulatedLayout hasClose={false} hasFooter={false}>
      {isOpen && (
        <>
          <Background onClick={handleClosePopup} />
        </>
      )}
      {isOpen ? <SignPopup handleClosePopup={handleClosePopup} /> : null}
      {(!isReady || !isOpen) && <Spinner />}

      <BlurImage
        onClick={() => setIsOpen(true)}
        onLoad={handleOnLoad}
        src={
          !isMobile
            ? '/images/simulate-bonus/bonus-blur-web.png'
            : '/images/simulate-bonus/bonus-blur-mobile.png'
        }
        alt={'blur_event_page'}
      />
    </SimulatedLayout>
  )
}

export default SignCheckPage
