import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { initializeDayjs } from './services/dayjs'

import App from './App'
import GlobalStyle from './styles/global'
import './styles/fonts/font.css'
import { Provider } from 'react-redux'
import store from './store/store'
import ScrollToTop from '@components/base/scrollToTop'

const firebaseConfig = {
  apiKey: 'AIzaSyC7QWA3m4E5usSvcn6By1oXg8OCykTOE5Q',
  authDomain: 'landing-e56d0.firebaseapp.com',
  projectId: 'landing-e56d0',
  storageBucket: 'landing-e56d0.appspot.com',
  messagingSenderId: '1069020972273',
  appId: '1:1069020972273:web:57044cd87f393c48159550',
  measurementId: 'G-7SGST95DCS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app)

const history = createBrowserHistory()

async function enableMocking() {
  if (process.env.NODE_ENV === 'test') {
    const { worker } = await import('./mocks/browser')
    await worker.start()
  }
}

initializeDayjs()
enableMocking().then(() => {
  createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <GlobalStyle />
          <App />
        </ScrollToTop>
      </Router>
    </Provider>
  )
})
