export const V2ApiUrls = {
  SIGN_IN: '/v1/stock-game/sign-in',
  ADD_SECURITIES: '/v1/stock-game/securities',
  ME: '/v1/stock-game/user/me',
  SIGN_UP: '/v1/stock-game/user',
  GET_SECURITIES: '/v1/stock-game/contractable-securities',
  DASHBOARD: '/v1/stock-game/dashboard',
  ONGOING: '/v1/stock-game/ongoing-stock',
  RECENT_STOCK: '/v1/stock-game/recent-listing-stock',
  LATEST_MESSAGE: '/v1/stock-game/latest-register-securities-message',
  ALIM_TALK: '/v1/stock-game/investment',
}
