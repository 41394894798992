import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import { useAppSelector } from '@store/selectors/utils'
import { Helmet } from 'react-helmet-async'
import Footer from '@components/layout/Footer'
import MobileHeader from '@components/layout/MobileHeader'
import WebHeader from '@components/layout/WebHeader'
import AppHeader from '@components/layout/AppHeader'
import usePostMessage from '@hooks/usePostMessage'
import { useHistory } from 'react-router-dom'
import useSetEntryPoint from '@hooks/useSetEntryPoint'
import useSearchParams from '@hooks/useSearchParams'

interface Props {
  children: React.ReactNode
  isFirst?: boolean
  hasFooter?: boolean
  hasBack?: boolean
  hasClose?: boolean
  paddingBottom?: number
  hasHeaderUnderLine?: boolean
  hasShareInHeader?: boolean
  rightComponent?: ReactNode | null
}

const Wrapper = styled.div<{ headerHeight: number; bottomHeight: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
  padding-top: ${(props) => props.headerHeight}px;
  padding-bottom: ${(props) => props.bottomHeight}px;
`

const SimulatedLayout = ({
  children,
  isFirst,
  hasBack = true,
  hasClose = true,
  hasFooter = true,
  paddingBottom = 98,
  hasHeaderUnderLine = true,
  hasShareInHeader = false,
  rightComponent = null,
}: Props) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { getParam } = useSearchParams()
  const source = getParam('source')
  const version = getParam('version')

  const { postMessageHandler } = usePostMessage()
  const history = useHistory()
  useSetEntryPoint()

  const handleWebviewClose = () => {
    postMessageHandler({
      action: 'close',
    })
  }

  const handleBackButton = () => {
    history.goBack()
  }
  useEffect(() => {
    if (source) {
      localStorage.setItem('source', source)
    }
    if (version) {
      // 160 app version
      localStorage.setItem('version', version)
    }
  }, [source, version])

  return (
    <Wrapper headerHeight={isFromApp ? 48 : 78} bottomHeight={paddingBottom}>
      {isMobile ? (
        <>
          {isFromApp ? (
            <AppHeader
              hasShareInHeader={hasShareInHeader}
              handleWebviewClose={handleWebviewClose}
              handleBackButton={handleBackButton}
              isFirst={isFirst}
              hasBack={hasBack}
              hasClose={hasClose}
              rightComponent={rightComponent}
            />
          ) : (
            <MobileHeader hasHeaderUnderLine={hasHeaderUnderLine} />
          )}
        </>
      ) : (
        <WebHeader hasHeaderUnderLine={hasHeaderUnderLine} />
      )}
      {children}
      {hasFooter && !isFromApp ? <Footer isFirst={isFirst} /> : null}
    </Wrapper>
  )
}

export default SimulatedLayout
