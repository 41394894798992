import React, { ChangeEvent } from 'react'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import BonusJoinForm from '@pages/Event/simulate/v1bonus/components/BonusJoinForm'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import { clearUser } from '@store/modules/userSlice'
import {
  BONUS_BENEFIT_PATH,
  BONUS_FORGET_PATH,
} from '@router/paths/simulate/pathMap'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { Link, useHistory } from 'react-router-dom'
import { Desktop600 } from '@constants/mediaQuery'
import { useMediaQuery } from 'react-responsive'
import usePostMessage from '@hooks/usePostMessage'
import usePostSimulatedData from '@pages/Event/simulate/v1bonus/hooks/usePostSimulatedData'
import { normalizeSpaces } from '@utils/index'

const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background-color: ${Colors.gf};
  width: 400px;
  height: fit-content;
  border: 1px solid ${Colors.ge};
  padding: 40px 0 16px;

  @media screen and (max-width: ${Desktop600}px) {
    width: 100%;
    height: 100%;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }
`

const Title = styled.h3`
  font-family: Pretendard700, sans-serif;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`
const ForgetLink = styled(Link)`
  color: #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
`
const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 24px;
`
const Button = styled.button<{ isApply?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 18px 24px;
  border-radius: 12px;
  flex: 1;
  font-size: 16px;
  line-height: 19px;
  color: ${({ isApply }) => (isApply ? Colors.gf : '#555564')};
  background-color: ${({ isApply }) => (isApply ? '#0E6DFB' : 'white')};
  font-family: Pretendard400, sans-serif;
  max-width: ${({ isApply }) => (isApply ? '100%' : '112px')};

  &:disabled {
    background-color: #dcdeeb;
    color: #999ba9;
  }
`
const MobileWrapper = styled.div`
  padding: 0 24px;

  @media screen and (max-width: ${Desktop600}px) {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
`
const Caption = styled.p`
  color: #999ba9;
  font-size: 16px;
  margin-top: 8px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  margin-bottom: 48px;
`
const ErrorMessage = styled.span`
  color: #ef4444;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
`

const MobileHeader = styled.div`
  display: flex;
  height: 48px;
  padding: 0 16px;
  margin-bottom: 40px;
  & > button {
    padding: 0;
  }
`

type Props = {
  handleClosePopup: () => void
}
type InputStatus = 'name' | 'password' | 'mobile'
type InputProps = Record<InputStatus, string>
const PasswordErrorMessage = '비밀번호가 일치하지 않습니다'
const AlreadyErrorMessage = '이전 시즌에 참여한 사용자는 참여할 수 없습니다'

const SignPopup = ({ handleClosePopup }: Props) => {
  const history = useHistory()

  const [inputData, setInputData] = React.useState<InputProps>({
    name: '',
    password: '',
    mobile: '',
  })
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  const { postMessageHandler } = usePostMessage()
  const {
    simulatedIsExistData: { mutate },
  } = usePostSimulatedData()
  const dispatch = useAppDispatch()
  const [errorMsg, setErrorMsg] = React.useState('')
  const isValidName = inputData.name.length > 0
  const isValidMobile = inputData.mobile.length > 0
  const isValidPassword = inputData.password.length === 4
  const isValidInputValues =
    isValidName && isValidMobile && isValidPassword && !errorMsg

  const handleSubmit = () => {
    const { name, password, mobile } = inputData
    const postData = {
      name: normalizeSpaces(name),
      mobile: formatPhoneNumber(mobile),
      password,
      season: '1bonus',
    }
    mutate(postData, {
      onSuccess: () => {
        dispatch(clearUser)
        if (isFromApp) {
          postMessageHandler({
            action: 'isJoin',
          })
        }
        history.replace(BONUS_BENEFIT_PATH)
      },
      onError: (error) => {
        if (!error.response) {
          return
        }
        if (error.response.data) {
          const { message, status } = error.response.data
          if (status === 409) {
            setErrorMsg(AlreadyErrorMessage)
            return
          }
          if (status === 400) {
            if (message === PasswordErrorMessage) {
              setErrorMsg(message)
              setInputData({
                ...inputData,
                password: '',
              })
              return
            }
          }
          if (error.response.data.message === '이벤트 참여자가 아닙니다') {
            alert('이벤트 참가 정보가 없습니다.')
            // history.push(BONUS_SIGN_PATH + '?type=yet')
            // const { mobile, name } = inputData
            // dispatch(
            //   setUser({
            //     name,
            //     phone: mobile,
            //     password: '',
            //   })
            // )
          }
        }
      },
    })
  }

  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    let formattedValue
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    if (type === 'password') {
      setErrorMsg('')
    }

    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }
  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }

  return (
    <ModalStyle>
      {isMobile && (
        <MobileHeader>
          <button onClick={handleClosePopup}>
            <img
              src={'/icons/simulate-bonus/back.png'}
              width={24}
              height={24}
              alt={'back'}
            />
          </button>
        </MobileHeader>
      )}
      <MobileWrapper>
        <Flex>
          <Title>참여 정보 확인</Title>
          <ForgetLink to={BONUS_FORGET_PATH}>비밀번호 찾기</ForgetLink>
        </Flex>
        <BonusJoinForm
          onChangeInput={onChangeInput}
          clearFields={clearFields}
          inputData={inputData}
        />
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        {errorMsg === AlreadyErrorMessage ? null : (
          <Caption>* 이전 시즌에 참여한 사용자는 참여할 수 없습니다.</Caption>
        )}
      </MobileWrapper>

      <ButtonWrapper>
        {isMobile ? null : (
          <Button type={'button'} onClick={handleClosePopup}>
            취소
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          type={'button'}
          isApply={true}
          disabled={!isValidInputValues}
        >
          누적상금보기
        </Button>
      </ButtonWrapper>
    </ModalStyle>
  )
}

export default SignPopup
