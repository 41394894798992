import { getData } from '@pages/Event/simulate/v2/axios/axios'
import { API_URL } from '@apis/axios'
import { useQuery } from '@tanstack/react-query'

type EventType = {
  endDate: Date
  eventCode: string
  id: number
  linkUrl: string
  startDate: Date
  web: {
    title: string
    dateDescription: string
    imgUrl: string
  }
}
type ResponseDataType = {
  endedEvents: EventType[]
  ongoingEvents: EventType[]
}

const getEventFunction = async () => {
  const res = await getData<ResponseDataType>(API_URL.EVENT)
  return res.data.data
}

const useGetEventList = () => {
  return useQuery({
    queryFn: getEventFunction,
    queryKey: ['event'],
  })
}
export default useGetEventList
