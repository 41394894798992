import React from 'react'
import styled from 'styled-components'

const PlayButton = () => {
  return (
    <Container>
      바로재생
      <PlayIcon src={'/images/new_play.svg'}  />
    </Container>
  )
}

const Container = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.33px;
  color: #0E6DFB;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-family: 'Pretendard 400', sans-serif;
`

const PlayIcon = styled.img`
  width: 24px;
  height: 24px;
`

export default PlayButton
