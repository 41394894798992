export enum Colors {
  background_sub = '#F4F4F4',
  g0 = '#000',
  g3 = '#333',
  ga = '#AAAAAA',
  ge = '#eeeeee',
  gf = '#fff',
  g9 = '#999',
  g12 = '#121212',
  g40 = '#404040',
  gc = '#ccc',
  g5 = '#555',
  g7 = '#777',
  gE2 = '#e2e2e2',
  gFa = '#fafafa',
  gE9 = '#e9e9e9',
  PRIMARY = 'black',
  tooltip_red = '#E52121',
  tooltip_blue = '#175ECA',
  secondary = '#404040',
  primary_ios = '#fccb33',
  maingrey_bg = '#FAFAFA',
  predict = '#a3bee3',
  d_day = '#fccb33',
  open_day = '#A3D9B1',
  calender01_ios = '#D6E3F8',
  calender02_ios = '#FFE083',
  calender03_ios = '#CEE9D5',
  yellow_sub = '#FFE299',
  green = '#035640',
  green2 = '#177259',
  green3 = '#7AD4A7',
  greenLine = '#2B8C72',
  greenText = '#0A5B45',
  app_bar = '#FFEDB7',
  bg_focus = '#19191B',
  bg_samsung = '#18277E',
  event_blue = '0E26FB',
  text_dark = '#555564',
  bg_dark = '#19191B',
  pink = '#FF5597',
}
