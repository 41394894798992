import { ReactElement, useState } from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import routerItems from './router/paths'
import RouterRenderer from './router/routerRender'
import { Colors } from '@styles/colors'
import ChannelTalkManager from '@components/ChannelTalkManager'
import FloatBtn from '@components/base/FloatBtn'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import { OverlayProvider } from '@toss/use-overlay'

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.gf};
  height: 100%;
  min-height: 100dvh;
`

function App(): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  })
  // 시뮬게임에서만 사용

  const guidePage = window.location.href.includes('guide')
  const samsungEventPage = window.location.href.includes('samsung-event')
  const samsungEvent160Page = window.location.href.includes('samsung-event-160')
  const kbEventPage = window.location.href.includes('kb-event')
  const mobileMainPage = window.location.href.includes('mobile-main')
  const tabletMainPage = window.location.href.includes('tablet-main')
  const simulatedPage = window.location.href.includes('simulated-event')
  const eventPage = window.location.href.includes('event')
  const simulateSignPage = window.location.href.includes('simulated-sign')
  const simulateForgetPage = window.location.href.includes('simulated-forget')
  const simulateChangePwPage =
    window.location.href.includes('simulated-changepw')
  const simulateMarketingPage = window.location.href.includes(
    'simulated-marketing'
  )

  const isSimulatedTradingMainPage = window.location.href.includes(
    'simulated-trading-main'
  )
  const isSimulatedBlurPage = window.location.href.includes('simulated-blur')

  return (
    <OverlayProvider>
      <QueryClientProvider client={queryClient}>
        <Wrapper>
          <HelmetProvider>
            {/* {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !newMainPage &&
        !guidePage &&
        !samsungEventPage &&
        !samsungEvent160Page &&
        !mobileMainPage &&
        !tabletMainPage && <Header />} */}
            {!guidePage &&
              !samsungEventPage &&
              !mobileMainPage &&
              !tabletMainPage &&
              !kbEventPage &&
              !samsungEvent160Page &&
              !mobileMainPage &&
              !eventPage &&
              !simulatedPage &&
              !simulateSignPage &&
              !simulateForgetPage &&
              !simulateChangePwPage &&
              !simulateMarketingPage &&
              !isSimulatedBlurPage &&
              !isSimulatedTradingMainPage &&
              !kbEventPage && (
                <FloatBtn
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowDownBtn={true}
                />
              )}

            <ChannelTalkManager />

            <Switch children={RouterRenderer(routerItems)} />
            {/* {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !guidePage &&
        !newMainPage &&
        !mobileMainPage &&
        !tabletMainPage &&
        !samsungEventPage &&
        !samsungEvent160Page && <Footer />} */}
          </HelmetProvider>
        </Wrapper>
      </QueryClientProvider>
    </OverlayProvider>
  )
}

export default App
