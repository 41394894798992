import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  background-color: ${Colors.gf};
  height: 104px;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  @media (max-width: ${Desktop}px) {
    height: 88px;
  }
`
const Buttons = styled.div`
  max-width: 800px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  gap: 16px;
  display: flex;
  padding: 0 24px;
  @media (max-width: ${Desktop}px) {
    gap: 8px;
  }
`
const Button = styled(Link)`
  border-radius: 12px;
  width: 100%;
  border: 1px solid #0e6dfb;
  height: 56px;
  color: #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const EndButton = styled.button`
  border-radius: 12px;
  width: 100%;
  background-color: #dcdeeb;
  height: 56px;
  cursor: not-allowed;
  color: white;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// const JoinButton = styled(Button)`
//   border: none;
//   background-color: #0e6dfb;
//   color: ${Colors.gf};
// `

const FixedJoinButton = () => {
  return (
    <Wrapper>
      <Buttons>
        <Button
          to={'/event/simulate/v1bonus/sign_check'}
          id={'event_160_02_detail_cash_btn_click'}
        >
          누적상금보기
        </Button>
        <EndButton>참여기간 종료</EndButton>
        {/*<JoinButton*/}
        {/*  to={'/event/simulate/v1bonus/sign_up'}*/}
        {/*  id={'event_160_02_detail_start_btn_click'}*/}
        {/*>*/}
        {/*  참여기간 종료*/}
        {/*</JoinButton>*/}
      </Buttons>
    </Wrapper>
  )
}

export default FixedJoinButton
