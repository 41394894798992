import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import NotFoundUserPopup from '@pages/Event/simulate/v1bonus/forget/components/notFoundUserPopup'
import { Colors } from '@styles/colors'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import { useHistory } from 'react-router-dom'
import { BONUS_CHANGE_PATH } from '@router/paths/simulate/pathMap'
import useGetSimulatedUserData from '@pages/Event/simulate/v1bonus/hooks/useGetSimulatedUserData'
import CustomHeader from '@pages/Event/simulate/v1bonus/components/CustomHeader'
import { Desktop } from '@constants/mediaQuery'
import { normalizeSpaces } from '@utils/index'

const PLACE_HOLDER = {
  name: '이름을 입력하세요',
  phone: '-없이 입력하세요',
}
type InputStatus = 'name' | 'phone'

type InputComponentProps = {
  type: InputStatus
  onChangeInput: (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => void
  clearFields: (
    type: InputStatus,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void
  value: string
}

const InputComponent = ({
  onChangeInput,
  clearFields,
  type,
  value,
}: InputComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus)
      inputElement.addEventListener('blur', handleBlur)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus)
        inputElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [])

  const MaxLength = useMemo(() => {
    if (type === 'phone') {
      return 12
    }
    return 10
  }, [type])

  return (
    <InputArea>
      <Input
        ref={inputRef}
        autoComplete={'off'}
        maxLength={MaxLength}
        value={value}
        onChange={(event) => onChangeInput(type, event)}
        placeholder={PLACE_HOLDER[type]}
      />
      {value && isFocused ? (
        <ClearButton
          type={'button'}
          onMouseDown={(event) => clearFields(type, event)}
        >
          <ClearIcon
            src={'/icons/simulate-bonus/input-clear.png'}
            width={12}
            height={12}
          />
        </ClearButton>
      ) : null}
    </InputArea>
  )
}

const ForgetPage = (): ReactElement => {
  const history = useHistory()
  const [inputData, setInputData] = useState({
    name: '',
    phone: '',
  })
  const [showModal, setShowModal] = useState(false)
  const isValidName = inputData.name.length > 1
  const isValidPhone = inputData.phone.length > 10
  const isValidData = isValidName && isValidPhone
  const { simulatedCurrentData } = useGetSimulatedUserData(
    normalizeSpaces(inputData.name),
    formatPhoneNumber(inputData.phone),
    isValidData,
    '1bonus'
  )
  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    let formattedValue
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }

  const backHandler = () => {
    history.goBack()
  }
  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }

  const handleSubmit = () => {
    if (simulatedCurrentData.isSuccess) {
      // 비밀번호 재설정으로
      history.push(
        BONUS_CHANGE_PATH +
          '?phone=' +
          inputData.phone +
          '&name=' +
          normalizeSpaces(inputData.name)
      )
    } else {
      if (simulatedCurrentData.error) {
        if (simulatedCurrentData.error.response?.status === 400) {
          setShowModal(true)
        }
      }
    }
  }

  return (
    // <SimulatedLayout
    //   hasFooter={false}
    //   paddingBottom={16}
    //   hasClose={false}
    //   rightComponent={<HeaderClose onClickHandler={backHandler} />}
    // >
    <>
      <CustomHeader onClickClose={backHandler} />
      <Container>
        <FlexContainer>
          <DescriptionArea>
            <Title>
              비밀번호 재설정을 위해 <br />
              이벤트 신청 정보를 확인할게요
            </Title>
          </DescriptionArea>
          <InputArea>
            <InputTitle>이름</InputTitle>
            <InputComponent
              type={'name'}
              onChangeInput={onChangeInput}
              value={inputData.name}
              clearFields={clearFields}
            />
          </InputArea>
          <Gap />
          <InputArea>
            <InputTitle>전화번호</InputTitle>
            <InputComponent
              type={'phone'}
              onChangeInput={onChangeInput}
              clearFields={clearFields}
              value={inputData.phone}
            />
          </InputArea>
        </FlexContainer>

        <Button onClick={handleSubmit} disabled={!isValidData}>
          참여정보 확인
        </Button>
        <NotFoundUserPopup isOpen={showModal} setIsOpen={setShowModal} />
      </Container>
    </>
    // </SimulatedLayout>
  )
}
const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  margin-top: 16px;
  border-radius: 12px;
  color: ${Colors.gf};
  background-color: #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;

  &:disabled {
    background-color: #dcdeeb;
    color: #999ba9;
  }
`
const Gap = styled.div`
  height: 16px;
`
const Input = styled.input`
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 0 16px;
  height: 56px;
  outline: none;
  border: 1px solid #dcdeeb;
  background: #eaeef3;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  font-family: Pretendard400, sans-serif;
  &:not(:placeholder-shown) {
    background-color: ${Colors.gf};
  }
  &:focus {
    border: 1px solid #555564;
    background-color: ${Colors.gf};
  }
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ClearButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 22px;
`
const ClearIcon = styled.img`
  color: #999ba9;
  cursor: pointer;
  transition: color 0.3s ease;
`

const InputTitle = styled.div`
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: #19191b;
  @media (max-width: 400px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-family: Pretendard700, sans-serif;
  letter-spacing: -0.72px;
`

const DescriptionArea = styled.div`
  margin-bottom: 32px;
`

const Container = styled.div`
  width: 100%;
  max-width: 448px;
  height: 100%;
  padding: 136px 24px 16px;
  flex: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${Desktop}px) {
    padding: 88px 24px 16px;
  }
`

export default ForgetPage
