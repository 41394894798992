import styled from 'styled-components'
import Modal from 'react-modal'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const disabledModalStyle = {
  content: {
    border: 0,
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '16px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 400,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 32px;
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #19191b;
    margin-bottom: 40px;
  `,
  BlueButton: styled.button`
    border-radius: 12px;
    color: #19191b;
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    border: 1px solid #19191b;
    line-height: 19px;
    height: 56px;
    text-align: center;
    width: 100%;
  `,
  Line: styled.div`
    width: 100%;
    height: 1px;
    background-color: #eeeeee; /* 색상 */
    /* margin: 20px 0;  */
    margin-bottom: 16px;
  `,
}

const { ModalContainer, Title, Description, BlueButton, Line } = Styled

const NoInfoModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...disabledModalStyle,
          content: {
            ...disabledModalStyle.content,
            width: '80%', // 기본 너비를 80%로 설정
            maxWidth: '312px', // 최대 너비를 500px로 설정
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>이벤트 참여 정보가 없어요</Title>
          <Description>입력하신 정보를 다시 확인해주세요</Description>
          <BlueButton
            onClick={() => {
              setIsOpen(false)
            }}
          >
            확인하기
          </BlueButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default NoInfoModal
