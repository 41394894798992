import React from 'react'
import EventDetail from '@components/simulated-trading/EventDetail'
import SimulatedLayout from '@layouts/SimulatedLayout'

const SimulatedEventDetailPage = () => {
  return (
    <SimulatedLayout
      hasFooter={false}
      hasBack={true}
      paddingBottom={0}
      hasClose={false}
    >
      <EventDetail />
    </SimulatedLayout>
  )
}

export default SimulatedEventDetailPage
