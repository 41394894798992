import React from 'react'
import styled from 'styled-components'
import { BONUS_EVENT_SHARE_PATH, CopyURL, NavigateShare } from '@utils/index'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import { isAndroid } from 'react-device-detect'
import { useAppSelector } from '@store/selectors/utils'

const ShareContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 800px;
  width: 100%;
  position: fixed;
  bottom: 128px;
  left: 50%;
  z-index: 20;
  transform: translateX(-50%);
  padding-right: 24px;
  @media screen and (max-width: ${Desktop}px) {
    bottom: 104px;
  }
`
const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  justify-content: center;
  flex-direction: column;
  background-color: #5b6aed;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  color: ${Colors.gf};
  font-size: 12px;
  font-family: Pretendard700, sans-serif;
  line-height: 14px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
`
const ShareFloatingButton = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  const handleShare = async () => {
    if (isMobile) {
      await NavigateShare(BONUS_EVENT_SHARE_PATH)
    } else {
      CopyURL()
    }
  }
  if (isFromApp && isAndroid) return null
  return (
    <ShareContainer>
      <ButtonContainer
        onClick={handleShare}
        id={'event_160_01_detail_share_btn_click'}
      >
        <img src={'/icons/ic_share.png'} alt={'share'} width={24} height={24} />
        <span>공유</span>
      </ButtonContainer>
    </ShareContainer>
  )
}

export default ShareFloatingButton
