import { ReactElement } from 'react'
import styled from 'styled-components'
import useGetBottomInset from '@hooks/useGetBottomInset'
import { useAppSelector } from '@store/selectors/utils'
import { useHistory } from 'react-router-dom'
import { BONUS_SIGN_PATH } from '@router/paths/simulate/pathMap'
import Footer from '@pages/Event/simulate/v1/SimulatedMarketingPage/footer'

const BackButton = styled.button`
  height: 56px;
  display: flex;
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
`

const MarketingAgreementPage = (): ReactElement => {
  const history = useHistory()
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const bottomInset = useGetBottomInset()

  const handleBack = () => {
    history.goBack()
  }
  const handleAgreement = () => {
    history.replace(BONUS_SIGN_PATH + '?agreement=true')
  }

  return (
    <>
      <Container>
        <BackButton onClick={handleBack}>
          <img
            src={'/icons/only_x_black.png'}
            alt={'back'}
            width={24}
            height={24}
          />
        </BackButton>
        <IframeWrapper>
          <Iframe
            src='https://metalogos.oopy.io/mli/160/eventinfo'
            title='WebView'
          />
        </IframeWrapper>
        <Footer
          isSignCheck={handleAgreement}
          paddingBottom={!isFromApp ? bottomInset : 24}
          isFromApp={isFromApp}
        />
      </Container>
    </>
  )
}

const IframeWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  justify-content: start;
  padding-top: 56px;
`

const Iframe = styled.iframe`
  width: 100vw;
  height: calc(100vh - 56px);
  border: none;
`

const Container = styled.div`
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`

export default MarketingAgreementPage
