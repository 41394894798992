import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import useGetAlimTalkData from '@pages/Event/simulate/v2/hooks/useGetAlimTalkData'
import { RangeOfAlimTalk } from '@constants/rangeOfAlimTalk'
import { Colors } from '@styles/colors'

const AlimTalkStatus = {
  OFFERED_DONE: 'OFFERED_DONE',
  ASSIGNMENT_DONE: 'ASSIGNMENT_DONE',
  SOLD_DONE: 'SOLD_DONE',
}

type Props = {
  status: keyof typeof AlimTalkStatus
}

const PopupGradientBg = styled.div<{ gradient: string }>`
  display: flex;
  width: 264px;
  border-radius: 16px;
  height: 354px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(${({ gradient }) => gradient});
  box-shadow: 0 0 32px 0 rgba(255, 255, 255, 0.25);
`
const PopupWrapper = styled.article<{ border?: string; shadow?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 16px;
  width: 260px;
  height: 350px;
  background-color: ${Colors.bg_dark};
  padding: 72px 32px 40px;
`
const CompanyLogoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  align-items: center;
  & > span {
    color: white;
    font-size: 22px;
    font-family: Pretendard700, sans-serif;
    line-height: 26px;
  }
`
const CompanyLogo = styled.div`
  position: relative;
`
const LogoImg = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 100%;
`
const SecurityLogoBox = styled.div`
  position: absolute;
  background-color: ${Colors.bg_dark};
  padding: 3px;
  border-radius: 100%;
  right: -10px;
  top: -10px;

  overflow: hidden;
  & > img {
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }
`
const DescriptionBox = styled.div`
  background-color: #28282e;
  padding: 12px 16px;
  margin: 24px 0;
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`
const AssignBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  gap: 4px;
  & > p {
    color: #eaeef3;
    font-size: 14px;
    line-height: 17px;
    font-family: Pretendard400, sans-serif;
  }
  & > span {
    font-family: Pretendard700, sans-serif;
    font-size: 14px;
    line-height: 17px;
  }
`

const SoldDoneText = styled.span`
  text-align: center;
  flex-direction: column;
  display: flex;
  color: white;
  font-family: Pretendard700, sans-serif;
  font-size: 14px;
  line-height: 17px;
`
const SoldDoneNormalText = styled(SoldDoneText)`
  font-family: Pretendard400, sans-serif;
  white-space: pre-wrap;
`
const StatusTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: Pretendard700, sans-serif;
  line-height: 29px;
  font-size: 24px;
`

const PopupItems = {
  [AlimTalkStatus.OFFERED_DONE]: {
    border: '2px solid #DEF5FF',
    gradient: '#DEF5FF, #44A1FF',
    titleColor: '#38BDF8',
  },
  [AlimTalkStatus.ASSIGNMENT_DONE]: {
    border: '2px solid #DEF5FF',
    gradient: '#DEF5FF, #97DDC2',
    titleColor: '#86EFAC',
  },
  [AlimTalkStatus.SOLD_DONE]: {
    border: '2px solid rgba(255, 230, 254, 0.96)',
    gradient: '#ffe6fe, #df4a83',
    titleColor: '#F87171',
    titleMinusColor: '#38BDF8',
    titleZeroColor: '#EAEEF3',
  },
}

const AlimTalkPopup = ({ status }: Props) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const { data, isLoading } = useGetAlimTalkData()
  const history = useHistory()
  const pathname = history.location.pathname

  function getRangeTextAndImage(value: number) {
    const range = RangeOfAlimTalk.find((r) => value >= r.min && value <= r.max)
    if (range && !isLoading) {
      return {
        text: range.text,
        imageUrl: range.imageUrl,
        exceptionText: range?.exceptionText || '',
      }
    } else {
      return { text: '값이 범위를 벗어났습니다', imageUrl: '' }
    }
  }
  function getTopImageSrc(price?: number) {
    switch (status) {
      case 'SOLD_DONE': {
        return getRangeTextAndImage(price || 0).imageUrl
      }
      case 'ASSIGNMENT_DONE': {
        return '/images/alim/alim-assignment.png'
      }
      case 'OFFERED_DONE': {
        return '/images/alim/alim-offered.png'
      }
      default: {
        return ''
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 500)
    const closeTimer = setTimeout(() => {
      setIsVisible(false)
    }, 4500)
    const navigateTimer = setTimeout(() => {
      history.replace(`${pathname}`)
    }, 5500)
    return () => {
      clearTimeout(timer)
      clearTimeout(closeTimer)
      clearTimeout(navigateTimer)
    }
  }, [])

  return (
    <div
      style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.8s ease-in-out',
      }}
    >
      {
        <PopupGradientBg gradient={PopupItems[status].gradient}>
          <PopupWrapper border={PopupItems[status].border}>
            {data && <TopImage src={getTopImageSrc(data.amountProfit)} />}
            <CompanyLogoBox>
              <CompanyLogo>
                <LogoImg
                  src={data?.stock.imgUrl}
                  alt={data?.stock.name}
                  width={56}
                  height={56}
                />
                <SecurityLogoBox>
                  <img
                    src={data?.securities.imgUrl}
                    alt={data?.securities.name}
                  />
                </SecurityLogoBox>
              </CompanyLogo>

              <span>{data?.stock.name}</span>
            </CompanyLogoBox>
            <DescriptionBox>
              {status === 'OFFERED_DONE' && data && (
                <OfferedBox>
                  <OfferedRow>
                    <p>공모가</p>
                    <span>{data.offeringPrice.toLocaleString()}원</span>
                  </OfferedRow>
                  <OfferedRow>
                    <p>청약증거금</p>
                    <span>{data.minSubscriptionAmount.toLocaleString()}원</span>
                  </OfferedRow>
                </OfferedBox>
              )}
              {status === 'ASSIGNMENT_DONE' && data && (
                <AssignBox>
                  <p>청약경쟁률</p>
                  <span>
                    {data.assignedInfo.minAssignedAmount}주&nbsp;
                    {data.assignedInfo.minAssignmentProbability}
                    %&nbsp;・&nbsp;
                    {data.assignedInfo.maxAssignedAmount}주&nbsp;
                    {data.assignedInfo.maxAssignmentProbability}%
                  </span>
                </AssignBox>
              )}
              {status === 'SOLD_DONE' && data && (
                <SoldDoneText>
                  {getRangeTextAndImage(data.amountProfit).exceptionText ? (
                    <SoldDoneNormalText>
                      {getRangeTextAndImage(data.amountProfit).exceptionText}
                    </SoldDoneNormalText>
                  ) : (
                    <>
                      {getRangeTextAndImage(data.amountProfit).text}값
                      <SoldDoneNormalText>만큼 벌었어요!</SoldDoneNormalText>
                    </>
                  )}
                </SoldDoneText>
              )}
            </DescriptionBox>

            {status === 'OFFERED_DONE' && (
              <StatusTitle color={PopupItems[status].titleColor}>
                청약 완료
              </StatusTitle>
            )}
            {status === 'ASSIGNMENT_DONE' && (
              <StatusTitle color={PopupItems[status].titleColor}>
                {data?.assignedInfo.assignedAmount}주 배정
              </StatusTitle>
            )}
            {status === 'SOLD_DONE' && data && (
              <StatusTitle
                color={
                  data.amountProfit === 0
                    ? PopupItems[status].titleZeroColor
                    : data.amountProfit > 0
                    ? PopupItems[status].titleColor
                    : PopupItems[status].titleMinusColor
                }
              >
                {data.amountProfit > 0 && '+'}
                {(data.amountProfit || 0).toLocaleString()}원
              </StatusTitle>
            )}
          </PopupWrapper>
        </PopupGradientBg>
      }
    </div>
  )
}
const OfferedBox = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`
const OfferedRow = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > p {
    color: #eaeef3;
    font-size: 14px;
    font-family: Pretendard400, sans-serif;
    line-height: 17px;
  }
  & > span {
    color: white;
    font-size: 14px;
    font-family: Pretendard700, sans-serif;
    line-height: 17px;
  }
`
const TopImage = styled.img`
  position: absolute;
  top: -72px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 120px;
  height: 120px;
`
export default AlimTalkPopup
