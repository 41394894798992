import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { postData } from '@utils/api'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number
  message: string
}

type RequestDataType = {
  name: string | null
  mobile: string | null
  password: string | null
  season?: string
}

type ErrorType = {
  message: string
  path: string
  requestId: string
  status: number
  timestamp: string
  //info 추가
}

const usePostSimulatedData = () => {
  const postGameStartUrl = '/mock-investment/user'
  const postIsExistUrl = '/mock-investment/user/verify'

  const simulatedGameStartData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError<ErrorType>,
    RequestDataType
  >({
    mutationFn: (data) =>
      postData<ReturnType<CurrentDataType>, RequestDataType>(
        postGameStartUrl,
        data
      ),
  })

  const simulatedIsExistData = useMutation<
    ReturnType<CurrentDataType>,
    AxiosError<ErrorType>,
    RequestDataType
  >({
    mutationFn: (data) =>
      postData<ReturnType<CurrentDataType>, RequestDataType>(
        postIsExistUrl,
        data
      ),
  })

  return {
    simulatedGameStartData,
    simulatedIsExistData,
  }
}

export default usePostSimulatedData
