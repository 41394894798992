import React, { useEffect, useRef, useState } from 'react'
import { SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import useSearchParams from '@hooks/useSearchParams'
import { useHistory } from 'react-router-dom'

const TooltipComponentWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  padding: 0 16px;
  position: relative;
`
const MobileTooltip = styled.div`
  display: flex;
  position: fixed;
  top: 16px;
  align-items: center;
  justify-content: center;
  height: 52px;
  z-index: 9999;
  width: calc(100% - 32px);
  max-width: 368px;
  background-color: rgba(0, 0, 0, 0.7);
  gap: 8px;
  border-radius: 12px;
`

const MAX_TIMER = 3
const WelcomeSnackbar = () => {
  const [count, setCount] = useState(0)
  const interval = useRef<NodeJS.Timer>()
  const history = useHistory()

  const { getParam } = useSearchParams()
  const isWelcome = getParam('welcome') === 'true'

  useEffect(() => {
    if (isWelcome) {
      interval.current = setInterval(() => {
        setCount((prev) => prev + 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [isWelcome])

  useEffect(() => {
    if (count > MAX_TIMER) {
      clearInterval(interval.current)
      // 기존 welcome queryString 제거를 위한 replace
      history.replace(history.location.pathname)
    }
  }, [count, history])
  if (!isWelcome || count > MAX_TIMER) {
    return null
  }
  return (
    <TooltipComponentWrapper>
      <MobileTooltip>
        <img
          src={'/icons/check_white.png'}
          alt={'check'}
          width={16}
          height={11}
        />

        <SpoqaLight size={14} color={Colors.gf} lineHeight={18}>
          이벤트 신청 완료!
        </SpoqaLight>
      </MobileTooltip>
    </TooltipComponentWrapper>
  )
  //
  // ) : (
  //   <WebTooltipWrapper>
  //     <WebTooltip>
  //       <img
  //         src={'/icons/check_black.png'}
  //         width={32}
  //         height={32}
  //         alt={'success_event'}
  //       />
  //       <SpoqaBold size={22} lineHeight={26} color={'#19191B'}>
  //         이벤트 신청이 완료됐어요!
  //       </SpoqaBold>
  //     </WebTooltip>
  //   </WebTooltipWrapper>
  // )
}

export default WelcomeSnackbar
