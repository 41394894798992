import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@components/NewMain/Header/Menu'
import DownloadApp from '@components/NewMain/DownloadApp'
import styled from 'styled-components'
import { WEB_HEADER_HEIGHT } from '@constants/elements'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'

const WebHeaderStyle = styled.header<{ hasHeaderUnderLine: boolean }>`
  width: 100%;
  height: ${WEB_HEADER_HEIGHT}px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${Colors.gf};
  border-bottom: ${({ hasHeaderUnderLine }) => (hasHeaderUnderLine ? '1px' : 0)}
    solid #c9d3de;
  z-index: 3;
  min-width: 360px;
`
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`
const MenuContainer = styled.div`
  display: flex;
  padding: 8px 8px;
  box-sizing: border-box;
  gap: 24px;
  align-items: center;
`
const DownloadButton = styled.div`
  background-color: #0e6dfb;
  border-radius: 8px;
  color: ${Colors.gf};
  padding: 12px 16px;
  font-size: 20px;
  line-height: normal;
  font-family: Pretendard500, sans-serif;
  cursor: pointer;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 12px;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  gap: 4px;
  > span {
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26px;
    color: #0e6dfb;
  }
  &:hover {
    color: #0e6dfb;
  }
`
type Props = {
  hasHeaderUnderLine?: boolean
}
const WebHeader = ({ hasHeaderUnderLine = true }: Props) => {
  const [isClicked, setIsClicked] = useState(false)
  const handleToggleDownloadApp = () => setIsClicked((prev) => !prev)
  return (
    <WebHeaderStyle hasHeaderUnderLine={hasHeaderUnderLine}>
      <Link to={'/'} id={'menu_160_btn_click'}>
        <img src={'/img/160_logo.svg'} alt='160_logo' />
      </Link>
      <RightContainer>
        <MenuContainer>
          <a href='/event'>
            <MenuItem>
              <img
                src={'/icons/event_fireworks.png'}
                alt={'event'}
                width={24}
                height={24}
              />
              <span>이벤트</span>
            </MenuItem>
          </a>
          <Menu name='엠엘투자자문↗' url='https://www.ml-invest.co.kr/' />
          <DownloadButton
            onClick={handleToggleDownloadApp}
            id={'memu_download_btn_click'}
          >
            앱 다운로드
          </DownloadButton>
          {isClicked && (
            <DownloadApp isClicked={isClicked} setIsClicked={setIsClicked} />
          )}
        </MenuContainer>
        {isClicked && (
          <DownloadApp isClicked={isClicked} setIsClicked={setIsClicked} />
        )}
      </RightContainer>
    </WebHeaderStyle>
  )
}
export default WebHeader
