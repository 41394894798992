import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import NavigateStore from '@utils/navigateStore'
import { Link } from 'react-router-dom'
import DownloadApp from '@components/NewMain/DownloadApp'
import MobileMenu from '@components/NewMain/Mobile/Menu'
const Background = styled.div`
  position: fixed;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`
const MobileHeaderStyle = styled.header<{ hasHeaderUnderLine: boolean }>`
  display: flex;
  width: 100%;
  position: fixed;
  padding: 0 24px;
  height: 78px;
  top: 0;
  justify-content: space-between;
  border-bottom: ${({ hasHeaderUnderLine }) => (hasHeaderUnderLine ? '1px' : 0)}
    solid #c9d3de;
  background-color: ${Colors.gf};
  align-items: center;
  z-index: 4;
`
const DownloadButton = styled.div`
  background-color: #0e6dfb;
  border-radius: 8px;
  color: ${Colors.gf};
  font-family: Pretendard500, sans-serif;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 12px;
`

const MobileRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

type Props = {
  hasHeaderUnderLine?: boolean
}
const CommonMobileHeader = ({ hasHeaderUnderLine = true }: Props) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  //햄버거 메뉴 클릭 state
  const [isOpen, setIsOpen] = useState(false)
  //앱 다운로드 클릭 state
  const [isClicked, setIsClicked] = useState(false)

  const handleOpenMenu = () => {
    setIsOpen((prev) => !prev)
  }
  const handleToggleMenu = () => setIsOpen((prev) => !prev)

  const handleToggleDownloadApp = () => {
    if (isMobile) {
      NavigateStore()
    } else {
      handleOpenMenu()
    }
  }

  return (
    <>
      {isOpen && <Background onClick={handleOpenMenu} />}

      <MobileHeaderStyle hasHeaderUnderLine={hasHeaderUnderLine}>
        <Link to={'/'} id={'menu_160_btn_click'}>
          <img src={'/img/160_logo.svg'} alt='160_logo' />
        </Link>
        <MobileRightContainer>
          <DownloadButton
            onClick={handleToggleDownloadApp}
            id={'memu_download_btn_click'}
          >
            앱 다운로드
          </DownloadButton>

          {isClicked && (
            <DownloadApp isClicked={isClicked} setIsClicked={setIsClicked} />
          )}
          <img
            src={isOpen ? '/icons/only_x_black.png' : '/img/hamburger.svg'}
            alt='menu'
            width={32}
            height={32}
            onClick={handleToggleMenu}
          />
          {isOpen && <MobileMenu isOpen={isOpen} onClose={handleToggleMenu} />}
        </MobileRightContainer>
      </MobileHeaderStyle>
    </>
  )
}

export default CommonMobileHeader
