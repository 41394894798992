import { getData } from '@pages/Event/simulate/v2/axios/axios'
import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { useQuery } from '@tanstack/react-query'

export const InvestmentStatus = {
  /** 증거금 부족 */
  NOT_ENOUGH: 'NOT_ENOUGH',
  /** 청약 예정 */
  OFFER_PENDING: 'OFFER_PENDING',
  /** 청약 완료 */
  OFFERED_DONE: 'OFFERED_DONE',
  /** 청약 실패 */
  OFFERED_FAILED: 'OFFERED_FAILED',
  /** 배정 실패 */
  ASSIGNMENT_FAILED: 'ASSIGNMENT_FAILED',
  /** 배정 완료 */
  ASSIGNMENT_DONE: 'ASSIGNMENT_DONE',
  /** 매도 완료 */
  SOLD_DONE: 'SOLD_DONE',
  /** 매도 실패 */
  SOLD_FAILED: 'SOLD_FAILED',
} as const

export type OnGoingStockType = {
  code: string
  name: string
  securities: {
    code: string
    name: string
    imgUrl: string
  }
  status: keyof typeof InvestmentStatus
  assignedAmount?: number
}
export type RecentStockType = {
  code: string
  name: string
  securities: {
    code: string
    name: string
    imgUrl: string
  }
  offeringPrice: number // 공모가
  amountReceived: number // '정산 금액( 매도금액 * 매도주수 ) - ( 매도 수수료 + 세금)'
  amountProfit: number
}

type ResponseDashboardType = {
  achievementRate: number
  totalProfitAmount: number
}
type ResponseOnGoingType = {
  stocks: OnGoingStockType[]
}
type ResponseRecentStockType = {
  stocks: RecentStockType[]
}
type ResponseLatestMessageType = {
  messages: string[]
}
const getLatestMessageFunction = async () => {
  const res = await getData<ResponseLatestMessageType>(V2ApiUrls.LATEST_MESSAGE)
  return res.data.data
}

const getDashboardFunction = async () => {
  const res = await getData<ResponseDashboardType>(V2ApiUrls.DASHBOARD)
  return res.data.data
}
const getOnGoingStockListFunction = async () => {
  const res = await getData<ResponseOnGoingType>(V2ApiUrls.ONGOING)
  return res.data.data
}
const getRecentStockListFunction = async () => {
  const res = await getData<ResponseRecentStockType>(V2ApiUrls.RECENT_STOCK)
  return res.data.data
}

const useGetDashBoard = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: getDashboardFunction,
  })
}
const useGetOnGoingStock = () => {
  return useQuery({
    queryKey: ['onGoing-stock'],
    queryFn: getOnGoingStockListFunction,
  })
}
const useGetRecentStock = () => {
  return useQuery({
    queryKey: ['recent-stock'],
    queryFn: getRecentStockListFunction,
  })
}
const useGetLatestMessage = () => {
  return useQuery({
    queryKey: ['latest-message'],
    queryFn: getLatestMessageFunction,
  })
}

export {
  useGetDashBoard,
  useGetOnGoingStock,
  useGetRecentStock,
  useGetLatestMessage,
}
