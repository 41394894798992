import React, { ChangeEvent } from 'react'
import V2Layout from '@layouts/V2Layout'
import V2JoinForm from '@pages/Event/simulate/v2/component/V2JoinForm'
import styled from 'styled-components'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import CommonButton from '@pages/Event/simulate/v2/component/CommonButton'
import HeaderClose from '@pages/Event/simulate/v2/component/HeaderClose'
import { useHistory } from 'react-router-dom'
import { usePostSignIn } from '@pages/Event/simulate/v2/hooks/useAuth'
import { AxiosError } from 'axios'
import { normalizeSpaces } from '@utils/index'
import { useAppSelector } from '@store/selectors/utils'
import usePostMessage from '@hooks/usePostMessage'

type InputStatus = 'name' | 'mobile'
type InputProps = Record<InputStatus, string>

const Wrapper = styled.section`
  padding: 32px 24px 0;
  width: 100%;
`
const ErrorMessageText = styled.p`
  color: #f57077;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  margin: 24px 0 0;
`

const V2SignCheckPage = () => {
  const [inputData, setInputData] = React.useState<InputProps>({
    name: '',
    mobile: '',
  })
  const history = useHistory()
  const { mutate } = usePostSignIn()
  const [errorMsg, setErrorMsg] = React.useState('')
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()

  const isValidName = inputData.name.length > 1
  const isValidMobile = inputData.mobile.length > 10
  const isValidButton = isValidName && isValidMobile
  const onChangeInput = (
    type: InputStatus,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (errorMsg) {
      setErrorMsg('')
    }
    const value = event.target.value
    let formattedValue
    if (type === 'name') {
      formattedValue = value
    } else {
      formattedValue = value.replace(/[^0-9]/g, '')
    }
    setInputData({
      ...inputData,
      [type]: formattedValue,
    })
  }

  const clearFields = (
    type: InputStatus,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setInputData({
      ...inputData,
      [type]: '',
    })
  }

  const onClickHandler = () => {
    mutate(
      {
        name: normalizeSpaces(inputData.name),
        mobile: normalizeSpaces(inputData.mobile),
        season: '2',
      },
      {
        onSuccess: () => {
          if (isFromApp) {
            postMessageHandler({
              action: 'isJoin',
            })
          }
          history.push('/event/simulate/v2/benefit')
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            if (error.response) {
              if (error.response.status === 404) {
                // 공모주 게임 참여 이력이 없는 경우
                history.push(
                  `/event/simulate/v2/sign-up?notFound=true&name=${inputData.name}&mobile=${inputData.mobile}`
                )
              } else if (error.response.status === 400) {
                setErrorMsg('이전 공모주 게임 참가자는 참가할 수 없습니다.')
              }
            }
          }
        },
      }
    )
  }

  return (
    <V2Layout RightComponent={<HeaderClose onClick={() => history.goBack()} />}>
      <Wrapper>
        <Title>참가 신청 확인</Title>
        <V2JoinForm
          isError={!!errorMsg}
          onChangeInput={onChangeInput}
          clearFields={clearFields}
          inputData={inputData}
        />
        {errorMsg && <ErrorMessageText>{errorMsg}</ErrorMessageText>}
      </Wrapper>
      <BottomButtonFixer hasBorder={false}>
        <CommonButton
          onClick={onClickHandler}
          buttonText={'나의 게임 현황 보기'}
          disabled={!isValidButton}
        />
      </BottomButtonFixer>
    </V2Layout>
  )
}
const Title = styled.h2`
  letter-spacing: -0.72px;
  font-size: 24px;
  line-height: 29px;
  color: white;
  margin-bottom: 24px;
  font-family: Pretendard700, sans-serif;
`
export default V2SignCheckPage
