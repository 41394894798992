import React, { useEffect } from 'react'
import V2Layout from '@layouts/V2Layout'
import styled from 'styled-components'
import { useScrollBlock } from '@hooks/useScrollBlock'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import Lottie from 'lottie-react'
import animationData from '../../../../../lotties/loading-gray.json'

const Wrapper = styled.section`
  padding: 56px 0;
`
const Banner = styled.div`
  padding: 24px;
  margin-bottom: 16px;
  & > img {
    width: 100%;
    object-fit: contain;
    object-position: top;
  }
`
const PersonImage = styled.img`
  width: 100%;
`
const PopupBackground = styled.div<{ isOpen: boolean }>`
  position: absolute;
  max-width: 312px;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    180deg,
    rgba(210, 165, 255, 0.5) 0%,
    rgba(130, 130, 148, 0.5) 100%
  );
  z-index: 1002;
`
const Popup = styled(motion.div)`
  border-radius: 16px;
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  backdrop-filter: blur(15px);
  border: 2px solid rgba(210, 165, 255, 0.5);

  & > p {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-family: Pretendard700, sans-serif;
    color: white;
  }
`
const ImageBox = styled.div`
  position: relative;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 56px;
    font-family: LabDigital, sans-serif;
    font-weight: 400;
    text-shadow: 0 0 14px #39a38c;
    color: white;
    line-height: 78px;
  }
`
const PopupDimmed = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.7);
`

const V2SignUpBridgePage = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [loading, setLoading] = React.useState<boolean>(true)
  const history = useHistory()

  const getRandomNumber = (): number => Math.floor(Math.random() * 999) + 1

  const openPopupHandler = () => {
    setIsOpen(true)
    setTimeout(() => {
      history.replace('/event/simulate/v2/select-securities')
    }, 2000)
  }
  useEffect(() => {
    if (!loading) {
      openPopupHandler()
    }
  }, [loading])
  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [isOpen])
  return (
    <>
      {loading || isOpen ? <PopupDimmed /> : null}
      {isOpen && (
        <>
          <PopupBackground isOpen={isOpen}>
            <Popup
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3, ease: 'easeOut' }}
            >
              <ImageBox>
                <img
                  src={'/images/simulate-v2/single-person.png'}
                  alt={'apply'}
                  width={160}
                  height={160}
                />
                <span>{getRandomNumber()}</span>
              </ImageBox>
              <p>
                공모주 게임에
                <br />
                참여하셨습니다
              </p>
            </Popup>
          </PopupBackground>
        </>
      )}
      <V2Layout>
        <Wrapper>
          {loading && (
            <Spinner>
              <Lottie animationData={animationData} />
            </Spinner>
          )}
          <Banner>
            <img
              src={'/images/simulate-v2/bridge-logo.png'}
              height={62}
              alt={'simulate-game'}
            />
          </Banner>
          <PersonImage
            src={'/images/simulate-v2/many-person.png'}
            onLoad={() => setLoading(false)}
          />
        </Wrapper>
      </V2Layout>
    </>
  )
}
const Spinner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default V2SignUpBridgePage
