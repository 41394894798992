import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useScrollBlock } from '@hooks/useScrollBlock'

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onExit?: () => void
}
const Wrapper = styled.button<{ isOpen: boolean }>`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`
const Animated = styled(motion.div)``
const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background: transparent;
`
const OverlayBackground = ({
  children,
  isOpen,
  onExit = () => null,
}: Props) => {
  const [blockScroll, allowScroll] = useScrollBlock()

  const onClickOutSide = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onExit()
  }
  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [isOpen])

  return (
    <>
      <Wrapper isOpen={isOpen} onClick={(e) => onClickOutSide(e)} />
      <ModalStyle>
        <Animated
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          {children}
        </Animated>
      </ModalStyle>
    </>
  )
}

export default OverlayBackground
