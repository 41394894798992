import React from 'react'
import styled from 'styled-components'
import V2Layout from '@layouts/V2Layout'
import { Colors } from '@styles/colors'
import V2Notice from '@pages/Event/simulate/v2/component/V2Notice'
import BottomButtonFixer from '@components/v2/BottomButtonFixer'
import { Link } from 'react-router-dom'
import usePostMessage from '@hooks/usePostMessage'
import HeaderBack from '@pages/Event/simulate/v2/component/HeaderBack'
import { Desktop, S2MaxWidth } from '@constants/mediaQuery'
import { useAppSelector } from '@store/selectors/utils'
import { useMediaQuery } from 'react-responsive'
import EventSubTitleForWeb from '@pages/Event/components/EventSubTitleForWeb'

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
const ImageStyle = styled.img<{ minHeight?: number }>`
  max-width: ${S2MaxWidth}px;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight}px;
  object-position: top;
  object-fit: contain;
`
const Divider = styled.div`
  height: 8px;
  background-color: #1e1e20;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const Button = styled(Link)`
  background-color: #237bff;
  color: #ffffff;
  font-size: 16px;
  font-family: Pretendard400, sans-serif;
  line-height: 19px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 12px;
`
const MyGameButton = styled(Button)`
  background-color: ${Colors.bg_dark};
  color: #237bff;
  border: 1px solid #237bff;
`
const SubTitleWrapper = styled.div`
  margin-top: 78px;
  z-index: 3;
`

const V2MainPage = () => {
  const { postMessageHandler } = usePostMessage()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  const handleBackButton = () => {
    postMessageHandler({
      action: 'close',
    })
  }
  return (
    <>
      {!isMobile && (
        <SubTitleWrapper>
          <EventSubTitleForWeb
            title={'새로운 시즌 OPEN! 공모주게임 시즌2 참여하기'}
            description={'2025.03.10 ~ 2025.04.30'}
          />
        </SubTitleWrapper>
      )}

      <V2Layout
        paddingTop={!isMobile ? 1 : 78}
        LeftComponent={
          isFromApp ? <HeaderBack onClick={handleBackButton} /> : null
        }
      >
        <Background>
          <ImageStyle
            src={'/images/simulate-v2/s2-main-image-1.png'}
            minHeight={557}
          />

          <ImageStyle
            src={'/images/simulate-v2/s2-main-image-2.png'}
            minHeight={604}
          />
          <Divider />
          <ImageStyle src={'/images/simulate-v2/s2-main-image-3.png'} />
          <Divider />
          <ImageStyle src={'/images/simulate-v2/s2-main-image-4.png'} />
          <Divider />
          <ImageStyle src={'/images/simulate-v2/s2-main-image-5.png'} />
          <Divider />
          <V2Notice />
          <BottomButtonFixer>
            <ButtonWrapper>
              <MyGameButton
                id={'event_160_03_detail_cash_btn_click'}
                to={'/event/simulate/v2/sign-check'}
              >
                나의 게임 현황
              </MyGameButton>
              <Button
                id={'event_160_03_detail_start_btn_click'}
                to={'/event/simulate/v2/sign-up'}
              >
                게임 참가
              </Button>
            </ButtonWrapper>
          </BottomButtonFixer>
        </Background>
      </V2Layout>
    </>
  )
}

export default V2MainPage
