import React from 'react'
import styled, { keyframes } from 'styled-components'
const skeletonAnimation = keyframes`
  0% {
      background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: #28282E;
  }
`
const SkeletonStyle = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 8px;
  animation: ${skeletonAnimation} 2s infinite ease-in-out;
`

type Props = {
  width: string
  height: string
}
const Skeleton = ({ width, height }: Props) => {
  return <SkeletonStyle width={width} height={height} />
}

export default Skeleton
