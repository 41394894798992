import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface V2UserState {
  name: string
  mobile: string
  agree: boolean
}

const initialState: V2UserState = {
  name: '',
  mobile: '',
  agree: false,
}

const V2UserSlice = createSlice({
  name: 'v2UserInfo',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Partial<V2UserState>>) {
      Object.assign(state, action.payload)
    },
    clearUser(state) {
      state.name = ''
      state.mobile = ''
      state.agree = false
    },
  },
})
export const { setUser, clearUser } = V2UserSlice.actions
export default V2UserSlice.reducer
