import Spacer from '@components/base/Spacer'
import { SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import axios from 'axios'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const AppLinkBridge = () => {
  const { ci } = useParams<any>()

  useEffect(() => {
    if (ci) {
      const pa = ci?.split('&hncdInflowDate=')
      const hncdCi = pa[0].split('=')[1]
      const hncdInflowDate = pa[1]
      postCi(hncdCi, hncdInflowDate)
    }
  })

  const postCi = async (hncdCi: any, hncdInflowDate: any) => {
    console.log(hncdCi, hncdInflowDate)
    try {
      const res = await axios.post(
        `https://api.metalogos.site/hana/hanacard/user/inflow`,
        {
          hncdCi,
          hncdInflowDate,
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      if (res) {
        window.location.href = 'i6o://entrust'
      }
    } catch (error: any) {
      window.location.href = 'i6o://entrust'
    }
  }

  const handleClickInstall = () => {
    console.log(window.navigator.userAgent)
    var isMobile = /Mobi/i.test(window.navigator.userAgent)

    if (isMobile) {
      if (/iPhone/i.test(window.navigator.userAgent)) {
        window.open(
          'https://apps.apple.com/ar/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
        )
      } else {
        window.open(
          'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
        )
      }
    } else {
      window.open('https://160ipo.com/')
    }
  }

  return (
    <Container>
      <Image
        src='/images/ios_install_guide_01.png'
        alt='ios_install_guide_01'
      />
      <Spacer height={20} />
      <TextWrapper>
        <SpoqaLight lineHeight={18} size={14}>
          버튼 선택시, 위와 같은 팝업이 나타나면 <br />
          앱이 설치되지 않은 경우 입니다.
          <br /> 아래의 버튼을 선택하면 앱스토어로 <br />
          이동합니다.
        </SpoqaLight>
      </TextWrapper>

      <ButtonWrapper>
        <Button onClick={handleClickInstall}>
          <SpoqaLight lineHeight={18} size={14}>
            설치하기
          </SpoqaLight>
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default AppLinkBridge

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  width: 100%;
  height: 100vh;
`
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  text-align: center;
`
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 55px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Button = styled.div`
  width: 80%;
  padding: 0 24px;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primary_ios};
`

const Image = styled.img``
