import styled from 'styled-components'
import Modal from 'react-modal'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { SEASON1_BENEFIT_PATH } from '@router/paths/simulate/pathMap'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isFromApp: boolean
}

const successModalStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 56px 100px;
    justify-content: center;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
      padding: 40px 58px;
    }
  `,
  Title: styled.div`
    font-family: Pretendard700, sans-serif;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 21.6px;
    }
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    color: #555564;
    margin-bottom: 32px;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 16.8px;
    }
  `,
  BlueButton: styled.span<{ isFromApp: boolean }>`
    border: none;
    border-radius: 16px;
    background-color: #0e6dfb;
    color: #fff;
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26.4px;
    text-align: center;
    padding: 24px 0px;
    width: 100%;
    cursor: pointer;
    @media (max-width: 800px) {
      font-family: Pretendard500, sans-serif;
      font-size: 16px;
      line-height: 19.2px;
      padding: 16.5px 0;
      width: 100%;
      border-radius: ${(props) => (props.isFromApp ? '4px' : '18px')};
    }
  `,
  NextButton: styled.span`
    text-decoration: underline;
    font-family: Pretendard400, sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #555564;
    margin-top: 12px;
    cursor: pointer;
    @media (max-width: 800px) {
      font-family: Pretendard700, sans-serif;
      font-size: 12px;
      line-height: 14.4px;
    }
  `,
}

const { ModalContainer, Title, Description, BlueButton, NextButton } = Styled

const SuccessModal: React.FC<Props> = ({ isOpen, setIsOpen, isFromApp }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const history = useHistory()

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          ...successModalStyle,
          content: {
            ...successModalStyle.content,
            width: '80%',
            maxWidth: '500px',
            borderRadius: !isMobile ? '24px' : isFromApp ? '4px' : '16px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer id={'event_160_01_sign_alreadydonechangepw_view'}>
          <Title>비밀번호 변경완료!</Title>
          <Description>
            변경하신 이벤트 신청 정보로
            <br />
            누적 상금을 보러 갈까요?
          </Description>
          <BlueButton
            isFromApp={isFromApp}
            onClick={() => {
              setIsOpen(false)
              history.push(SEASON1_BENEFIT_PATH)
            }}
          >
            누적상금 보러가기
          </BlueButton>
          <NextButton onClick={() => setIsOpen(false)}>
            다음에 볼게요
          </NextButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default SuccessModal
