// 모의 투자 게임 => "확인해 주세요" 부분
export const INVESTMENT_NOTICE_LIST = [
  {
    id: 1,
    content:
      '투자자는 당사와 투자일임계약을 체결하기 전에 투자일임계약 권유 문서 및 투자일임계약서를 반드시 읽어 보시기 바랍니다.',
  },
  {
    id: 2,
    content: '공모주 및 투자일임계약은 예금자보호법에 따라 보호되지 않습니다.',
  },
  {
    id: 3,
    content:
      '공모주는 가격 변동에 따라 투자 원금의 일부 또는 전부 손실이 발생할 수 있으며, 투자 손익은 전부 투자자 본인에게 귀속됩니다.',
  },
  {
    id: 4,
    content:
      '투자일임 수수료는 투자일임계약서 상 고객과의 합의에 의하며, 이외에도 거래 수수료 및 청약수수료가 발생합니다.',
  },
  {
    id: 5,
    content:
      '공모주에 대한 청약의 권유는 주관 증권회사에서 제공하는 투자설명서에 따릅니다.',
  },
  {
    id: 6,
    content:
      '공모주 일반투자자에게는 균등 배정 방식과 비례 배정 방식이 적용되어 각 배정 방식에 따라 공모주 배정 결과가 다를 수 있습니다.',
  },
  {
    id: 7,
    content:
      '공모주는 통상 상장 초기 가격 변동성이 크며, 상장 후 시가가 공모가를 하회할 경우 투자 손실이 크게 발생할 수도 있습니다.',
  },
]

const BONUS_COMPLETE_NOTICE_LIST = [
  {
    id: 1,
    content:
      '이벤트 참여 기간(25.2.3-3.9)내 참여 신청을 하신 분들에 한해 혜택을 지급합니다.',
  },
  {
    id: 2,
    content:
      '이벤트 기간 내 탈퇴 후 재가입 이력이 있는 경우 이벤트 대상에서 제외됩니다.',
  },
  {
    id: 3,
    content:
      '신청 일자와 상관없이 이벤트 기간 내 일육공이 청약 대행한 모든 공모주 투자 성과는 확인 가능합니다.',
  },
  {
    id: 4,
    content:
      '일육공은 자체 알고리즘을 통해 종목별 매력지수를 산정하여 50점 미만이 나올 경우, 고객의 리스크 관리를 위해 청약을 진행하지 않습니다.',
  },
  {
    id: 5,
    content:
      '공모주게임의 매도금액은 일육공의 실제 매도금액과 차이가 발생할 수 있습니다.',
  },
  { id: 7, content: '중복 참여는 불가하며, 참여 취소 및 변경이 불가합니다.' },
  {
    id: 8,
    content:
      '3만원 상금 및 50만원 추첨 기회를 받기 위해서는 4월 8일까지 일육공을 통해 증권사 계좌 연결 및 실제 청약 서비스를 1회 이용하셔야 합니다.',
  },
  {
    id: 9,
    content:
      '혜택1은 경품 수령 조건을 충족시킨 분에 한하여 4월 중 일괄 지급합니다.',
  },
  {
    id: 10,
    content:
      '혜택2 당첨자는 4월 중 앱 내 공지 또는 개별적으로 안내드릴 예정입니다.',
  },
  { id: 11, content: '마케팅 활용 동의 회원 대상으로 추첨이 진행됩니다.' },
  {
    id: 12,
    content: '5만 원 초과 경품/상금은 제세 공과금(22%) 공제 후 지급됩니다.',
  },
  {
    id: 13,
    content:
      '경품/상금 증정일 기준, 고객 정보 오류(연락처 결번, 착신 정지, 수신 정지 등)일 경우에는 경품/상금 지급이 불가할 수 있습니다.',
  },
  {
    id: 14,
    content:
      '일부 경품/상금의 경우, 당첨 고객 대상 개별 연락드릴 예정이며 5회 이상 부재 시 당첨이 취소될 수 있습니다.',
  },
  {
    id: 15,
    content:
      '회원 정보가 잘못 기재되어 경품/상금을 받지 못하시는 경우 경품/상금은 지급되지 않습니다.',
  },
  {
    id: 16,
    content:
      '고객의 일육공 앱 서비스 가입 정보(성명, 연락처)와 이벤트 신청 정보가 동일해야 경품/상금이 지급됩니다.',
  },
  {
    id: 17,
    content:
      '다른 이름과 연락처로 경품/상금을 요구하는 경우 당첨이 임의 취소될 수 있습니다.',
  },
  {
    id: 18,
    content:
      '이벤트 경품/상금의 내용과 기간은 당사 사정과 신청인 사정에 따라 변동될 수 있습니다.',
  },
  {
    id: 19,
    content:
      '경품/상금 수령 이후 모든 사후 처리의 책임은 당첨자 본인에게 있습니다.',
  },
  {
    id: 20,
    content:
      '「소득세법]제21조(기타소득), 제127조(원천징수 의무), 제164조(지급명세서의 제출)에 따라 제세공과금 납부 및 지급명세서 제출을 위해 당첨자의 주민등록번호를 수집•이용합니다.',
  },
  {
    id: 21,
    content:
      '이벤트를 통해 수집•이용된 개인정보는 해당 이벤트 당첨자 선정 및 경품/상금 발송 용도로만 활용되며, 이벤트 종료(경품/상금 지급 완료) 후 즉시 파기합니다.',
  },
]

const BONUS_NOTICE_LIST = [
  {
    id: 1,
    content:
      '이벤트 참여 기간(25.2.3-3.9)내 참여 신청을 하신 분들에 한해 혜택을 지급합니다.',
  },
  {
    id: 2,
    content:
      '이벤트 기간 내 탈퇴 후 재가입 이력이 있는 경우 이벤트 대상에서 제외됩니다.',
  },
  {
    id: 3,
    content:
      '신청 일자와 상관없이 이벤트 기간 내 일육공이 청약 대행한 모든 공모주 투자 성과는 확인 가능합니다.',
  },
  {
    id: 4,
    content:
      '일육공은 자체 알고리즘을 통해 종목별 매력지수를 산정하여 50점 미만이 나올 경우, 고객의 리스크 관리를 위해 청약을 진행하지 않습니다.',
  },
  {
    id: 5,
    content:
      '공모주게임의 매도금액은 일육공의 실제 매도금액과 차이가 발생할 수 있습니다.',
  },
  {
    id: 6,
    content:
      '2월 내 청약 가능 종목이 3종목이 안 될 경우, 이벤트 기간이 연장될 수 있습니다.',
  },
  { id: 7, content: '중복 참여는 불가하며, 참여 취소 및 변경이 불가합니다.' },
  {
    id: 8,
    content:
      '수익금 및 1만원 추가금을 수령하기 위해서는 이벤트 종료 후 한달 내 일육공을 통해 증권사 계좌 개설/연결 후 실제 청약 서비스를 1회 이상 이용하셔야 합니다.',
  },
  {
    id: 9,
    content:
      '혜택1은 경품 수령 조건을 충족시킨 분에 한하여 4월 중 일괄 지급합니다.',
  },
  {
    id: 10,
    content:
      '혜택2 당첨자는 4월 중 앱 내 공지 또는 개별적으로 안내드릴 예정입니다.',
  },
  { id: 11, content: '마케팅 활용 동의 회원 대상으로 추첨이 진행됩니다.' },
  {
    id: 12,
    content: '5만 원 초과 경품/상금은 제세 공과금(22%) 공제 후 지급됩니다.',
  },
  {
    id: 13,
    content:
      '경품/상금 증정일 기준, 고객 정보 오류(연락처 결번, 착신 정지, 수신 정지 등)일 경우에는 경품/상금 지급이 불가할 수 있습니다.',
  },
  {
    id: 14,
    content:
      '일부 경품/상금의 경우, 당첨 고객 대상 개별 연락드릴 예정이며 5회 이상 부재 시 당첨이 취소될 수 있습니다.',
  },
  {
    id: 15,
    content:
      '회원 정보가 잘못 기재되어 경품/상금을 받지 못하시는 경우 경품/상금은 지급되지 않습니다.',
  },
  {
    id: 16,
    content:
      '고객의 일육공 앱 서비스 가입 정보(성명, 연락처)와 이벤트 신청 정보가 동일해야 경품/상금이 지급됩니다.',
  },
  {
    id: 17,
    content:
      '다른 이름과 연락처로 경품/상금을 요구하는 경우 당첨이 임의 취소될 수 있습니다.',
  },
  {
    id: 18,
    content:
      '이벤트 경품/상금의 내용과 기간은 당사 사정과 신청인 사정에 따라 변동될 수 있습니다.',
  },
  {
    id: 19,
    content:
      '경품/상금 수령 이후 모든 사후 처리의 책임은 당첨자 본인에게 있습니다.',
  },
  {
    id: 20,
    content:
      '「소득세법]제21조(기타소득), 제127조(원천징수 의무), 제164조(지급명세서의 제출)에 따라 제세공과금 납부 및 지급명세서 제출을 위해 당첨자의 주민등록번호를 수집•이용합니다.',
  },
  {
    id: 21,
    content:
      '이벤트를 통해 수집•이용된 개인정보는 해당 이벤트 당첨자 선정 및 경품/상금 발송 용도로만 활용되며, 이벤트 종료(경품/상금 지급 완료) 후 즉시 파기합니다.',
  },
]
const SEASON1_NOTICE_LIST = [
  {
    id: 1,
    content:
      '이벤트 참여 기간(25.1.6-2.5)내 참여 신청을 하신 분들에 한해 혜택을 지급합니다.',
  },
  {
    id: 2,
    content:
      '이벤트 기간 내 탈퇴 후 재가입 이력이 있는 경우 이벤트 대상에서 제외됩니다.',
  },
  {
    id: 3,
    content:
      '신청 일자와 상관없이 이벤트 기간 내 일육공이 청약 대행한 모든 공모주 투자 성과는 확인 가능합니다.',
  },
  {
    id: 4,
    content:
      '일육공은 자체 알고리즘을 통해 종목별 매력지수를 산정하여 50점 미만이 나올 경우, 고객의 리스크 관리를 위해 청약을 진행하지 않습니다.',
  },
  {
    id: 5,
    content:
      '공모주게임의 매도금액은 일육공의 실제 매도금액과 차이가 발생할 수 있습니다.',
  },
  {
    id: 6,
    content:
      '1월 내 청약 가능 종목이 3종목이 안 될 경우, 이벤트 기간이 연장될 수 있습니다.',
  },
  { id: 7, content: '중복 참여는 불가하며, 참여 취소 및 변경이 불가합니다.' },
  {
    id: 8,
    content:
      '3만원 상금 및 50만원 추첨 기회를 받기 위해서는 3월 21일까지 일육공을 통해 증권사 계좌 연결 및 실제 청약 서비스를 1회 이용하셔야 합니다.',
  },
  {
    id: 9,
    content:
      '경품 수령 조건 달성 기간의 연장에 따라 기본 상금 3만원은 3월 1주차까지, 조건 달성하신 고객께는 3월 둘째주에, 3월 21일까지 달성하신 분은 4월 첫째주에 입금해 드립니다.',
  },
  {
    id: 10,
    content:
      '50만원은 3월 21일 이후 추첨을 진행하며, 당첨자는 4월 첫째주에 앱 내 공지 또는 개별 안내 예정입니다.',
  },
  { id: 11, content: '마케팅 활용 동의 회원 대상으로 추첨이 진행됩니다.' },
  {
    id: 12,
    content: '5만 원 초과 경품/상금은 제세 공과금(22%) 공제 후 지급됩니다.',
  },
  {
    id: 13,
    content:
      '경품/상금 증정일 기준, 고객 정보 오류(연락처 결번, 착신 정지, 수신 정지 등)일 경우에는 경품/상금 지급이 불가할 수 있습니다.',
  },
  {
    id: 14,
    content:
      '일부 경품/상금의 경우, 당첨 고객 대상 개별 연락드릴 예정이며 5회 이상 부재 시 당첨이 취소될 수 있습니다.',
  },
  {
    id: 15,
    content:
      '회원 정보가 잘못 기재되어 경품/상금을 받지 못하시는 경우 경품/상금은 지급되지 않습니다.',
  },
  {
    id: 16,
    content:
      '고객의 일육공 앱 서비스 가입 정보(성명, 연락처)와 이벤트 신청 정보가 동일해야 경품/상금이 지급됩니다.',
  },
  {
    id: 17,
    content:
      '다른 이름과 연락처로 경품/상금을 요구하는 경우 당첨이 임의 취소될 수 있습니다.',
  },
  {
    id: 18,
    content:
      '이벤트 경품/상금의 내용과 기간은 당사 사정과 신청인 사정에 따라 변동될 수 있습니다.',
  },
  {
    id: 19,
    content:
      '경품/상금 수령 이후 모든 사후 처리의 책임은 당첨자 본인에게 있습니다.',
  },
  {
    id: 20,
    content:
      '「소득세법]제21조(기타소득), 제127조(원천징수 의무), 제164조(지급명세서의 제출)에 따라 제세공과금 납부 및 지급명세서 제출을 위해 당첨자의 주민등록번호를 수집•이용합니다.',
  },
  {
    id: 21,
    content:
      '이벤트를 통해 수집•이용된 개인정보는 해당 이벤트 당첨자 선정 및 경품/상금 발송 용도로만 활용되며, 이벤트 종료(경품/상금 지급 완료) 후 즉시 파기합니다.',
  },
]

export const NOTICE_LIST = ({
  type,
}: {
  type: 'bonus' | 'season1' | 'bonus-complete'
}) => {
  switch (type) {
    case 'season1': {
      return SEASON1_NOTICE_LIST
    }
    case 'bonus': {
      return BONUS_NOTICE_LIST
    }
    case 'bonus-complete': {
      return BONUS_COMPLETE_NOTICE_LIST
    }
    default: {
      return []
    }
  }
}
