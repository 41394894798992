import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { Desktop } from '@constants/mediaQuery'
import { Link } from 'react-router-dom'
import { useScrollBlock } from '@hooks/useScrollBlock'
import { useMediaQuery } from 'react-responsive'

const Background = styled.div`
  position: fixed;
  z-index: 2000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`
const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 640px;
  height: 656px;

  @media screen and (max-width: ${Desktop}px) {
    width: 326px;
    height: 324px;
  }
`
const PopupImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`
const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 74px;
  padding: 0 24px;
  bottom: 24px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: ${Desktop}px) {
    height: 40px;
    padding: 0 24px;
    bottom: 24px;
  }
`
const NavigateButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gf};
  width: 100%;
  height: 100%;
  border-radius: 12px;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.72px;
  font-family: Pretendard700, sans-serif;
  color: #19191b;
  @media screen and (max-width: ${Desktop}px) {
    height: 35px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
  }
`
const CloseIcon = styled.button`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  @media screen and (max-width: ${Desktop}px) {
    top: 16px;
    right: 16px;
  }
  > img {
    width: 44px;
    height: 44px;
    @media screen and (max-width: ${Desktop}px) {
      width: 24px;
      height: 24px;
    }
  }
`
const EVENT_PAGE_PATH = '/event/simulate/v2'
const LandingPopup = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [blockScroll, allowScroll] = useScrollBlock()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })

  const lowSrc = isMobile
    ? '/images/simulate-v2/v2-landing-mobile-low.png'
    : '/images/simulate-v2/v2-landing-web-low.png'
  const highSrc = isMobile
    ? '/images/simulate-v2/v2-landing-mobile.png'
    : '/images/simulate-v2/v2-landing-web.png'
  const [src, setSrc] = React.useState(lowSrc)

  useEffect(() => {
    const img = new Image()
    img.src = highSrc
    img.onload = () => setSrc(highSrc)
  }, [])
  useEffect(() => {
    if (isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [isOpen])
  /**
   * 시즌 1 popup Image
   * <PopupImage
   *  src={'/images/event/simulate_event_popup_low.png'}
   *  srcSet={'/images/event/simulate_event_popup_high.png'}
   *  loading={'eager'}
   * />
   */

  return (
    <>
      {isOpen && (
        <>
          <Background onClick={() => setIsOpen(false)} />
          <ModalStyle>
            <CloseIcon onClick={() => setIsOpen(false)}>
              <img src={'/icons/close_white.png'} alt='close' />
            </CloseIcon>
            <PopupImage src={src} alt={'simulate-bonus'} />
            <ButtonWrapper>
              <NavigateButton to={EVENT_PAGE_PATH}>
                이벤트 자세히 보기
              </NavigateButton>
            </ButtonWrapper>
          </ModalStyle>
        </>
      )}
      {}
    </>
  )
}

export default LandingPopup
