import React, { useEffect } from 'react'
import { RouterItem } from './routerItem'
import RouterInterceptor from './routerInterceptor'
import { useLocation } from 'react-router-dom'

function RouterRenderer(routes: RouterItem[]) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return routes.map((route, index) => {
    return <RouterInterceptor exact key={`${route.name}-${index}`} {...route} />
  })
}

export default RouterRenderer
