import { getData, postData } from '@pages/Event/simulate/v2/axios/axios'
import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { useMutation, useQuery } from '@tanstack/react-query'

type ResponseType = {
  id: number
  name: string
  mobile: string
  securities: {
    code: string
    name: string
    imgUrl: string
  }[]
}
type RequestSignInData = {
  name: string
  mobile: string
  season: string
}
type ResponseSignInData = {
  token: string
}

const postSignInFunction = async (body: RequestSignInData) => {
  const res = await postData<ResponseSignInData, RequestSignInData>(
    V2ApiUrls.SIGN_IN,
    body
  )
  return res.data.data
}

const getMeFunction = async () => {
  const res = await getData<ResponseType>(V2ApiUrls.ME)
  return res.data.data
}

export const useGetMe = () => {
  return useQuery({
    queryKey: ['me'],
    queryFn: getMeFunction,
  })
}
export const usePostSignIn = () => {
  return useMutation({
    mutationFn: postSignInFunction,
    onSuccess: ({ token }) => {
      localStorage.setItem('accessToken', token)
    },
  })
}
