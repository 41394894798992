import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Desktop } from '@constants/mediaQuery'
import SubTitleForWeb from '@pages/Event/simulate/v1/EventCompletePage/components/SubTitleForWeb'
import SimulatedLayout from '@layouts/SimulatedLayout'
import styled from 'styled-components'
import HowInvestment from '@pages/Event/simulate/v1/EventCompletePage/components/HowInvestment'
import useSetEntryPoint from '@hooks/useSetEntryPoint'
import { Helmet } from 'react-helmet-async'
import useSearchParams from '@hooks/useSearchParams'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'
import { Link } from 'react-router-dom'

const Wrapper = styled.article`
  max-width: 800px;
  margin: 0 auto;
`
const TopImage = styled.img`
  max-width: 800px;
  width: 100%;
  object-fit: contain;
  object-position: top;
  min-height: 550px;
  height: auto;
  display: block;
  margin: 0;
  border: none;
`
const BenefitImage = styled.img`
  width: 100%;
  object-fit: contain;
  min-height: 440px;
  object-position: top;
`
const GuideImage = styled.img`
  width: 100%;
`
const Divider = styled.div`
  height: 18px;
  background-color: #f7f8fa;
  @media (max-width: ${Desktop}px) {
    height: 8px;
  }
`
const CenterBox = styled.button`
  display: flex;
  width: 100%;
  margin: 62px 0;
  justify-content: center;
`
const NavigateEvent = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 40px;
  color: #0e6dfb;
  cursor: pointer;
  border: 2px solid #0e6dfb;
  border-radius: 12px;
  font-family: Pretendard500, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
`
const lowSrc = '/images/simulateUpdate/season1-top-image-low.png'
const highSrc = '/images/simulateUpdate/season1-top-image.png'
const SimulateEventCompletePage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { getParam } = useSearchParams()
  const isFromApp = getParam('fromApp') === 'true'
  const [src, setSrc] = React.useState(lowSrc)
  useSetEntryPoint()

  useEffect(() => {
    const img = new Image()
    img.src = highSrc
    img.onload = () => setSrc(highSrc)
  }, [])
  return (
    <>
      <Helmet>
        <meta
          property={'title'}
          content={'일육공 160 - 공모주게임 시즌1 혜택 받기'}
        />
        <meta
          property={'description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
        <meta
          property={'og:title'}
          content={'일육공 160 - 공모주게임 시즌1 혜택 받기'}
        />
        <meta
          property={'og:description'}
          content={
            '공모주 청약을 해야하는지 말아야 하는지, 어느 증권사에 청약하는 게 유리한지, 콕 집어서 답을 알려드려요.'
          }
        />
        <meta property='og:image' content='%PUBLIC_URL%/og.png' />
      </Helmet>
      <SimulatedLayout hasFooter={!isMobile} hasClose={false} isFirst={true}>
        {!isMobile && <SubTitleForWeb />}
        <Wrapper>
          <TopImage src={src} alt={'simulate-game'} />
          <BenefitImage
            src={'/images/event/simulate-event-complete-new.png'}
            alt={'benefit'}
          />
          <Divider />
          <GuideImage
            src={
              isFromApp
                ? '/images/simulateUpdate/season1-completed-app.png'
                : '/images/simulateUpdate/season1-completed-web.png'
            }
            alt={'guide'}
          />
          <HowInvestment />
          <NoticeBox type={'season1'} backgroundColor={'#f4f4f4'} />
          {!isMobile && (
            <CenterBox>
              <NavigateEvent to={'/event'}>이벤트 목록보기</NavigateEvent>
            </CenterBox>
          )}
        </Wrapper>
      </SimulatedLayout>
    </>
  )
}

export default SimulateEventCompletePage
