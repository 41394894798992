import React from 'react'
import AccordionMenu from './accordionMenu'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import useSearchParams from '@hooks/useSearchParams'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 34px;
`
const Header = styled.header`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 16px;
  > button {
    padding: 0;
  }
`

const GuidePage = () => {
  const { getParam } = useSearchParams()
  const hasHeader = getParam('hasHeader') === 'true'

  const history = useHistory()
  return (
    <Wrapper>
      {!hasHeader ? null : (
        <Header>
          <button onClick={() => history.goBack()}>
            <img
              src={'/icons/simulate-bonus/back.png'}
              alt={'back'}
              width={24}
              height={24}
            />
          </button>
        </Header>
      )}
      {/*<Header />*/}
      <AccordionMenu />
    </Wrapper>
  )
}

export default GuidePage
