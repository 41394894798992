import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

const onFulfilled = (response: AxiosResponse) => {
  return response
}
const onRejected = (error: any) => {
  return Promise.reject(error)
}
const initialization = (config: AxiosRequestConfig) => {
  const axiosInstance = axios.create(config)
  axiosInstance.interceptors.request.use(
    (existedConfig: InternalAxiosRequestConfig) => {
      const accessToken = localStorage.getItem('accessToken')
      const newConfig = { ...existedConfig }
      if (accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`
      }
      return newConfig
    }
  )
  axiosInstance.interceptors.response.use(onFulfilled, onRejected)
  return axiosInstance
}
export default initialization
