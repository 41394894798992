import React, { useEffect } from 'react'
import styled from 'styled-components'
import Section01 from './section01/index'
import Section02 from './section02/index'
import Section03 from './section03/index'
import Section05 from './section05/index'
import FooterInLogo from '@components/NewMain/Footer'
import MobilePage from '../NewMobilePage/index'
import WebHeader from '@components/layout/WebHeader'
import { Desktop } from '@constants/mediaQuery'
import { useMediaQuery } from 'react-responsive'
import LandingPopup from '@components/simulated-trading/LandingPopup'
import useSearchParams from '@hooks/useSearchParams'

const NewMainPage = () => {
  const isMobileSize = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const { getParam } = useSearchParams()
  const source = getParam('source')
  useEffect(() => {
    if (source) {
      localStorage.setItem('source', source)
    }
  }, [source])
  return (
    <Container>
      <LandingPopup />
      {isMobileSize ? (
        <>
          <MobilePage />
        </>
      ) : (
        <>
          <WebHeader />
          <Section01 />
          <Section02 />
          <Section03 />
          <Section05 />
          <FooterInLogo />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  scroll-behavior: auto;
  width: 100%;
`

export default NewMainPage
