import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { BONUS_EVENT_SHARE_PATH, NavigateShare, SHARE_DATA } from '../../utils'
import { isAndroid } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'

const MobileHeaderStyle = styled.header`
  display: flex;
  width: 100%;
  position: fixed;
  padding: 0 16px;
  height: 48px;
  top: 0;
  justify-content: space-between;
  background-color: ${Colors.gf};
  align-items: center;
  z-index: 300;
`
const RNHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    padding: 0;
    cursor: pointer;
  }
`
const ShareButton = styled.button`
  font-family: SpoqaHanSansNeoRegular, sans-serif;
  font-size: 16px;
  letter-spacing: -0.33px;
  line-height: 24px;
  color: #0e6dfb;
`

type Props = {
  isFirst?: boolean
  hasBack?: boolean
  hasClose?: boolean
  hasShareInHeader?: boolean
  handleWebviewClose: () => void
  handleBackButton: () => void
  rightComponent: ReactNode | null
}

const AppHeader = ({
  isFirst,
  hasBack,
  hasClose,
  handleBackButton,
  handleWebviewClose,
  hasShareInHeader,
  rightComponent,
}: Props) => {
  const location = useLocation()
  const isBonusPage = location.pathname.startsWith('/event')
  return (
    <MobileHeaderStyle>
      <RNHeader>
        {hasBack ? (
          <button onClick={isFirst ? handleWebviewClose : handleBackButton}>
            <img
              src={'/icons/simulate-bonus/back.png'}
              alt={'back'}
              width={24}
              height={24}
            />
          </button>
        ) : (
          // space-between 속성을 위한 빈 div
          <div />
        )}
        {rightComponent ? (
          <>{rightComponent}</>
        ) : hasClose && !isFirst ? (
          <button onClick={handleWebviewClose}>
            <img
              src={'/icons/only_x_black.png'}
              alt={'close'}
              width={20}
              height={20}
            />
          </button>
        ) : hasShareInHeader && !isAndroid ? (
          <ShareButton
            onClick={() =>
              NavigateShare(
                isBonusPage ? BONUS_EVENT_SHARE_PATH : SHARE_DATA.url
              )
            }
          >
            공유하기
          </ShareButton>
        ) : null}
      </RNHeader>
    </MobileHeaderStyle>
  )
}

export default AppHeader
