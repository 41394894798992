import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import {
  CopyURL,
  IsSimulationGameSeasonOneEnd,
  NavigateShare,
} from '../../utils'
import { useHistory } from 'react-router-dom'
import { Desktop } from '@constants/mediaQuery'
import { useMediaQuery } from 'react-responsive'
import NoticeBox from '@pages/Event/simulate/v1bonus/components/NoticeBox'

const Wrapper = styled.article<{ pb: number }>`
  width: 100%;
  padding-bottom: ${({ pb }) => pb}px;
`
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  > img {
    width: 100%;
    max-width: 800px;
  }
`

const FirstImageArea = styled.img`
  min-height: 100%;
  height: auto;
  display: block;
  margin: 0;
  border: none;
  object-fit: contain;
`
const ImageArea = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  border: none;
`
const EventArea = styled.span`
  display: flex;
  background-color: #1927db;
  width: 100%;
  margin-top: -1px;
  padding: 24px 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > button {
    cursor: pointer;
  }
`
const VideoWrapper = styled.div`
  width: 100%;
  background-color: white;
`
const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: calc(9 / 16 * 100%); // 9/16
  margin: 0 56px;

  @media screen and (max-width: ${Desktop}px) {
    margin: 0 7%;
  }
`
const YoutubeIframe = styled.iframe`
  position: absolute;
  border-radius: 12px;
  top: 0;
  height: 100%;
  width: 100%;
  border: none;
`
const VideoSource = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.gf};
  padding-top: 16px;
  color: #b2b2b2;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.8px;
  text-align: center;
  font-family: Pretendard500, sans-serif;
  margin-bottom: -1px;
  @media screen and (max-width: ${Desktop}px) {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.33px;
    padding-top: 8px;
  }
`
const BlueBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1927db;
  padding: 24px 24px 0;
`
const WhiteBackground = styled.div`
  background-color: ${Colors.gf};
  padding: 48px 24px 56px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
const ShareButton = styled.button`
  display: flex;
  cursor: pointer;
  width: 280px;
  margin-bottom: 24px;
  height: 76px;
  border-radius: 32px;
  border: 1px solid ${Colors.gf};
  align-items: center;
  justify-content: center;
  font-family: Pretendard700, sans-serif;
  font-size: 28px;
  line-height: 30px;
  color: ${Colors.gf};
  @media screen and (max-width: ${Desktop}px) {
    width: 162px;
    height: 46px;
    font-size: 18px;
    line-height: 22px;
    border-radius: 24px;
  }
`
const TitleWrapper = styled.div`
  padding: 80px 52px 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gf};

  @media screen and (max-width: ${Desktop}px) {
    padding: 56px 52px 48px;
  }
  > img {
    width: 530px;
    @media screen and (max-width: ${Desktop}px) {
      width: 256px;
      height: 76px;
    }
  }
`
const ShareFloatingButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  justify-content: center;
  flex-direction: column;
  background-color: #5b6aed;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  color: ${Colors.gf};
  font-size: 12px;
  font-family: Pretendard700, sans-serif;
  line-height: 14px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
`
const BottomFixedButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  max-width: 800px;
  transform: translateX(-50%);
  display: flex;
  padding: 16px 24px;
  gap: 8px;
  background-color: ${Colors.gf};
  border-top: 1px solid #dcdeeb;
`
const ButtonFixer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: ${Colors.gf};
  justify-content: center;
  height: 88px;
`

const BottomButton = styled.button<{
  isInvite?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #0e6dfb;
  font-family: Pretendard400, sans-serif;
  font-size: 16px;
  line-height: 19px;
  background-color: ${({ isInvite }) => (isInvite ? '#0E6DFB' : Colors.gf)};
  color: ${({ isInvite }) => (isInvite ? Colors.gf : '#0E6DFB')};
  height: 56px;
  width: 100%;
  &:disabled {
    cursor: not-allowed;
    background-color: #dcdeeb;
    border: none;
  }
`
const ShareContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 800px;
  width: 100%;
  position: fixed;
  bottom: 108px;
  left: 50%;
  z-index: 20;
  transform: translateX(-50%);
  padding-right: 24px;
`

type Props = {
  isLanding?: boolean
}
/**
 *
 *  모의투자 이벤트 상세 페이지 (공통)
 *  /simulate-bonus-event
 *  /simulated-trading-main/detail
 *  분기 처리 필요한 것들
 *  현재 액수 보러가기 handler
 * @param isLanding /simulate-bonus-event 페이지 인지 여부
 * @constructor
 */
const EventDetail = ({ isLanding = false }: Props) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ query: `(max-width: ${Desktop}px)` })
  const isEndGame = IsSimulationGameSeasonOneEnd()

  const handleNavigate = () => {
    if (isLanding) {
      history.push('/simulated-blur')
    } else {
      if (history.location.state) {
        history.goBack()
      } else {
        history.push('/simulated-trading-main')
      }
    }
  }
  const handleNavigateToJoin = () => {
    const SIGH_PAGE_URL = '/simulated-sign'
    history.push(SIGH_PAGE_URL)
  }
  const handleShare = async () => {
    if (isMobile) {
      await NavigateShare()
    } else {
      CopyURL()
    }
  }

  return (
    <Wrapper pb={isLanding ? 0 : 88}>
      <ShareContainer>
        <ShareFloatingButton
          onClick={handleShare}
          id={'event_160_01_detail_share_btn_click'}
        >
          <img
            src={'/icons/ic_share.png'}
            alt={'share'}
            width={24}
            height={24}
          />
          <span>공유</span>
        </ShareFloatingButton>
      </ShareContainer>

      <Container>
        <FirstImageArea
          src='/images/event/simulate-detail-1.png'
          alt='event-1'
        />
        <ImageArea src='/images/event/simulate-detail-2.png' alt='event-2' />
        <BlueBackground>
          <ImageArea src='/images/event/simulate-detail-3.png' alt='event-3' />
        </BlueBackground>

        <EventArea>
          <button onClick={handleNavigate}>
            <ImageArea
              src='/images/event/simulate-navigate-goal.png'
              alt='navigateToGoal'
            />
          </button>
          <ButtonWrapper>
            <ShareButton
              onClick={handleShare}
              id={'event_160_01_detail_share_btn_click'}
            >
              이벤트 공유하기
            </ShareButton>
          </ButtonWrapper>
        </EventArea>

        <TitleWrapper>
          <ImageArea src='/images/event/simulate-detail-4.png' alt='why' />
        </TitleWrapper>

        <VideoWrapper>
          <VideoContainer>
            <YoutubeIframe
              src='https://www.youtube.com/embed/_h5zZQ3noNA?si=hn_V2joOiDKxj5NX'
              title='공모주 청약, 아무거나 하면 큰일납니다 - 일육공 박재원, 윤도선 대표'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;'
              referrerPolicy='strict-origin-when-cross-origin'
              allowFullScreen
            ></YoutubeIframe>
          </VideoContainer>
        </VideoWrapper>

        <VideoSource>출처 : 손에잡히는경제 Youtube</VideoSource>
        <WhiteBackground>
          <ImageArea src='/images/event/simulate-detail-5.png' alt='event-4' />
        </WhiteBackground>
        <NoticeBox type={'season1'} />
        {/*<ImageArea src='/images/simulateUpdate/simulated_5.png' alt='event-5' />*/}
      </Container>
      <ButtonFixer>
        <BottomFixedButtonWrapper>
          <BottomButton onClick={handleNavigate}>누적상금보기</BottomButton>
          <BottomButton
            isInvite={true}
            onClick={handleNavigateToJoin}
            disabled={isEndGame}
          >
            {isEndGame ? '참여기간 종료' : '이벤트 참여'}
          </BottomButton>
        </BottomFixedButtonWrapper>
      </ButtonFixer>
    </Wrapper>
  )
}

export default EventDetail
