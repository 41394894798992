import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
  border-bottom: 1px solid #19191b;
  padding: 16px 0;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  background-color: #28282e;
`
const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const MarqueeContent = styled.div<{ length: number }>`
  display: flex;
  gap: 8px;
  animation: ${scroll} ${(props) => props.length * 6}s linear infinite;
  min-width: ${(props) => Math.max(200, props.length * 100)}%;
`

const MarqueeItem = styled.span`
  color: white;
  font-size: 16px;
  line-height: 19px;
  flex: 0 0 auto;
  font-family: Pretendard400, sans-serif;
`
const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`

type Props = {
  items: string[]
}
// 전광판
const Marquee = ({ items }: Props) => {
  return (
    <Container>
      <MarqueeContainer>
        <MarqueeContent length={items.length}>
          {[...items, ...items, ...items, ...items].map((item, index) => (
            <MarqueeItem key={index}>{item}</MarqueeItem>
          ))}
        </MarqueeContent>
      </MarqueeContainer>
    </Container>
  )
}

export default Marquee
