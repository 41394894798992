import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { useAppSelector } from '@store/selectors/utils'
import NavigateStore from '@utils/navigateStore'
import usePostMessage from '@hooks/usePostMessage'
import useSearchParams from '@hooks/useSearchParams'
import CurrentInvestmentBonus from '@pages/Event/simulate/v1bonus/benefit/components/CurrentInvestmentBonus'
import ScheduleListSectionBonus from '@pages/Event/simulate/v1bonus/benefit/components/ScheduleListSectionBonus'
import InvestmentCompletedBonus from '@pages/Event/simulate/v1bonus/benefit/components/InvestmentCompletedBonus'

const Wrapper = styled.div`
  background: #f5f5f9;
  width: 100%;
`
const Border = styled.div`
  background-color: #eaeef3;
  border-top: 1px solid #dcdeeb;
  height: 12px;
  width: 100%;
`
const EachSection = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
`

const Sections = () => {
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const { postMessageHandler } = usePostMessage()
  const { getParam } = useSearchParams()
  const fromApp = getParam('fromApp') === 'true'
  /**
   * 각 공모주 카드 클릭 시의 action
   * @param code 앱에서 온 경우 RN으로 전달하는 code 값
   */

  const onClickCard = useCallback(
    (code: string) => {
      const formatted = {
        action: 'toStock',
        stockId: code,
      }
      // 앱에서 온 경우
      if (isFromApp || fromApp) {
        postMessageHandler(formatted)
      } else {
        NavigateStore()
      }
    },
    [fromApp, isFromApp]
  )

  return (
    <Wrapper>
      <EachSection>
        <CurrentInvestmentBonus onClickCard={onClickCard} />
      </EachSection>
      <Border />
      <EachSection>
        <ScheduleListSectionBonus onClickCard={onClickCard} />
      </EachSection>
      <Border />
      <EachSection>
        <InvestmentCompletedBonus onClickCard={onClickCard} />
      </EachSection>
    </Wrapper>
  )
}

export default Sections
