import React, { useEffect } from 'react'
import styled from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Image = styled.img`
  width: 704px;
  aspect-ratio: 7/9.4;
`

const Mobile = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-out',
    })
  }, [])
  return (
    <div>
      <Image
        src={'/img/main/main-pc-mobile-image.png'}
        alt=''
        data-aos='slide-up'
      />
    </div>
  )
}

export default Mobile
