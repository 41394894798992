import { Axios } from '@apis/axios'

export const getData = async <T>(url: string): Promise<T> => {
  const response = await Axios.get<T>(url)
  return response.data
}

export const postData = async <T, U>(url: string, data: U): Promise<T> => {
  const response = await Axios.post<T>(url, data)
  return response.data
}

export const patchData = async <T, U>(url: string, data: U): Promise<T> => {
  const response = await Axios.patch<T>(url, data)
  return response.data
}
