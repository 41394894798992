// 보너스 시즌
const BONUS_SIGN_PATH = '/event/simulate/v1bonus/sign_up'
const BONUS_BENEFIT_PATH = '/event/simulate/v1bonus/benefit'
const BONUS_MAIN_PATH = '/event/simulate/v1bonus'
const BONUS_FORGET_PATH = '/event/simulate/v1bonus/forget'
const BONUS_CHANGE_PATH = '/event/simulate/v1bonus/change-info'
const BONUS_MARKETING_AGREEMENT_PATH =
  '/event/simulate/v1bonus/marketing-agreement'

// 1 시즌
const SEASON1_MAIN_PATH = '/simulated-event'
const SEASON1_BENEFIT_PATH = '/simulated-trading-main'
const SEASON1_COMPLETE_PATH = '/event/simulate/v1/complete'
const SEASON1_DETAIL_PATH = '/simulated-trading-main/detail'
const SEASON1_FORGET_PATH = '/simulated-forget'
const SEASON1_CHANGE_PATH = '/simulated-changepw'
const SEASON1_SIGN_PATH = '/simulated-sign'
const SEASON1_MARKETING_PATH = '/simulated-marketing'
const SEASON1_SIGN_CHECK_PATH = '/simulated-blur'

export {
  BONUS_MAIN_PATH,
  BONUS_SIGN_PATH,
  BONUS_BENEFIT_PATH,
  BONUS_FORGET_PATH,
  BONUS_MARKETING_AGREEMENT_PATH,
  BONUS_CHANGE_PATH,
  SEASON1_MAIN_PATH,
  SEASON1_FORGET_PATH,
  SEASON1_BENEFIT_PATH,
  SEASON1_CHANGE_PATH,
  SEASON1_DETAIL_PATH,
  SEASON1_COMPLETE_PATH,
  SEASON1_SIGN_PATH,
  SEASON1_MARKETING_PATH,
  SEASON1_SIGN_CHECK_PATH,
}
