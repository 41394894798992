import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const SimulatedHeader = (props: { notClear?: boolean; step?: number }) => {
  const { notClear, step } = props
  const history = useHistory()
  const handleBackButtonClick = () => {
    history.goBack()
  }

  const handleCancelButtonClick = () => {
    step === 1 ? history.goBack() : history.go(-2)
  }

  return (
    <Container>
      <BackButton
        src={'/icons/backButton.svg'}
        alt='backButton'
        onClick={handleBackButtonClick}
      />
      {!notClear && (
        <ClearIcon
          src={'/icons/clearIcon.svg'}
          onClick={handleCancelButtonClick}
        />
      )}
    </Container>
  )
}

const ClearIcon = styled.img``
const Container = styled.div`
  background-color: #fff;
  height: 48px;
  padding: 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  min-width: 360px;
  position: fixed;
  z-index: 300;
`

const BackButton = styled.img`
  width: 24px;
  height: 24px;
`

export default SimulatedHeader
