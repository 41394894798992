import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getData } from '@utils/api'

type ReturnType<T> = {
  data: T
}
type CurrentDataType = {
  statusCode: number
  message: string
}
type ErrorType = {
  message: string
  path: string
  requestId: string
  status: number
  timestamp: string
}
const useGetSimulatedUserData = (
  name: string,
  mobile: string,
  isFetch: boolean,
  season?: string
) => {
  const getUserUrl = '/mock-investment/user'

  const simulatedCurrentData = useQuery<
    ReturnType<CurrentDataType>,
    AxiosError<ErrorType>,
    CurrentDataType
  >({
    queryKey: ['participationInquiryGetUser', name, mobile],
    queryFn: () =>
      getData(
        `${getUserUrl}?name=${name}&mobile=${mobile}&season=${season || '1'}`
      ),
    select: ({ data }) => data,
    retry: false,
    enabled: isFetch,
  })
  return {
    simulatedCurrentData,
  }
}

export default useGetSimulatedUserData
