import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@store/selectors/utils'
import { setCloseToast } from '@store/modules/v2ToastSlice'
import styled from 'styled-components'
import useSearchParams from '@hooks/useSearchParams'
import { S2MaxWidth } from '@constants/mediaQuery'

const Wrapper = styled.div`
  width: 100%;
  color: white;
  z-index: 20;
  height: 52px;
  max-width: ${S2MaxWidth}px;
  padding: 0 24px;
  bottom: 88px;
  position: fixed;
  //position: ;
`
const ToastContainer = styled.div`
  border-radius: 12px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
  gap: 8px;
`
const V2Toast = () => {
  const dispatch = useAppDispatch()
  const { removeParam } = useSearchParams()
  const { message } = useAppSelector((state) => state.v2ToastReducer)
  const handleCloseToast = () => {
    dispatch(setCloseToast())
    // clean-up
    removeParam('notFound')
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleCloseToast()
    }, 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Wrapper>
      <ToastContainer>
        <img
          src={'/images/simulate-v2/warning-white.png'}
          height={24}
          width={24}
          alt={message}
        />
        <span>{message}</span>
      </ToastContainer>
    </Wrapper>
  )
}

export default V2Toast
