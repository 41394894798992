import React, { useEffect, useRef, useState } from 'react'
import useSearchParams from '@hooks/useSearchParams'
import { SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  width: 100%;
  padding: 0 24px;
  max-width: 800px;
  position: relative;
`
const MobileTooltip = styled.div`
  display: flex;
  position: fixed;
  bottom: 120px;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: calc(100% - 48px);
  max-width: 752px;
  opacity: 0.9;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 8px;
  border-radius: 4px;
`

const MAX_SNACK_BAR_COUNT = 3
const SnackBar = () => {
  const [count, setCount] = useState(0)
  const { getParam } = useSearchParams()
  const history = useHistory()
  const isOpenSnackBar = getParam('type') === 'yet'
  const interval = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (isOpenSnackBar) {
      interval.current = setInterval(() => {
        setCount((prev) => prev + 1)
      }, 1000)
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isOpenSnackBar])
  useEffect(() => {
    if (count > MAX_SNACK_BAR_COUNT) {
      clearInterval(interval.current)
      history.replace(history.location.pathname)
    }
  }, [count, history])

  return !isOpenSnackBar || count > MAX_SNACK_BAR_COUNT ? null : (
    <Wrapper>
      <MobileTooltip>
        <SpoqaLight size={14} color={Colors.gf} lineHeight={18}>
          이벤트 신청 정보가 없어요
        </SpoqaLight>
      </MobileTooltip>
    </Wrapper>
  )
}

export default SnackBar
