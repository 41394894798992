import React from 'react'
import styled from 'styled-components'
import Rolling from './rolling'

const ImgContainer = () => {
  return (
    <Container>
      <CenterImg src='/img/Center-main.png' />
      <Rolling />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 103px;
`

const CenterImg = styled.img`
  width: 212px;
  z-index: 1;
  position: absolute;
  bottom: -40px;
`

export default ImgContainer
