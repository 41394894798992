import React, { useEffect, useRef, useState } from 'react'
import useSearchParams from '@hooks/useSearchParams'
import { SpoqaLight } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
`
const MobileTooltip = styled.div`
  display: flex;
  position: fixed;
  top: 48px;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: calc(100% - 32px);
  max-width: 368px;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.7);
  gap: 8px;
  border-radius: 12px;
`

const MAX_SNACK_BAR_COUNT = 3
const Snackbar = () => {
  const [count, setCount] = useState(0)
  const { getParam, removeParam } = useSearchParams()
  const history = useHistory()
  const isOpenSnackbar = getParam('type') === 'yet'
  const interval = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (isOpenSnackbar) {
      interval.current = setInterval(() => {
        setCount((prev) => prev + 1)
      }, 1000)
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isOpenSnackbar])
  useEffect(() => {
    if (count > MAX_SNACK_BAR_COUNT) {
      clearInterval(interval.current)
      removeParam('type')
    }
  }, [count, history])

  return !isOpenSnackbar || count > MAX_SNACK_BAR_COUNT ? null : (
    <Wrapper>
      <MobileTooltip>
        <img
          src={'/icons/simulate-bonus/warning.png'}
          alt={'warn'}
          width={24}
          height={24}
        />
        <SpoqaLight size={14} color={Colors.gf} lineHeight={18}>
          이벤트 참여 신청 정보가 없어요
        </SpoqaLight>
      </MobileTooltip>
    </Wrapper>
  )
}

export default Snackbar
