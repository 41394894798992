import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  margin: 56px 0 36px;
  max-width: 800px;
  width: 100%;
  background-color: #f5f5f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 108px;
`
const Title = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.72px;
`
const SubTitle = styled.span`
  color: #999ba9;
  font-size: 18px;
  line-height: 22px;
`

const EventSubTitleForWeb = () => {
  return (
    <Wrapper>
      <Container>
        <Title>또 한 번의 기회! 공모주 게임 보너스 시즌 참여하기</Title>
        <SubTitle>
          2025.02.03 ~ 2025.03.09(청약 발생 시, 해당 종목 매도일까지)
        </SubTitle>
      </Container>
    </Wrapper>
  )
}

export default EventSubTitleForWeb
