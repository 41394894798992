import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getNow } from '@utils/dateHelper'
import { Colors } from '@styles/colors'
import { SummaryDataType } from '@hooks/useGetSimulatedData'
import { useMediaQuery } from 'react-responsive'

const WebLogoBox = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: flex-end;
`
const Section = styled.section`
  display: flex;
  padding: 40px 0;
  background-color: #2833bf;
  flex-direction: column;
`
const MaxWidthView = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`
const Logo = styled.img`
  width: 100%;
`
const Stats = styled.div`
  padding: 24px 0 0;
  @media (max-width: 600px) {
    padding: 24px 24px;
  }
`
const FlexBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  color: ${Colors.gf};
  margin-bottom: 24px;
`
const AmountText = styled.span`
  font-family: Pretendard700, sans-serif;
  font-size: 28px;
  line-height: 34px;
`
const DateText = styled.span`
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
`
const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ProgressBar = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  z-index: 1;
  background-color: #d7f3ff;
  overflow: hidden;
`
const ProgressActive = styled(ProgressBar)<{ percent: number }>`
  position: relative;
  transition: width 1s ease-in-out;
  border-radius: 0;
  width: ${(props) => props.percent}%;
  background-color: #ffc300;
  display: flex;
  align-items: center;
  > p {
    position: absolute;
    right: 6px;
    top: 7px;
    color: #151c78;
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26px;
    word-break: keep-all;
  }
`
const WonText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-family: Pretendard700, sans-serif;
  line-height: 26px;
`
const ProgressAmount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-family: Pretendard400, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
`
type Props = {
  summaryData?: SummaryDataType
}

const StatsOverview = ({ summaryData }: Props) => {
  const nowDate = getNow().format('M월 DD일')
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const formattedPercentage = useMemo(() => {
    if (!summaryData?.eventProfitPercentage) {
      return 0
    }
    if (summaryData.eventProfitPercentage > 100) {
      return 100
    }
    if (summaryData.eventProfitPercentage < 0) {
      return 0
    }
    return summaryData.eventProfitPercentage
  }, [summaryData])

  return (
    <Section>
      <MaxWidthView>
        {isMobile ? (
          <Logo
            src={'/images/simulate-bonus/bonus-logo.png'}
            alt={'simulate-bonus-logo'}
          />
        ) : (
          <WebLogoBox>
            <img
              src={'/images/simulate-bonus/web-logo.png'}
              alt={'simulate-game'}
              width={200}
              height={78}
            />
            <img
              src={'/images/simulate-bonus/web-logo-sub.png'}
              alt={'goal'}
              width={123}
              height={28}
            />
          </WebLogoBox>
        )}
        <Stats>
          <FlexBox>
            <AmountText>수익금 + 참여지원금</AmountText>
            <DateText>{nowDate}</DateText>
          </FlexBox>
          <ProgressBarWrapper>
            <ProgressBar>
              <ProgressActive percent={formattedPercentage}>
                <p>
                  {(summaryData?.eventProfit || 0).toLocaleString()}
                  <WonText>원</WonText>
                </p>
              </ProgressActive>
            </ProgressBar>
            <ProgressAmount>
              <span>0원</span>
              <span>1만 원</span>
              <span>2만 원</span>
              <span>3만 원</span>
            </ProgressAmount>
          </ProgressBarWrapper>
        </Stats>
      </MaxWidthView>
    </Section>
  )
}

export default StatsOverview
