import { createRouterItem, RouterItemType } from '../../routerItem'
import BonusMainPage from '@pages/Event/simulate/v1bonus/BonusMainPage'
import BenefitPage from '@pages/Event/simulate/v1bonus/benefit/SummaryPage'
import SignCheckPage from '@pages/Event/simulate/v1bonus/sign-check/SignCheckPage'
import SignupPage from '@pages/Event/simulate/v1bonus/sign-up/SignupPage'
import ForgetPage from '@pages/Event/simulate/v1bonus/forget/ForgetPage'
import ChangeInfoPage from '@pages/Event/simulate/v1bonus/change/ChangeInfoPage'
import MarketingAgreementPage from '@pages/Event/simulate/v1bonus/marketingAgreement/marketingAgreementPage'
import BonusCompletePage from '@pages/Event/simulate/v1bonus/EventCompletePage/BonusCompletePage'

const PageItems = [
  {
    name: 'bonus-main-page',
    path: '/event/simulate/v1bonus',
    component: BonusMainPage,
  },
  {
    name: 'bonus-summary-page',
    path: '/event/simulate/v1bonus/benefit',
    component: BenefitPage,
  },
  {
    name: 'bonus-sign-check-page',
    path: '/event/simulate/v1bonus/sign_check',
    component: SignCheckPage,
  },
  {
    name: 'bonus-sign-up-page',
    path: '/event/simulate/v1bonus/sign_up',
    component: SignupPage,
  },
  {
    name: 'bonus-forget-password-page',
    path: '/event/simulate/v1bonus/forget',
    component: ForgetPage,
  },
  {
    name: 'bonus-change-info-page',
    path: '/event/simulate/v1bonus/change-info',
    component: ChangeInfoPage,
  },
  {
    name: 'bonus-marketing-agreement-page',
    path: '/event/simulate/v1bonus/marketing-agreement',
    component: MarketingAgreementPage,
  },
  {
    name: 'bonus-event-complete-page',
    path: '/event/simulate/v1bonus/complete',
    component: BonusCompletePage,
  },
]
const CreatedSimulatePageList = PageItems.map(({ path, component, name }) =>
  createRouterItem({
    name,
    path,
    component,
    type: RouterItemType.DEFAULT,
    exact: true,
  })
)

export default CreatedSimulatePageList
