import { AxiosRequestConfig } from 'axios'
import initialization from '@pages/Event/simulate/v2/axios/axiosSetup'

const baseURL = process.env.REACT_APP_REST_API_URI

export interface IResponseBody<T> {
  data: T
}

const defaultRequestConfiguration: AxiosRequestConfig = {
  baseURL,
  responseType: 'json',
  timeout: 10000, //10s,
  // withCredentials: process.env.NEXT_PUBLIC_RUN_MODE === 'prd',
}

const axiosInstance = initialization(defaultRequestConfiguration)
const getData = <ReturnType, QueryParamType = unknown>(
  url: string,
  queryParams?: QueryParamType,
  config?: AxiosRequestConfig<unknown> | undefined
) => {
  return axiosInstance.get<IResponseBody<ReturnType>>(url, {
    ...config,
    params: queryParams,
  })
}

const postData = <ReturnType, BodyType>(
  url: string,
  body?: BodyType,
  config?: AxiosRequestConfig<unknown> | undefined
) => {
  return axiosInstance.post<IResponseBody<ReturnType>>(url, body, config)
}

const putData = <ReturnType, BodyType>(
  url: string,
  body: BodyType,
  config?: AxiosRequestConfig<unknown> | undefined
) => {
  return axiosInstance.put<IResponseBody<ReturnType>>(url, body, config)
}

const patchData = <ReturnType, BodyType>(
  url: string,
  body?: BodyType,
  config?: AxiosRequestConfig<unknown> | undefined
) => {
  return axiosInstance.patch<IResponseBody<ReturnType>>(url, body, config)
}

export { getData, postData, putData, patchData, axiosInstance }
