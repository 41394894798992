import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import ExistModal from './existModal'
import SimulatedLayout from '@layouts/SimulatedLayout'
import EventDetail from '@components/simulated-trading/EventDetail'
import { useAppSelector } from '@store/selectors/utils'

export const Styled = {
  Container: styled.div`
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    margin: auto;
    margin-bottom: 24px;
    @media (max-width: 800px) {
      width: 100%;
      /* min-width: 360px; */
      margin: auto;
    }
  `,

  TopTab: styled.div`
    display: flex;
    justify-content: space-between;
    color: #555564;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 50px;
  `,
  TabItem: styled.span`
    margin: 16px 10px;
  `,
  TabDivide: styled.span`
    margin: 0 30px;
  `,

  FirstImageArea: styled.img`
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    border: none;
  `,
  ImageArea: styled.img`
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    border: none;
  `,
  ShareImage: styled.img`
    width: 70%;
    height: auto;
    display: block;
    margin: 24px 0;
    border: none;
    cursor: pointer;
  `,
  EventArea: styled.span`
    display: flex;
    background-color: #2833bf;
    width: 100%;
    height: auto;
    padding: 24px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
}

const { Container } = Styled

// RN 앱에서 초기 진입 페이지
const Index = (): ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const { isFromApp } = useAppSelector((state) => state.entryReducer)

  return (
    <SimulatedLayout
      isFirst={true}
      paddingBottom={isFromApp ? 137 : 156}
      hasHeaderUnderLine={false}
    >
      {/*<EventHeader />*/}
      {/*{isMobile ? null : <EventSubTitleForWeb />}*/}
      <Container>
        <EventDetail isLanding={true} />
        {/*<Footer*/}
        {/*  handleModal={handleModal}*/}
        {/*  paddingBottom={!isFromApp ? bottomInset : 24}*/}
        {/*/>*/}
        <ExistModal isOpen={showModal} setIsOpen={setShowModal} />
      </Container>
    </SimulatedLayout>
  )
}

export default Index
