import styled from 'styled-components'
import Modal from 'react-modal'
import { useAppSelector } from '@store/selectors/utils'
import { formatPhoneNumber } from '@utils/formatPhoneNumber'
import { useMediaQuery } from 'react-responsive'
import { Colors } from '@styles/colors'

const AlertPopupStyle = {
  content: {
    border: 0,
    // width: '80%',
    height: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
  },
}

const Styled = {
  ModalContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 40px 24px 16px;
    justify-content: center;
  `,
  Title: styled.div`
    width: 100%;
    font-family: Pretendard700, sans-serif;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    margin-bottom: 32px;
  `,
  Description: styled.div`
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    width: 100%;
    line-height: 24px;
    text-align: left;
    color: #555564;
    margin-bottom: 16px;
  `,
  BlueButton: styled.button`
    cursor: pointer;
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    flex: 1;
    background-color: #0e6dfb;
    color: ${Colors.gf};
    height: 100%;
    border-radius: 12px;
  `,
  NextButton: styled.span`
    font-family: Pretendard400, sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #0e6dfb;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #0e6dfb;
    flex: 1;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  `,
  NumberArea: styled.div`
    border: 1px solid #dcdeeb;
    background-color: #f5f5f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: 8px;
    height: 85px;
    width: 100%;
  `,
  TalkImage: styled.img`
    width: 56px;
    height: 56px;
    @media (max-width: 800px) {
      width: 40px;
      height: 40px;
    }
  `,
  PhoneNumberText: styled.span`
    font-family: Pretendard500, sans-serif;
    font-size: 14px;
    line-height: 17px;
    margin-top: 4px;
  `,

  ButtonArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    gap: 8px;
    height: 56px;
  `,
}

const {
  ModalContainer,
  Title,
  Description,
  BlueButton,
  NumberArea,
  TalkImage,
  PhoneNumberText,
  NextButton,
  ButtonArea,
} = Styled

type Props = {
  handleSubmit: () => void
  mobile: string
  handleClose: () => void
  isOpen: boolean
}

const AlertPopup: React.FC<Props> = ({
  mobile,
  handleSubmit,
  handleClose,
  isOpen,
}: Props) => {
  const { isFromApp } = useAppSelector((state) => state.entryReducer)
  const isMobile = useMediaQuery({ query: `(max-width: 400px)` })

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleClose}
        style={{
          ...AlertPopupStyle,
          content: {
            ...AlertPopupStyle.content,
            width: '80%',
            maxWidth: '400px',
            borderRadius: !isMobile ? '24px' : isFromApp ? '4px' : '16px',
          },
        }}
        ariaHideApp={false}
      >
        <ModalContainer>
          <Title>
            이벤트 알림톡 <br />
            수신번호를 확인해주세요
          </Title>
          <Description>
            공모주 게임 투자 일정마다, 아래 번호로 <br />
            카카오톡 알림 메시지가 발송돼요!
          </Description>
          <NumberArea>
            <TalkImage src='/images/talk-bell.png' />
            <PhoneNumberText>{formatPhoneNumber(mobile)}</PhoneNumberText>
          </NumberArea>
          <ButtonArea>
            <NextButton onClick={handleClose}>다시입력</NextButton>
            <BlueButton
              onClick={handleSubmit}
              id={'event_160_02_sign_signin_btn_click'}
            >
              확인
            </BlueButton>
          </ButtonArea>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default AlertPopup
