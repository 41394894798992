import { getData } from '@pages/Event/simulate/v2/axios/axios'
import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { useQuery } from '@tanstack/react-query'

type ResponseType = {
  securities: {
    code: string
    imgUrl: string
    name: string
  }[]
}

const getSecuritiesFunction = async () => {
  const res = await getData<ResponseType>(V2ApiUrls.GET_SECURITIES)
  return res.data.data
}

const useGetSecurities = () => {
  return useQuery({
    queryKey: ['securities'],
    queryFn: getSecuritiesFunction,
  })
}
export default useGetSecurities
