import { createRouterItem, RouterItemType } from '@router/routerItem'
import SimulateEventCompletePage from '@pages/Event/simulate/v1/EventCompletePage/SimulateEventCompletePage'
import SimulatedEventDetailPage from '@pages/Event/simulate/v1/SimulatedEventDetailPage'
import SimulatedBlurPage from '@pages/Event/simulate/v1/SimulatedBlurPage'
import SimulatedTradingPage from '@pages/Event/simulate/v1/SimulatedTradingPage'
import SimulatedSignPage from '@pages/Event/simulate/v1/SimulatedSignPage'
import SimulatedForgetPage from '@pages/Event/simulate/v1/SimulatedForgetPage'
import SimulatedChangedPwPage from '@pages/Event/simulate/v1/SimulatedChangedPwPage'
import SimulatedMarketingPage from '@pages/Event/simulate/v1/SimulatedMarketingPage'
import SimulatedEventPage from '@pages/Event/simulate/v1/SimulatedEventPage'
import {
  SEASON1_BENEFIT_PATH,
  SEASON1_CHANGE_PATH,
  SEASON1_COMPLETE_PATH,
  SEASON1_DETAIL_PATH,
  SEASON1_FORGET_PATH,
  SEASON1_MAIN_PATH,
  SEASON1_MARKETING_PATH,
  SEASON1_SIGN_CHECK_PATH,
  SEASON1_SIGN_PATH,
} from '@router/paths/simulate/pathMap'

const PageItems = [
  {
    name: 'season1-event-complete-page',
    path: SEASON1_COMPLETE_PATH,
    component: SimulateEventCompletePage,
  },
  {
    name: 'simulated-trading-main',
    path: SEASON1_BENEFIT_PATH,
    component: SimulatedTradingPage,
  },
  {
    name: 'simulated-trading-main/detail',
    path: SEASON1_DETAIL_PATH,
    component: SimulatedEventDetailPage,
  },
  {
    name: 'simulated-event',
    path: SEASON1_MAIN_PATH,
    component: SimulatedEventPage,
  },
  {
    name: 'simulated-sign',
    path: SEASON1_SIGN_PATH,
    component: SimulatedSignPage,
  },
  {
    name: 'simulated-forget',
    path: SEASON1_FORGET_PATH,
    component: SimulatedForgetPage,
  },
  {
    name: 'simulated-changepw',
    path: SEASON1_CHANGE_PATH,
    component: SimulatedChangedPwPage,
  },
  {
    name: 'simulated-marketing',
    path: SEASON1_MARKETING_PATH,
    component: SimulatedMarketingPage,
  },
  {
    name: 'simulated-blur',
    path: SEASON1_SIGN_CHECK_PATH,
    component: SimulatedBlurPage,
  },
]
const CreatedSimulateSeason1PageList = PageItems.map(
  ({ path, component, name }) =>
    createRouterItem({
      name,
      path,
      component,
      type: RouterItemType.DEFAULT,
      exact: true,
    })
)
export default CreatedSimulateSeason1PageList
