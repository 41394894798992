import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import NavigateStore from '@utils/navigateStore'

interface Props {
  isOpen: boolean
  handleClose: () => void
}
const Container = styled.section`
  padding-top: 48px;
  background-color: ${Colors.gf};
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Title = styled.h3`
  font-family: Pretendard700, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #0e6dfb;
`
const DownloadImage = styled.img`
  width: 154px;
  height: 136px;
  object-fit: contain;
  margin: 32px 0 58px;
`

const BottomButtonWrapper = styled.div`
  display: flex;
  padding: 0 24px 24px;

  gap: 8px;
  & > button {
    outline: none;
    font-size: 16px;
    line-height: 19px;
    font-family: Pretendard400, sans-serif;
    width: 100%;
    flex: 1;
    height: 56px;
    background-color: #0e6dfb;
    color: ${Colors.gf};
    border-radius: 12px;
    &:nth-child(1) {
      max-width: 90px;
      background-color: ${Colors.gf};
      color: #555564;
    }
  }
`

const DownloadSheet: React.FC<Props> = ({ isOpen, handleClose }) => {
  return (
    <>
      <BottomSheet open={isOpen} color={Colors.gf} onDismiss={handleClose}>
        <Container>
          <Title>
            일육공 모바일 앱 설치 후<br />더 많은 공모주 정보를 알아보세요!
          </Title>
          <DownloadImage
            alt={'guide'}
            src={'/images/simulateUpdate/download-popup.png'}
          />
        </Container>
        <BottomButtonWrapper>
          <button
            onClick={handleClose}
            id={'event_160_02_finish_downloadnext_click'}
          >
            다음에
          </button>
          <button
            onClick={NavigateStore}
            id={'event_160_02_finish_downloadgo_click'}
          >
            일육공 다운로드
          </button>
        </BottomButtonWrapper>
      </BottomSheet>
    </>
  )
}

export default DownloadSheet
