import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { S2MaxWidth } from '@constants/mediaQuery'
import { useAppSelector } from '@store/selectors/utils'

const Container = styled.div<{ bottom: number; hasBorder: boolean }>`
  z-index: 10;
  width: 100%;
  position: fixed;
  max-width: ${S2MaxWidth}px;
  height: 88px;
  border-top: ${({ hasBorder }) => (hasBorder ? 1 : 0)}px solid #28282e;
  bottom: ${({ bottom }) => bottom * 100}px;
  padding: 16px 24px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: ${Colors.bg_dark};
  //backdrop-filter: blur(4px);
`

type Props = {
  children: React.ReactNode
  hasBorder?: boolean
}
const BottomButtonFixer = ({ children, hasBorder = true }: Props) => {
  const { height, isKeyboardOpen } = useAppSelector(
    (state) => state.v2KeyboardReducer
  )
  return (
    <Container bottom={isKeyboardOpen ? height : 0} hasBorder={hasBorder}>
      {children}
    </Container>
  )
}

export default BottomButtonFixer
