import { postData } from '@pages/Event/simulate/v2/axios/axios'
import { V2ApiUrls } from '@pages/Event/simulate/v2/axios/v2ApiUrls'
import { useMutation } from '@tanstack/react-query'

type RequestBodyType = {
  securitiesCodes: string[]
}

const addSecuritiesFunction = async (body: RequestBodyType) => {
  const res = await postData<any, RequestBodyType>(
    V2ApiUrls.ADD_SECURITIES,
    body
  )
  return res.data.data
}
const usePostSecurities = () => {
  return useMutation({
    mutationFn: addSecuritiesFunction,
  })
}
export default usePostSecurities
