import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import base from './modules/base'
import entryPointSlice from './modules/entryPointSlice'
import userSlice from './modules/userSlice'
import v2UserSlice from './modules/v2UserSlice'
import v2ToastSlice from './modules/v2ToastSlice'
import v2KeyboardSlice from './modules/v2KeyboardSlice'

const rootReducer = {
  base: base,
  entryReducer: entryPointSlice,
  userReducer: userSlice,
  v2UserReducer: v2UserSlice,
  v2ToastReducer: v2ToastSlice,
  v2KeyboardReducer: v2KeyboardSlice,
}
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === 'test',
})
export type RootState = ReturnType<typeof store.getState>
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export default store
